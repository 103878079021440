import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'addUnavailability.api.js';

async function createUnavailability(unavailability) {
    try {
        if (unavailability && unavailability.periodicity_type) {
            const response = await apiClient.post('unavailabilities/periodicity', unavailability);
            return response.data;
        }
        const response = await apiClient.post('unavailabilities', unavailability);
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.CREATE_UNAVAILABILITY.MESSAGE, fileName)
        );
    }
}
async function unavailabilityCreationCheck(unavailability) {
    try {
        if (unavailability && unavailability.periodicity_type) {
            const response = await apiClient.post(
                'unavailabilities/periodicity/creation_check',
                unavailability
            );
            return response.data;
        }
        const response = await apiClient.post('unavailabilities/creation_check', unavailability);
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.CHECK_CREATE_UNAVAILABILITY, fileName)
        );
    }
}

async function replaceUnavailability(unavId, newUnavailability) {
    try {
        const response = await apiClient.post(
            `/unavailabilities/${unavId}/replacement`,
            newUnavailability
        );
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.CHECK_EDIT_UNAVAILABILITY, fileName)
        );
    }
}
async function unavailabilityReplacementCheck(unavId, newUnavailability) {
    try {
        const response = await apiClient.post(
            `/unavailabilities/${unavId}/replacement_check`,
            newUnavailability
        );
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.CHECK_CREATE_UNAVAILABILITY, fileName)
        );
    }
}

export default {
    createUnavailability,
    unavailabilityCreationCheck,
    replaceUnavailability,
    unavailabilityReplacementCheck,
};
