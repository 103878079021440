/* eslint-disable camelcase */
import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import { formatToIsoStringEndOfDay, formatToIsoStringStartOfDay } from '@/services/shared';
import errorMessages from '@/constants/errorMessages';

const fileName = 'delegations.api.js';

async function getDelegations() {
    try {
        const response = await apiClient.get('delegations');
        return response.data.gid_delegues;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.EMPLOYEE_GET_DELEGATIONS, fileName));
    }
}

async function createDelegation({ gid_collaborateur, date_debut, date_fin }) {
    const startDate = formatToIsoStringStartOfDay(date_debut);
    const endDate = formatToIsoStringEndOfDay(date_fin);

    try {
        const response = await apiClient.post('delegations', {
            gid_collaborateur,
            date_debut: startDate,
            date_fin: endDate,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.EMPLOYEE_CREATE_DELEGATION, fileName)
        );
    }
}

async function updateDelegation({ gid_collaborateur, date_debut, date_fin }) {
    const startDate = formatToIsoStringStartOfDay(date_debut);
    const endDate = formatToIsoStringEndOfDay(date_fin);

    try {
        const response = await apiClient.put(`delegations/${gid_collaborateur}`, {
            date_debut: startDate,
            date_fin: endDate,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.EMPLOYEE_CREATE_DELEGATION, fileName)
        );
    }
}

async function deleteDelegations(gid_collaborateur) {
    try {
        const response = await apiClient.delete(`delegations/${gid_collaborateur}`);
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.EMPLOYEE_DELETE_DELEGATION, fileName)
        );
    }
}

export default {
    getDelegations,
    createDelegation,
    updateDelegation,
    deleteDelegations,
};
