import moment from 'moment';
import { LOCKED_PLACEMENT_VALUE } from '@/constants/unavailabilities';
import { REASON_TYPE } from '@/constants/addUnavailability';

const state = {
    isDisplayed: false,
    isEditing: false,
    unavailability: undefined,

    unavPeriod: {
        type: undefined,
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        periodicity: undefined,
    },
    recipients: [],
    unavReason: {
        type: undefined,
        reason: undefined,
        constraint: undefined,
    },
    comment: '',
};

const getters = {};

const mutations = {
    setAddUnavailabilityDisplay(state, isDisplayed) {
        if (!isDisplayed) {
            state.isEditing = false;
            state.unavailability = undefined;
            state.unavPeriod = {
                type: undefined,
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                periodicity: undefined,
            };
            state.recipients = [];
            state.unavReason = {
                type: undefined,
                reason: undefined,
                constraint: undefined,
            };
            state.comment = '';
        }
        state.isDisplayed = isDisplayed;
    },
    displayAddUnavailability(state) {
        state.isDisplayed = true;
    },
    displayEditUnavailability(state, unavailability) {
        state.isEditing = true;
        state.unavailability = unavailability;
        state.isDisplayed = true;
    },

    setUnavPeriod(state, newUnavPeriod) {
        state.unavPeriod = newUnavPeriod;
    },
    setUnavType(state, newUnavType) {
        state.unavPeriod.type = newUnavType;
    },
    setStartDate(state, newVal) {
        state.unavPeriod.startDate = newVal;
    },
    setEndDate(state, newVal) {
        state.unavPeriod.endDate = newVal;
    },
    setStartTime(state, newVal) {
        state.unavPeriod.startTime = newVal;
    },
    setEndTime(state, newVal) {
        state.unavPeriod.endTime = newVal;
    },

    initializePeriodicity(state) {
        const dayOfWeek = moment().isoWeekday() - 1;
        state.unavPeriod.periodicity = {
            isWorkingDay: false,
            numberDays: 1,
            numberWeeks: 1,
            periodicityDays: [dayOfWeek],
        };
    },
    setPeriodicity(state, newVal) {
        state.unavPeriod.periodicity = newVal;
    },
    setPeriodicityType(state, newVal) {
        state.unavPeriod.periodicity.type = newVal;
    },
    setIsWorkingDay(state, newVal) {
        state.unavPeriod.periodicity.isWorkingDay = newVal;
    },
    setNumberDays(state, newVal) {
        state.unavPeriod.periodicity.numberDays = newVal;
    },
    setNumberWeeks(state, newVal) {
        state.unavPeriod.periodicity.numberWeeks = newVal;
    },
    setPeriocityDays(state, newVal) {
        state.unavPeriod.periodicity.periodicityDays = newVal.sort();
    },

    setRecipients(state, newRecipients) {
        state.recipients = newRecipients;
    },

    initializeUnavReason(state) {
        state.unavReason = {
            type: REASON_TYPE.HR,
            reason: undefined,
            constraint: undefined,
        };
    },
    setUnavReason(state, newUnavReason) {
        state.unavReason = newUnavReason;
    },
    setReasonType(state, newReasonType) {
        state.unavReason.type = newReasonType;
    },
    setReason(state, newReason) {
        state.unavReason.reason = newReason;
    },

    initializeConstraint(state) {
        state.unavReason.constraint = {
            isValid: true,
            propertyNumber: null,
            constructionSiteCode: null,
            placementType: LOCKED_PLACEMENT_VALUE,
            worksLabel: null,
        };
    },
    setConstraint(state, constraint) {
        state.unavReason.constraint = constraint;
    },
    setConstraintValidity(state, newValid) {
        state.unavReason.constraint.isValid = newValid;
    },
    setConstaintPropertyNumber(state, newNumber) {
        state.unavReason.constraint.propertyNumber = newNumber;
    },
    setConstraintConstructionCode(state, newConstructionCode) {
        state.unavReason.constraint.constructionSiteCode = newConstructionCode;
    },
    setConstraintPlacementType(state, newPlacementType) {
        state.unavReason.constraint.placementType = newPlacementType;
    },
    setConstraintWorksLabel(state, newWorkLabel) {
        state.unavReason.constraint.worksLabel = newWorkLabel;
    },

    setComment(state, newComment) {
        state.comment = newComment;
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
