<template>
    <div class="error-page">
        <h1 class="error-title">({{ fatalError.title }})</h1>
        <div class="error-message">{{ fatalError.message }}</div>

        <v-btn
            class="error-btn primary"
            v-if="fatalError.solution && fatalError.solution.title"
            @click="fatalError.solution.action()"
        >
            {{ fatalError.solution.title }}
        </v-btn>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FatalErrorViewer',

    computed: {
        ...mapGetters(['fatalError']),
    },
};
</script>

<style scoped lang="scss">
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-title {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .error-message {
        font-size: 1.1em;
    }
    .error-btn {
        margin-top: 20px;
    }
}
</style>
