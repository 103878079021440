<template>
    <div>
        <v-dialog :value="isDialogDisplayed" @input="onInput" width="80%" max-width="1000px">
            <template v-slot:activator="{ on }">
                <div class="rgpd-button" v-on="on">
                    <a>Mention d'information RGPD</a>
                </div>
            </template>

            <v-card>
                <v-card-title class="break-word">
                    eDispo – Mention d’information RGPD relative à la gestion des indisponibilités
                    du personnel technique
                </v-card-title>

                <v-card-text class="rgpd-mention rgpd-title">
                    <div class="fist-paragraph">
                        En votre qualité de technicien vous êtes sujet au traitement de vos données
                        personnelles (nom, prénom, matricule GENESIS, identifiant Groupe du manager
                        de la personne concernée (GID), numéro de téléphone et adresse mail
                        professionnelle, entité de rattachement), via l’outil GENESIS (module
                        eDispo). Ce traitement est réalisé par votre employeur en qualité de
                        responsable de traitement (qui est selon votre rattachement, soit d’ENGIE
                        ENERGIE SERVICES ou la société CIEC). Plus précisément, ces données sont
                        traitées par les services habilités à cet effet de la Direction de la
                        Performance et des Opérations (DPO) du responsable de traitement concerné
                        (ex : RDE, REE, assistantes d’exploitation, ordonnanceurs et dispatcheurs,
                        le manager de la personne concerné), les équipes de la DSI en charges de
                        l’outil, ainsi que le service RH (ex : assistantes RH et RRH).
                    </div>
                    <p>
                        Ce traitement a pour finalité d’assurer la remontée en temps réel des
                        informations relatives à la disponibilité du personnel technique afin de
                        permettre une gestion rapide par la cellule PREDITY et d’optimiser
                        l'organisation des plans de maintenance et des interventions de dépannage.
                        La sous-finalité de ce traitement est de permettre la déclaration et la
                        validation des absences, puis de récupérer les données d’absence en vue de
                        la gestion des temps (GTA).
                    </p>

                    <p>
                        Ces données sont historisées et conservées dans l’outil pour la période de
                        référence « Congés Payés » en cours (i.e. du 1 juin année N au 31 mai année
                        N+1) et les 2 années précédentes, afin de se conformer aux obligations
                        légales en vigueur (art. L. 3245-1 Code du travail), durée à l'issue de
                        laquelle les données sont archivées en base intermédiaire pendant 3 années
                        supplémentaires, soit jusqu’à l’acquisition de la prescription civile. En
                        cas de contentieux, ces données sont conservées pendant toute la durée de la
                        procédure et jusqu'à l'expiration des voies de recours ordinaires et
                        extraordinaires. Ces données peuvent, le cas échéant, sur demande motivée,
                        être mises à disposition des autorités judiciaires et administratives,
                        agissant dans le cadre de leurs attributions.
                    </p>

                    <p>
                        Dans l’hypothèse où votre employeur est la société CIEC, les services
                        habilités de la DSI d’ENGIE Energie Services en charge de l’outil eDispo
                        peuvent être des destinataires de vos données personnelles dans la cadre de
                        leurs missions d’accompagnement technique de votre employeur.
                    </p>

                    <p>
                        Par ailleurs, les données personnelles sont également traitées par le
                        prestataire auquel votre employeur est susceptible de sous-traiter
                        l’hébergement de la solution eDispo (AWS Irlande). Le traitement de vos
                        données personnelles par ce prestataire est encadré par les obligations
                        issues de l’article 28 du règlement (UE) 2016/679 du Parlement européen et
                        du Conseil du 27 avril 2016 relatif à la protection des personnes physiques
                        à l’égard du traitement des données à caractère personnel et à la libre
                        circulation de ces données (RGPD), notamment l’obligation de mettre en place
                        toutes les mesures techniques et organisationnelles appropriées pour assurer
                        un haut niveau de sécurité et de confidentialité des données communiquées.
                        Vos données ne font pas l’objet d’un transfert de données personnelles hors
                        de l’Espace Economique Européen (EEE).
                    </p>

                    <p>
                        Conformément à la loi Informatique et libertés du 6 janvier 1978 et au
                        Règlement européen 2016/679 du 27 avril 2016, vous disposez d’un droit
                        d’accès, de rectification, de limitation des données qui vous concernent et
                        d’opposition au traitement pour des raisons tenant à votre situation
                        particulière.
                    </p>

                    <p>
                        Pour exercer vos droits, il suffit d’en faire la demande à l’adresse
                        courriel : dpm.engie-solutions@engie.com ou à ENGIE Energie Services – ENGIE
                        Solutions – Data Privacy Manager - 1 place Samuel de Champlain – Faubourg de
                        l’Arche - 92930 Paris La Défense Cedex - case courrier 13-12. Dans
                        l’hypothèse où votre employeur la société CIEC est responsable de
                        traitement, votre demande sera réceptionnée par le Data Privacy Manager
                        d’ENGIE Solutions (cf. supra), société mère de la CIEC, et traitée en
                        collaboration avec les services de la CIEC habilités à cet effet (à savoir
                        le Correspondant du Data Privacy Manager auprès de la CIEC).
                    </p>
                    <p>
                        Si vous estimez, après nous avoir contactés, que vos droits « Informatique
                        et Libertés » ne sont pas respectés, vous pouvez adresser une réclamation à
                        la CNIL.
                    </p>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer />
                    <v-btn class="primary" text @click="onInput(false)">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import localCacheService from '@/services/localCache';
import { CACHE_FIRST_CONNECTION_KEY } from '@/constants/localCache';

export default {
    name: 'RGPDMention',
    data() {
        return {
            isDialogDisplayed: false,
        };
    },

    mounted() {
        this.isDialogDisplayed = !localCacheService.getCacheElement(CACHE_FIRST_CONNECTION_KEY);
    },

    methods: {
        onInput(val) {
            if (!val && !localCacheService.getCacheElement(CACHE_FIRST_CONNECTION_KEY)) {
                localCacheService.setCacheElement(CACHE_FIRST_CONNECTION_KEY, true);
            }
            this.isDialogDisplayed = val;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/styles/colors';

.rgpd-button {
    font-size: 0.8rem;
}

.rgpd-mention {
    text-align: justify;
    text-justify: inter-word;

    .fist-paragraph {
        margin-top: 16px;
    }

    .identification-data {
        margin-bottom: 0;
    }

    .identification-data-list {
        margin-bottom: 10px;
    }
}

.break-word {
    word-break: break-word;
}
</style>
