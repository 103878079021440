<template>
    <div>
        <p class="bold">Récapitulatif de votre demande</p>

        <RecapSection
            :unavPeriod="unavPeriod"
            :recipients="recipients"
            :reason="reason"
            :constraint="constraint"
        />

        <SummaryAlert :unavPrevisions="unavPrevisions" />

        <v-row v-if="invalidUnavs.length" no-gutters align="center" justify="start">
            <Spinner v-if="isLoaderDisplayed" />

            <ConflictTable
                v-else
                :invalidUnavailabilities="invalidUnavs"
                :recipients="recipients"
                :reason="reason"
                :employee="employee"
                @perform-action="performAction"
            />
        </v-row>

        <v-row align="center" justify="start" v-if="reason.is_constraint === NO">
            <v-col cols="12">
                <v-form v-model="isValid">
                    <v-text-field
                        outlined
                        label="Ajouter un commentaire (facultatif)"
                        :value="comment"
                        @input="setComment"
                        :counter="512"
                        :rules="commentRules"
                    />
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex';

import unavailabilitiesService from '@/services/unavailabilities';

import { ACTIONS_INDISPO, ACTIONS_INDISPO_LABEL } from '@/constants/unavailabilities';
import { UNAV_ACTIONS } from '@/constants/succesMessages';
import { NO } from '@/constants/shared';

import Spinner from '@/components/Spinner.vue';
import RecapSection from '@/components/sections/unavs/RecapSection.vue';
import SummaryAlert from '@/components/addUnavailability/lastStep/SummaryAlert.vue';
import ConflictTable from '@/components/addUnavailability/conflicts/ConflictTable.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

const fileName = 'SummaryStep.vue';

export default {
    name: 'SummaryStep',

    components: {
        Spinner,
        RecapSection,
        SummaryAlert,
        ConflictTable,
    },

    props: {
        employee: {
            type: Object,
            required: true,
        },
        unavPrevisions: {
            type: Object,
            required: false,
            default: () => {},
        },
    },

    data() {
        return {
            NO,
            isLoaderDisplayed: false,

            isValid: true,
            commentRules: [(v) => v.length <= 512 || 'La taille maximum est de 512!'],
        };
    },

    computed: {
        ...addUnavStore.mapState(['unavPeriod', 'recipients', 'comment']),
        ...addUnavStore.mapState({
            reason: (state) => state.unavReason.reason,
            constraint: (state) => state.unavReason.constraint,
        }),

        invalidUnavs() {
            return this.unavPrevisions && this.unavPrevisions.invalid_unavailabilities
                ? this.unavPrevisions.invalid_unavailabilities
                : [];
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),
        ...addUnavStore.mapMutations(['setComment']),

        async performAction(invalidUnav) {
            try {
                this.showSpinner();
                await unavailabilitiesService.performUnavAction(
                    invalidUnav.id,
                    ACTIONS_INDISPO.ANNULATION,
                    ''
                );
                this.hideSpinner();

                this.showToast({
                    title: UNAV_ACTIONS.TITLE,
                    message: UNAV_ACTIONS.MESSAGE.toParamString(
                        ACTIONS_INDISPO_LABEL[ACTIONS_INDISPO.ANNULATION]
                    ),
                    dismiss: false,
                });
                this.$emit('unav-modification');
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
            }
        },

        showSpinner() {
            this.isLoaderDisplayed = true;
        },
        hideSpinner() {
            this.isLoaderDisplayed = false;
        },
    },
};
</script>
