export const CACHE_ALLOWANCE_KEY = 'paramLocalCache';
export const CACHE_FILTERS_KEY = 'calendarFiltersCache';
export const CACHE_SELECTED_USERS_KEY = 'selectedUsersCache';

export const CACHE_SUPERVISION_KEY = 'supervisionCache';
export const CACHE_FIRST_CONNECTION_KEY = 'firstConnectionCache';

export default {
    CACHE_ALLOWANCE_KEY,
    CACHE_FILTERS_KEY,
    CACHE_SELECTED_USERS_KEY,
    CACHE_SUPERVISION_KEY,
    CACHE_FIRST_CONNECTION_KEY,
};
