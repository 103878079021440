<template>
    <div>
        <v-row no-gutters align="center">
            <v-btn
                class="primary"
                large
                depressed
                tile
                :ripple="false"
                @click="displayAddUnavailability()"
            >
                <v-icon left>mdi-plus</v-icon>
                Nouvelle indisponibilité
            </v-btn>
            <v-spacer />

            <TextTreePicker
                v-if="perimeterTree && perimeterTree.length"
                textLabel="Entité ou personne"
                :treeData="perimeterTree"
                :selectedKeys="selectedGids"
                @update-selected="onSelectedUsersChange"
            />
            <v-spacer />

            <div v-if="isSmallScreen">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="font-neutral" icon large @click="showFilters()" v-on="on">
                            <v-icon medium>mdi-filter-variant</v-icon>
                        </v-btn>
                    </template>
                    <span>Filtres d'affichage</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn class="font-neutral" icon large @click="showGroups()" v-on="on">
                            <v-icon medium>mdi-account-multiple</v-icon>
                        </v-btn>
                    </template>
                    <span>Mes groupes</span>
                </v-tooltip>
            </div>
            <div v-else>
                <v-btn
                    class="mx-2"
                    color="secondary"
                    tile
                    :ripple="false"
                    depressed
                    large
                    @click="showFilters()"
                >
                    <v-icon medium>mdi-filter-variant</v-icon>
                    Filtres
                </v-btn>
                <v-btn
                    class="mx-2"
                    color="secondary"
                    tile
                    :ripple="false"
                    depressed
                    large
                    @click="showGroups()"
                >
                    <v-icon medium>mdi-account-multiple</v-icon>
                    Mes groupes
                </v-btn>
            </div>
        </v-row>

        <v-navigation-drawer
            v-if="isNavDisplayed"
            width="400"
            v-model="isNavDisplayed"
            absolute
            temporary
            hide-overlay
            right
        >
            <v-tabs fixed-tabs :value="actualTab">
                <v-tab @click="showFilters()">Affichage</v-tab>
                <v-tab @click="showGroups()" class="text-no-wrap">Mes groupes</v-tab>
            </v-tabs>

            <v-tabs-items v-model="actualTab">
                <Spinner v-if="isNavSpinnerDisplayed" />

                <v-tab-item>
                    <FiltersCard
                        v-if="filters"
                        :filters="filters"
                        @update-filters="onFiltersUpdate"
                    />
                </v-tab-item>
                <v-tab-item>
                    <GroupsCard
                        v-if="teams && teams.length && actualTab === 1"
                        :groupsTree="groupsTree"
                        :teams="teams"
                        :perimeterPeople="perimeterPeople"
                        :selectedGids="selectedGids"
                        @selected-users-change="onSelectedUsersChange"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, createNamespacedHelpers } from 'vuex';

import teamsApi from '@/api/teams';

import localCacheService from '@/services/localCache';
import sharedService, { sortAndOrderPeople } from '@/services/shared';

import { CACHE_SELECTED_USERS_KEY, CACHE_FILTERS_KEY } from '@/constants/localCache';

import TextTreePicker from '@/components/pickers/TextTreePicker.vue';
import GroupsCard from '@/components/calendar/calendarParams/GroupsCard.vue';
import FiltersCard from '@/components/calendar/calendarParams/FiltersCard.vue';
import Spinner from '@/components/Spinner.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
};
const perimeterNameSpace = createNamespacedHelpers('perimeter');
const perimeterStore = {
    mapGetters: perimeterNameSpace.mapGetters,
    mapActions: perimeterNameSpace.mapActions,
};
const groupsNameSpace = createNamespacedHelpers('groups');
const groupsStore = {
    mapGetters: groupsNameSpace.mapGetters,
    mapActions: groupsNameSpace.mapActions,
};

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapMutations: addUnavNameSpace.mapMutations,
};

const fileName = 'CalendarParams.vue';

export default {
    name: 'CalendarParams',

    components: {
        TextTreePicker,
        GroupsCard,
        FiltersCard,
        Spinner,
    },

    data() {
        return {
            isNavDisplayed: false,
            isNavSpinnerDisplayed: false,
            actualTab: 1,

            teams: [],

            selectedUsers: [],
            filters: undefined,
        };
    },

    computed: {
        ...mapState(['isCacheAllowed']),
        ...mapGetters(['isScreenXS', 'isScreenSM']),
        ...employeeStore.mapState(['employee']),
        ...perimeterStore.mapGetters(['perimeterTree', 'perimeterPeople']),
        ...groupsStore.mapGetters(['groupsTree']),

        selectedGids() {
            return this.selectedUsers.map((user) => user.gid_collaborateur);
        },
        isSmallScreen() {
            return this.isScreenXS || this.isScreenSM;
        },
    },

    async created() {
        this.showSpinner();
        await this.loadTeams();
        await this.loadPerimeter();
        this.hideSpinner();

        this.initSelectedUsers();
        this.initFilters();
    },

    watch: {
        isCacheAllowed(newVal) {
            if (newVal) {
                this.onCacheAllowanceChange();
            }
        },
    },

    methods: {
        ...mapMutations(['showSpinner', 'hideSpinner', 'addFatalError']),
        ...perimeterStore.mapActions(['loadPerimeter']),
        ...groupsStore.mapActions(['loadGroups']),
        ...addUnavStore.mapMutations(['displayAddUnavailability']),

        // INITIALIZERS
        initSelectedUsers() {
            const cacheSelectedUsers = this.getFromCache(CACHE_SELECTED_USERS_KEY);
            if (cacheSelectedUsers && cacheSelectedUsers.length > 0) {
                this.selectedUsers = cacheSelectedUsers;
                this.$emit('update-selected-users', cacheSelectedUsers);
            } else {
                this.onSelectedUsersChange(this.teams);
            }
        },
        initFilters() {
            const cacheFilters = this.getFromCache(CACHE_FILTERS_KEY);
            if (cacheFilters) {
                this.filters = cacheFilters;
                this.$emit('update-filters', cacheFilters);
            } else {
                this.onFiltersUpdate({
                    isWorkRythmDisplayed: true,
                    isDutyCallDisplayed: true,
                    isConstraintsDisplayed: true,
                    displayedZones: [],
                });
            }
        },

        // EVENTS
        onSelectedUsersChange(people) {
            const selectedPeople = sortAndOrderPeople(people, this.employee.gid_collaborateur);
            const selectedUsers = selectedPeople.map((person) => sharedService.mapToUser(person));
            this.selectedUsers = selectedUsers;
            this.saveToCache(CACHE_SELECTED_USERS_KEY, selectedUsers);
            this.$emit('update-selected-users', selectedUsers);
        },
        onFiltersUpdate(newVal) {
            this.filters = newVal;
            this.saveToCache(CACHE_FILTERS_KEY, newVal);
            this.$emit('update-filters', newVal);
        },
        async onCacheAllowanceChange() {
            this.saveToCache(CACHE_SELECTED_USERS_KEY, this.selectedUsers);
            this.saveToCache(CACHE_FILTERS_KEY, this.filters);
        },

        // ACTIONS
        async loadTeams() {
            if (!(this.teams && this.teams.length > 0)) {
                try {
                    this.teams = await teamsApi.getTeams();
                } catch (error) {
                    this.addFatalError({ error, fileName });
                }
            }
        },
        async showGroups() {
            this.showNavSpinner();
            await this.loadGroups();
            this.hideNavSpinner();
            this.showNav(1);
        },
        showFilters() {
            this.showNav(0);
        },
        showNav(tabNumber) {
            this.isNavDisplayed = true;
            this.actualTab = tabNumber;
        },

        // HELPERS
        getFromCache(key, prop = undefined) {
            if (this.isCacheAllowed) {
                return localCacheService.getCacheItem({
                    userGid: this.employee.gid_collaborateur,
                    key,
                    prop,
                });
            }
            return null;
        },
        saveToCache(key, value, prop = undefined) {
            if (this.isCacheAllowed) {
                localCacheService.setCacheItem(
                    {
                        userGid: this.employee.gid_collaborateur,
                        key,
                        prop,
                    },
                    value
                );
            }
        },
        showNavSpinner() {
            this.isNavSpinnerDisplayed = true;
        },
        hideNavSpinner() {
            this.isNavSpinnerDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.v-btn.v-btn--icon.font-neutral {
    color: $neutral;
}

::v-deep .v-input__slot {
    padding: 5px 12px;

    &:before {
        border: none !important;
    }
}
</style>
