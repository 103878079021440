<template>
    <v-row no-gutters>
        <v-col cols="12">
            <span class="bold">
                Soldes
                <span v-if="employee.date_maj_solde">
                    (mise à jour S{{ employee.date_maj_solde }})
                </span>
                :
            </span>
        </v-col>

        <v-col :cols="12" :md="12 / colsNumber">
            <span>
                Solde RTT :
                <span class="bold">
                    {{ employee.solde_rtt | plural('jour') }}
                </span>
            </span>
            <br />

            <span>
                Solde CP :
                <span class="bold">
                    {{ employee.solde_cp | plural('jour') }}
                </span>
            </span>
            <br />

            <span>
                Solde GHA :
                <span class="bold">
                    {{ employee.solde_gha | plural('heure') }}
                </span>
            </span>
        </v-col>

        <v-col :cols="12" :md="12 / colsNumber">
            <span>
                Solde GHB :
                <span class="bold">
                    {{ employee.solde_ghb | plural('heure') }}
                </span>
            </span>
            <br />

            <span>
                Solde GHC :
                <span class="bold">
                    {{ employee.solde_ghc | plural('heure') }}
                </span>
            </span>
            <br />

            <span>
                Solde GHD :
                <span class="bold">
                    {{ employee.solde_ghd | plural('heure') }}
                </span>
            </span>
        </v-col>
        <v-col class="mt-4">
            <a
                class="accent--text font-weight-bold text-decoration-underline"
                @click="showCounterBalanceDefinition"
            >
                À quoi correspondent ces soldes?
            </a>

            <CounterBalancedefinition
                v-if="isCounterBalanceDefinitionModalDisplayed"
                v-model="isCounterBalanceDefinitionModalDisplayed"
            />
        </v-col>
    </v-row>
</template>

<script>
import CounterBalancedefinition from '@/components/sections/employee/CounterBalancedefinition.vue';

export default {
    name: 'CounterBalanceSection',

    components: {
        CounterBalancedefinition,
    },

    props: {
        employee: {
            type: Object,
            required: true,
        },
        colsNumber: {
            type: Number,
            required: false,
            default: 1,
        },
    },

    data() {
        return {
            isCounterBalanceDefinitionModalDisplayed: false,
        };
    },

    methods: {
        showCounterBalanceDefinition() {
            this.isCounterBalanceDefinitionModalDisplayed = true;
        },
    },

    filters: {
        plural(number, timeMeasure) {
            if (Math.abs(number) > 1) {
                return `${number} ${timeMeasure}s`;
            }
            return `${number} ${timeMeasure}`;
        },
    },
};
</script>
