function buildRelease(name, version, commitHash = undefined) {
    return `${name}@${version}${commitHash ? ` ${commitHash}` : ''}`;
}

const appName = process.env.VUE_APP_NAME;
const appVersion = process.env.VUE_APP_VERSION;
const appGitCommit = process.env.VUE_APP_BUILD_GIT_COMMIT;
const sentryRelease = buildRelease(appName, appVersion, appGitCommit);

export const sentry = {
    dsn: 'https://3ac65912c9764d9daa4d0b3d6a49f9ab@sentry.io/5991744',
    delimiter: /nos-dispo\/.*\//,
    prefix: 'file://nos-dispo/',
    sentryRelease,
};

export const buildInfos = {
    date: process.env.VUE_APP_BUILD_DATE,
    branch: process.env.VUE_APP_BUILD_GIT_BRANCH,
};

export default {
    name: appName,
    num: appVersion,
    commit: appGitCommit,
    env: process.env.VUE_APP_NODE_ENV, // depend on the NODE_ENV Mode
};
