<template>
    <div>
        <p class="bold">Choisissez le motif de l'indisponibilité</p>

        <v-row no-gutters align="center" justify="start">
            <v-col>
                <v-tabs :value="currentTab" active-class="active-tab" hide-slider>
                    <v-tab @click="selectHR()">RH</v-tab>
                    <v-tab @click="selectConstraint()" :disabled="!canSelectConstraints">
                        Contraintes
                    </v-tab>
                </v-tabs>
            </v-col>

            <v-col offset="1">
                <CounterBalanceSection
                    v-if="recipients && recipients.length === 1"
                    :employee="recipients[0]"
                    :colsNumber="2"
                />
            </v-col>
        </v-row>

        <div class="ma-3">
            <v-row no-gutters align="center" justify="start">
                <v-col cols="12" md="3">
                    <v-autocomplete
                        label="Filtrer par famille"
                        no-data-text="Aucune donnée à afficher."
                        clearable
                        outlined
                        dense
                        :value="selectedFamily"
                        :items="
                            reasonType === REASON_TYPE.HR ? reasonsFamily : constraintsReasonsFamily
                        "
                        @change="onFamilyChange"
                    />
                </v-col>

                <v-col cols="12" md="7" :class="{ 'ml-4': $vuetify.breakpoint.lgAndUp }">
                    <v-autocomplete
                        label="Sélectionner un motif"
                        no-data-text="Aucune donnée à afficher."
                        clearable
                        outlined
                        dense
                        return-object
                        :value="reason"
                        :items="reasonType === REASON_TYPE.HR ? reasons : constraintsReasons"
                        @input="setUnavReason"
                    />
                </v-col>
            </v-row>
        </div>

        <ReasonAlert
            :isPeriodic="isPeriodic"
            :isSpecificHours="isSpecificHours"
            :selectedReason="reason"
        />

        <ConstraintForm
            id="constraintForm"
            v-if="reason && reason.is_constraint !== NO"
            :selectedReason="reason"
            :selectedRecipients="recipients"
        />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { REASON_TYPE_POSITION, REASON_TYPE } from '@/constants/addUnavailability';
import CounterBalanceSection from '@/components/sections/employee/CounterBalanceSection.vue';
import ReasonAlert from '@/components/addUnavailability/reasonStep/ReasonAlert.vue';
import ConstraintForm from '@/components/addUnavailability/reasonStep/ConstraintForm.vue';
import { NO } from '@/constants/shared';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

export default {
    name: 'ReasonStep',

    components: {
        CounterBalanceSection,
        ReasonAlert,
        ConstraintForm,
    },

    props: {
        commonReasons: {
            type: Array,
            required: true,
        },
        isPeriodic: {
            type: Boolean,
            required: true,
        },
        hasRightToSelectConstraints: {
            type: Boolean,
            required: true,
        },
        isSpecificHours: {
            type: Boolean,
            required: true,
        },
        roles: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            selectedFamily: undefined,
            NO,
            REASON_TYPE,
        };
    },

    computed: {
        ...addUnavStore.mapState(['isEditing', 'recipients']),
        ...addUnavStore.mapState({
            reason: (state) => state.unavReason.reason,
            reasonType: (state) => state.unavReason.type,
        }),

        currentTab() {
            return this.reasonType ? REASON_TYPE_POSITION[this.reasonType] : 0;
        },

        canSelectConstraints() {
            return !this.isPeriodic && this.hasRightToSelectConstraints;
        },

        reasonsFamily() {
            return this.commonReasons && this.commonReasons.length
                ? this.commonReasons
                      .filter((reason) => reason.is_constraint === NO)
                      .map((reason) => {
                          let disabled;
                          if (this.isSpecificHours) {
                              disabled = reason.fl_abs_jour !== NO || reason.fl_decompte !== null;
                          }
                          if (!disabled && this.isPeriodic) {
                              disabled = reason.validation_needed !== NO;
                          }

                          return {
                              text: reason.libelle_abs_non_affectee,
                              value: reason.libelle_abs_non_affectee,
                              disabled,
                          };
                      })
                      .sort((a, b) => {
                          return a.text.localeCompare(b.text);
                      })
                : [];
        },
        reasons() {
            return this.commonReasons && this.commonReasons.length
                ? this.commonReasons
                      .filter(
                          (reason) =>
                              reason.is_constraint === NO &&
                              (this.selectedFamily
                                  ? reason.libelle_abs_non_affectee === this.selectedFamily
                                  : true)
                      )
                      .map((reason) => this.mapReason(reason))
                      .sort((a, b) => {
                          return a.text.localeCompare(b.text);
                      })
                : [];
        },

        constraintsReasonsFamily() {
            return this.canSelectConstraints && this.commonReasons && this.commonReasons.length
                ? this.commonReasons
                      .filter((reason) => reason.is_constraint !== NO)
                      .map((reason) => reason.libelle_abs_non_affectee)
                      .sort()
                : [];
        },
        constraintsReasons() {
            return this.canSelectConstraints && this.commonReasons && this.commonReasons.length
                ? this.commonReasons
                      .filter(
                          (reason) =>
                              reason.is_constraint !== NO &&
                              (this.selectedFamily
                                  ? reason.libelle_abs_non_affectee === this.selectedFamily
                                  : true)
                      )
                      .map((reason) => this.mapReason(reason))
                      .sort((a, b) => {
                          return a.text.localeCompare(b.text);
                      })
                : [];
        },
    },

    watch: {
        canSelectConstraints(newVal, oldVal) {
            if (newVal !== oldVal && !newVal) {
                this.selectHR();
            }
        },
    },

    created() {
        if (!this.isEditing) {
            this.selectHR();
        } else {
            const reason = this.mapReason(this.reason);
            this.setUnavReason(reason);
        }
    },

    methods: {
        ...addUnavStore.mapMutations([
            'setReason',
            'setReasonType',
            'setConstraint',
            'initializeConstraint',
        ]),

        onFamilyChange(newFamily) {
            this.selectedFamily = newFamily;
            this.setUnavReason(undefined);
        },
        mapReason(reason) {
            if (!reason) {
                return undefined;
            }

            if (reason.is_constraint === NO) {
                let disabled;
                if (this.isSpecificHours) {
                    disabled = reason.fl_abs_jour !== NO || reason.fl_decompte !== null;
                }
                if (!disabled && this.isPeriodic) {
                    disabled = reason.validation_needed !== NO;
                }

                return {
                    ...reason,
                    text: `${reason.indisponibilite} - ${reason.lib_absence}`,
                    disabled,
                };
            }

            return {
                ...reason,
                text: `${reason.lib_absence}`,
            };
        },

        setUnavReason(newVal) {
            this.setReason(newVal);
            if (newVal && newVal.is_constraint !== NO) {
                this.$nextTick(() =>
                    document.getElementById('constraintForm').scrollIntoView({ behavior: 'smooth' })
                );
            }
        },

        selectHR() {
            this.setConstraint(undefined);
            this.showTab(REASON_TYPE.HR);
        },
        selectConstraint() {
            this.initializeConstraint();
            this.showTab(REASON_TYPE.CONSTRAINT);
        },

        showTab(reasonType) {
            this.selectedFamily = undefined;
            this.setUnavReason(undefined);
            this.setReasonType(reasonType);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__control {
    max-width: 500px;
    width: 500px;
}
</style>
