import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'validations.api.js';

async function getToValidateCount() {
    try {
        const response = await apiClient.get('/unavailabilities/to_validate/count');
        return response.data.count;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.TO_VALIDATE_COUNT, fileName));
    }
}

async function getUnavsToValidate(offset, limit) {
    try {
        const response = await apiClient.get('/unavailabilities/to_validate', {
            params: { offset, limit, order: 'date_created' },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.UNAVAILABILITIES_TO_VALIDATE, fileName)
        );
    }
}

async function getToValidateHistory(offset, limit) {
    try {
        const response = await apiClient.get('/unavailabilities/actions_history', {
            params: { offset, limit, order: 'date_created' },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.TO_VALIDATE_HISTORY, fileName));
    }
}

async function getRescueUnavsToValidate(offset, limit) {
    try {
        const response = await apiClient.get('/unavailabilities/emergency_validations', {
            params: { offset, limit, order: 'date_created' },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.UNAVAILABILITIES_TO_VALIDATE, fileName)
        );
    }
}

async function getRescueToValidateHistory(offset, limit) {
    try {
        const response = await apiClient.get('/unavailabilities/emergency_actions_history', {
            params: { offset, limit, order: 'date_created' },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.TO_VALIDATE_HISTORY, fileName));
    }
}

export default {
    getToValidateCount,
    getUnavsToValidate,
    getToValidateHistory,

    getRescueUnavsToValidate,
    getRescueToValidateHistory,
};
