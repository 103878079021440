const developmentConfig = require('./development');
const recetteConfig = require('./recette');
const testConfig = require('./test');
const preproductionConfig = require('./preproduction');
const productionConfig = require('./production');

const envConfigs = {
    development: {
        url: 'http://localhost:8080',
        oktaIssuer: developmentConfig.ISSUER,
        oktaClientId: developmentConfig.CLIENT_ID,
        apiBaseUrl: developmentConfig.API_BASE_URL,
        oldApiBaseUrl: developmentConfig.OLD_API_BASE_URL,
        assetsBaseUrl: developmentConfig.ASSETS_BASE_URL,
        getFeedbackMobButtonId: developmentConfig.GETFEEDBACK_BUTTON_MOB,
        getFeedbackWebButtonId: developmentConfig.GETFEEDBACK_BUTTON_WEB,
    },
    recette: {
        url: 'https://edispo-rec.engie-solutions.fr',
        oktaIssuer: recetteConfig.ISSUER,
        oktaClientId: recetteConfig.CLIENT_ID,
        apiBaseUrl: recetteConfig.API_BASE_URL,
        oldApiBaseUrl: recetteConfig.OLD_API_BASE_URL,
        assetsBaseUrl: recetteConfig.ASSETS_BASE_URL,
        getFeedbackMobButtonId: recetteConfig.GETFEEDBACK_BUTTON_MOB,
        getFeedbackWebButtonId: recetteConfig.GETFEEDBACK_BUTTON_WEB,
    },
    test: {
        url: 'https://edispo-rec.engie-solutions.fr',
        oktaIssuer: testConfig.ISSUER,
        oktaClientId: testConfig.CLIENT_ID,
        apiBaseUrl: testConfig.API_BASE_URL,
        oldApiBaseUrl: testConfig.OLD_API_BASE_URL,
        assetsBaseUrl: testConfig.ASSETS_BASE_URL,
        getFeedbackMobButtonId: testConfig.GETFEEDBACK_BUTTON_MOB,
        getFeedbackWebButtonId: testConfig.GETFEEDBACK_BUTTON_WEB,
    },
    preproduction: {
        url: 'https://edispo-ppr.engie-solutions.fr',
        oktaIssuer: preproductionConfig.ISSUER,
        oktaClientId: preproductionConfig.CLIENT_ID,
        apiBaseUrl: preproductionConfig.API_BASE_URL,
        oldApiBaseUrl: preproductionConfig.OLD_API_BASE_URL,
        assetsBaseUrl: preproductionConfig.ASSETS_BASE_URL,
        getFeedbackMobButtonId: preproductionConfig.GETFEEDBACK_BUTTON_MOB,
        getFeedbackWebButtonId: preproductionConfig.GETFEEDBACK_BUTTON_WEB,
    },
    production: {
        url: 'https://edispo.engie-solutions.fr',
        oktaIssuer: productionConfig.ISSUER,
        oktaClientId: productionConfig.CLIENT_ID,
        apiBaseUrl: productionConfig.API_BASE_URL,
        oldApiBaseUrl: productionConfig.OLD_API_BASE_URL,
        assetsBaseUrl: productionConfig.ASSETS_BASE_URL,
        getFeedbackMobButtonId: productionConfig.GETFEEDBACK_BUTTON_MOB,
        getFeedbackWebButtonId: productionConfig.GETFEEDBACK_BUTTON_WEB,
    },
};

export default {
    getEnvConfig() {
        switch (window.location.origin) {
            case envConfigs.recette.url:
                return envConfigs.recette;
            case envConfigs.preproduction.url:
                return envConfigs.preproduction;
            case envConfigs.production.url:
                return envConfigs.production;
            default:
                return envConfigs.development;
        }
    },
};
