import Vue from 'vue';
import Vuex from 'vuex';

import state from '@/store/state';
import mutations from '@/store/mutations';
import getters from '@/store/getters';
import actions from '@/store/actions';

import employee from '@/store/modules/employee';
import perimeter from '@/store/modules/perimeter';
import groups from '@/store/modules/groups';
import unavailabilityReasons from '@/store/modules/unavailabilityReasons';
import addUnavailability from '@/store/modules/addUnavailability';

Vue.use(Vuex);

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    modules: {
        employee,
        perimeter,
        groups,
        unavailabilityReasons,
        addUnavailability,
    },
});
