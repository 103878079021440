import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'employee.api.js';

async function getEmployee() {
    try {
        const response = await apiClient.get('employees');
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.EMPLOYEE, fileName));
    }
}
async function getEmployees(rawEmployeesGids) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const response = await apiClient.post('employees/search', {
            employees: formattedUsersGids,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.EMPLOYEE_LIST, fileName));
    }
}

async function getEligibleRecipients() {
    try {
        const response = await apiClient.get('employees/eligible_recipients', {
            params: { order: 'nom' },
        });
        return response.data.items;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.EMPLOYEE_ELIGIBLE_RECIPIENTS, fileName)
        );
    }
}

export default {
    getEmployee,
    getEmployees,
    getEligibleRecipients,
};
