<template>
    <div>
        <p>
            La demande concerne
            <span class="bold">{{ unavailability.nom }} {{ unavailability.prenom }}</span>

            <span
                class="created-by"
                v-if="
                    unavailability.user_created &&
                    unavailability.user_created !== unavailability.gid_collaborateur
                "
            >
                (demandée par :
                <span class="bold">
                    {{ unavailability.nom_created }} {{ unavailability.prenom_created }}
                </span>
                )
            </span>
        </p>

        <p>
            Motif :
            <span v-if="unavailability.indisponibilite && unavailability.lib_absence" class="bold">
                {{ unavailability.lib_absence }}
            </span>
            <span v-else class="insufficient-rights">
                Vous n'avez pas les droits suffisants pour visualiser le motif d'indisponibilité.
            </span>
        </p>

        <ConstraintDetailsSection v-if="isConstraint" :unavailability="unavailability" />

        <p>
            Du
            <span class="bold">{{ unavailability.date_debut | formatDate }}</span>
            au
            <span class="bold">{{ unavailability.date_fin | formatDate }}</span>
        </p>

        <p v-if="unavailability.com_declarant_annulation || unavailability.commentaire_declarant">
            {{ commentLib }} :
            <span class="bold">
                {{
                    unavailability.com_declarant_annulation || unavailability.commentaire_declarant
                }}
            </span>
        </p>
    </div>
</template>

<script>
import moment from 'moment';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';
import unavailabilitiesService from '@/services/unavailabilities';
import ConstraintDetailsSection from '@/components/sections/unavs/ConstraintDetailsSection.vue';

export default {
    name: 'UnavSummarySection',

    props: {
        unavailability: {
            type: Object,
            required: true,
        },
    },

    components: {
        ConstraintDetailsSection,
    },

    computed: {
        commentLib() {
            if (this.isConstraint && this.unavailability.indisponibilite !== 'DEPANN') {
                return 'Consigne';
            }
            return 'Commentaire du déclarant';
        },

        isConstraint() {
            return unavailabilitiesService.isConstraint(this.unavailability);
        },
    },

    filters: {
        formatDate(isoDate) {
            return isoDate ? moment(isoDate).format(DATE_TIME_FORMAT) : '';
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.created-by {
    color: var(--v-primary-base);
    font-style: italic;
}

.insufficient-rights {
    color: $dark-gray;
    font-style: italic;
}
</style>
