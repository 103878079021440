<template>
    <v-dialog
        @input="showDialog"
        :value="isDisplayed"
        scrollable
        max-width="500"
        persistent
        id="add-delegation"
    >
        <v-card tile>
            <v-card-title>
                <span v-if="isEditing">
                    <v-icon dark large class="mr-2">mdi-account-edit</v-icon>
                    Modifier une délégation
                </span>
                <span v-else>
                    <v-icon dark large class="mr-2">mdi-account-plus</v-icon>
                    Ajouter une délégation
                </span>

                <v-spacer />

                <v-btn icon dark @click="showDialog(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-form v-model="isValid">
                <v-container fluid>
                    <v-row>
                        <v-col cols="8">
                            <v-autocomplete
                                label="Choisir un délégué*"
                                autofocus
                                single-line
                                outlined
                                item-value="gid_collaborateur"
                                :item-text="getCandidateFullName"
                                :items="candidates"
                                :rules="selectRules"
                                v-model="newDelegation.gid_collaborateur"
                                v-if="!isEditing"
                            />
                            <v-text-field
                                v-else
                                hide-details
                                disabled
                                outlined
                                :value="getCandidateFullName(delegation)"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-switch
                                label="Délégation permanente"
                                color="primary"
                                hide-details
                                dense
                                v-model="isPermanentDelegation"
                            />
                        </v-col>
                    </v-row>

                    <v-row v-if="!isPermanentDelegation">
                        <v-col cols="6">
                            <TextDatePicker
                                clearable
                                prepend-icon="mdi-calendar"
                                mandatory
                                label="Date de début*"
                                v-model="newDelegation.date_debut"
                                :min="todayDate"
                                :max="newDelegation.date_fin"
                            />
                        </v-col>
                        <v-col cols="6">
                            <TextDatePicker
                                clearable
                                prepend-icon="mdi-calendar"
                                mandatory
                                label="Date de fin*"
                                v-model="newDelegation.date_fin"
                                :min="newDelegation.date_debut"
                                :max="endYearDate"
                            />
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <v-card-actions>
                <v-container fluid>
                    <v-row v-if="isEditing">
                        <v-col cols="12">
                            <v-btn
                                tile
                                large
                                depressed
                                block
                                :ripple="false"
                                class="error"
                                @click="deleteDelegation()"
                            >
                                Supprimer
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-btn
                                tile
                                large
                                depressed
                                block
                                :ripple="false"
                                class="secondary"
                                @click="showDialog(false)"
                            >
                                Annuler
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                v-if="isEditing"
                                tile
                                large
                                depressed
                                block
                                :ripple="false"
                                class="primary"
                                :disabled="!isValid || !hasEdited"
                                @click="editDelegation()"
                            >
                                Mettre à jour
                            </v-btn>
                            <v-btn
                                v-else
                                tile
                                large
                                depressed
                                block
                                :ripple="false"
                                class="primary"
                                :disabled="!isValid"
                                @click="addDelegation()"
                            >
                                Ajouter
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import moment from 'moment';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';
import TextDatePicker from '@/components/pickers/TextDatePicker.vue';

export default {
    name: 'AddDelegation',

    components: {
        TextDatePicker,
    },

    props: {
        value: {
            required: true,
            type: Boolean,
        },
        candidates: {
            required: true,
            type: Array,
        },
        delegation: {
            required: false,
            type: Object,
            default: undefined,
        },
    },

    data() {
        return {
            isValid: true,
            selectRules: [(value) => !!value || 'Veuillez choisir un délégué'],

            newDelegation: {},

            todayDate: moment().format(ISO_DATE_FORMAT),
            tomorrowDate: moment().add(1, 'd').format(ISO_DATE_FORMAT),
            endYearDate: moment().add(1, 'y').format(ISO_DATE_FORMAT),
        };
    },

    computed: {
        isEditing() {
            return this.delegation && this.delegation.gid_collaborateur !== '';
        },
        hasEdited() {
            return this.isEditing
                ? this.delegation.date_debut !== this.newDelegation.date_debut ||
                      this.delegation.date_fin !== this.newDelegation.date_fin
                : false;
        },
        isPermanentDelegation: {
            get() {
                return !this.newDelegation.date_debut && !this.newDelegation.date_fin;
            },
            set(val) {
                this.onPermanentChange(val);
            },
        },
        isDisplayed() {
            return this.value;
        },
    },

    created() {
        if (this.isEditing) {
            this.setNewDelegation(this.delegation);
        } else {
            this.setNewDelegation({
                gid_collaborateur: '',
                date_debut: this.todayDate,
                date_fin: this.tomorrowDate,
            });
        }
    },

    watch: {
        delegation(newVal) {
            if (newVal) {
                this.setNewDelegation(newVal);
            }
        },
    },

    methods: {
        // ACTIONS
        deleteDelegation() {
            this.$emit('deleted-delegation', this.newDelegation.gid_collaborateur);
            this.showDialog(false);
        },
        addDelegation() {
            this.$emit('added-delegation', this.newDelegation);
            this.showDialog(false);
        },
        editDelegation() {
            this.$emit('edited-delegation', this.newDelegation);
            this.showDialog(false);
        },
        showDialog(newVal) {
            this.$emit('input', newVal);
        },

        // EVENTS
        onPermanentChange(isPermanent) {
            if (isPermanent) {
                this.newDelegation.date_debut = null;
                this.newDelegation.date_fin = null;
            } else if (this.isEditing) {
                this.newDelegation.date_debut = this.delegation.date_debut || this.todayDate;
                this.newDelegation.date_fin = this.delegation.date_fin || this.tomorrowDate;
            } else {
                this.newDelegation.date_debut = this.todayDate;
                this.newDelegation.date_fin = this.tomorrowDate;
            }
        },

        // HELPERS
        getCandidateFullName(candidate) {
            return `${candidate.nom} ${candidate.prenom}`;
        },
        setNewDelegation(delegate) {
            this.newDelegation = {
                gid_collaborateur: delegate.gid_collaborateur,
                date_debut: delegate.date_debut,
                date_fin: delegate.date_fin,
            };
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

::v-deep .v-dialog > .v-card > .v-card__text {
    padding: 20px 5px !important;
}
</style>
