<template>
    <div v-if="label" class="notification-badge">
        {{ label }}
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: [String, Number],
            default: '',
            required: false,
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.notification-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    min-width: 24px;
    padding: 0 8px;
    margin-left: 8px;
    border-radius: 32px;
    background-color: $light-orange;
    color: $neutral;
    font-size: 0.75rem;
}
</style>
