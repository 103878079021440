<template>
    <v-card tile width="300">
        <v-container fluid>
            <v-row no-gutters align="center">
                <v-col class="text-center" cols="10">
                    <span>Garder ma sélection active</span>
                </v-col>
                <v-col cols="2">
                    <v-switch
                        dense
                        :input-value="isCacheAllowed"
                        hide-details
                        @change="updateCacheAllowance"
                    />
                </v-col>
            </v-row>
        </v-container>

        <v-divider />

        <v-card-text>
            <ProfileSummarySection :employee="employee" />
        </v-card-text>

        <v-divider />

        <v-card-text>
            <v-container fluid>
                <v-row class="mb-1" v-if="employee.is_external !== OK" @click="showUserProfile()">
                    Mon profil
                </v-row>

                <v-row @click="showDelegation()">Délégations</v-row>
            </v-container>
        </v-card-text>

        <v-divider />

        <v-card-text>
            <v-container fluid>
                <v-row class="mb-1" @click="goToFAQ()">
                    FAQ
                    <v-spacer />
                    <v-icon>mdi-open-in-new</v-icon>
                </v-row>
                <v-row class="mb-1" @click="goToUserPortal()">
                    Portail eDispo
                    <v-spacer />
                    <v-icon>mdi-open-in-new</v-icon>
                </v-row>
                <v-row @click="goToYammer()">
                    Yammer eDispo
                    <v-spacer />
                    <v-icon>mdi-open-in-new</v-icon>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import ProfileSummarySection from '@/components/sections/employee/ProfileSummarySection.vue';
import { OK } from '@/constants/shared';

export default {
    name: 'ProfileCard',

    components: {
        ProfileSummarySection,
    },

    props: {
        isCacheAllowed: {
            type: Boolean,
            required: true,
        },
        employee: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            OK,
        };
    },

    methods: {
        updateCacheAllowance(newVal) {
            this.$emit('update-cache-allowance', newVal);
        },
        showUserProfile() {
            this.$emit('show-user-profile');
        },
        showDelegation() {
            this.$emit('show-delegation');
        },
        goToFAQ() {
            window.open('https://engie.sharepoint.com/sites/PU_edispo#foire-aux-questions-edispo');
        },
        goToUserPortal() {
            window.open('https://engie.sharepoint.com/sites/PU_edispo');
        },
        goToYammer() {
            window.open(
                'https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNTc2ODIzMSJ9/all'
            );
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}

.row:hover {
    cursor: pointer;
}
</style>
