<template>
    <component :is="layout">
        <router-view />
    </component>
</template>

<script>
import { mapGetters } from 'vuex';
import genLogger, { ConsoleLogger, logLevel, SentryLogger } from '@cofely/genesis-logger';
import appInfo, { sentry } from '@/app.info';
import Default from '@/layouts/Default.vue';
import { initializeLightningjs } from '@/services/getFeedback/getFeebackProvidedScript';
import config from '../env/env-config';

const fileName = 'App.vue';

export default {
    name: 'App',

    components: {
        Default,
    },

    computed: {
        ...mapGetters(['isScreenMD']),

        layout() {
            return this.$route && this.$route.meta && this.$route.meta.layout
                ? this.$route.meta.layout
                : 'Default';
        },
    },

    async created() {
        await this.initializeLogger();
        genLogger.trace(fileName, 'Initializing App.vue...');
        this.initializeGetFeedBack();
    },

    methods: {
        async initializeLogger() {
            genLogger
                .setEnabled(true)
                .setAppInfo(appInfo)
                .register(
                    new ConsoleLogger({
                        minLevel: appInfo.env === 'production' ? logLevel.WARN : logLevel.TRACE,
                    })
                )
                .register(
                    new SentryLogger({
                        enabled: appInfo.env === 'production',
                        minLevel: logLevel.WARN,
                        dsn: sentry.dsn,
                        release: sentry.sentryRelease,
                        shortenStacktrace: {
                            delimiter: sentry.delimiter,
                            prefix: sentry.prefix,
                        },
                        userReportDialogEnabled: false,
                        userReportDialogOptions: {
                            title: `eDispo a planté... :'-(`,
                        },
                    })
                );
        },

        initializeGetFeedBack() {
            const envConfig = config.getEnvConfig();
            const getFeedBackButtonId = this.isScreenMD
                ? envConfig.getFeedbackMobButtonId
                : envConfig.getFeedbackWebButtonId;

            initializeLightningjs();
            // eslint-disable-next-line no-undef
            window.usabilla_live = lightningjs.require(
                'usabilla_live',
                `//w.usabilla.com/${getFeedBackButtonId}.js`
            );
        },
    },
};
</script>

<style lang="scss">
@import '@/styles/elementTree';
@import '@/styles/colors';
@import '@/styles/fonts';

* {
    font-family: 'Lato', sans-serif;
}

#app {
    padding: 0 40px;
    background-color: $neutral-background;
}

.v-text-field {
    border-radius: 0;
}

.v-menu {
    border-radius: 0;
}

.v-btn {
    font-size: 14px;
    font-weight: 700;
    text-transform: none !important;
    letter-spacing: 0;
}

.bold {
    font-weight: bold;
}

.v-tab {
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0;
    text-transform: none;
}

.v-tab--active {
    font-weight: 700;
}

.el-picker-panel {
    width: 100px !important;
}

.v-sheet.v-alert {
    max-width: fit-content;
}

.usabilla_scroller_area {
    top: 10% !important;
}

.v-application {
    .v-btn {
        &.primary,
        &.primary-subtle,
        &.secondary,
        &.error {
            font-weight: normal;

            &:focus {
                position: relative;
                z-index: 1;

                &::before {
                    z-index: 0;
                    opacity: 1 !important;
                    background-color: transparent;
                    content: '';
                    position: absolute;
                    margin: -3px;
                    top: -3px;
                    right: -3px;
                    bottom: -3px;
                    left: -3px;
                    border: 2px solid $primary;
                }
            }
        }

        &.primary {
            &:hover,
            &:focus {
                background-color: $primary-darken-1 !important;
            }

            &:active {
                background-color: $primary-darken-2 !important;
            }
        }

        &.primary-subtle {
            color: $primary;
            background-color: $white;
            border: 1px solid $primary;

            &:hover,
            &:focus {
                color: $primary-darken-1;
                background-color: $primary-lighten-2;
            }

            &:active {
                color: $primary-darken-2;
                background-color: $primary-lighten-1;
            }
        }

        &.secondary {
            color: $neutral;
            background-color: $secondary !important;

            &:hover,
            &:focus {
                background-color: $secondary-darken-1 !important;
            }

            &:active {
                background-color: $secondary-darken-2 !important;
            }
        }

        &.error {
            background-color: $error !important;

            &:hover,
            &:focus {
                background-color: $error-darken-1 !important;
            }

            &:active {
                background-color: $error-darken-2 !important;
            }
        }

        &.v-btn--icon.primary {
            color: $primary;
            background-color: $white !important;

            &:hover {
                background-color: $white !important;
            }
        }
    }
}

.theme--light.v-btn.v-btn--disabled {
    color: $white !important;
    opacity: 0.35;
    cursor: default;
    pointer-events: none;

    &.primary {
        background-color: $primary !important;

        i {
            color: $white !important;
        }
    }

    &.primary-subtle {
        background-color: $white !important;
        color: $primary !important;

        i {
            color: $primary !important;
        }
    }

    &.secondary {
        background-color: $secondary !important;

        i {
            color: $neutral !important;
        }
    }

    &,
    i {
        font-weight: normal;
    }
}

.theme--light.v-icon,
.v-btn:not(.primary):not(.error):not(.primary--text) > .v-btn__content .v-icon {
    color: $neutral;
}

.v-btn:not(.v-btn--round).v-size--default {
    height: 40px;
}

.v-dialog > .v-card > .v-card__title {
    color: $neutral;
    font-size: 1.125rem;

    .v-icon {
        color: $neutral;
    }
}

.select-label {
    cursor: pointer;
    font-size: 0.8rem;
}
</style>
