const RECIPIENT_CONFLICT = 'RECIPIENT_CONFLICT';
const UNAVAILABLE_REASON_CONFLICT = 'UNAVAILABLE_REASON_CONFLICT';
const SPECIFIC_HOURS_CONFLICT = 'SPECIFIC_HOURS_CONFLICT';
const VALIDATION_CONFLICT = 'PERIODIC_VALIDATION_CONFLICT';
const CONSTRAINT_CONFLICT = 'CONSTRAINT_CONFLICT';
export const CONFLICT_ELEMENTS = {
    RECIPIENT_CONFLICT,
    SPECIFIC_HOURS_CONFLICT,
    UNAVAILABLE_REASON_CONFLICT,
    VALIDATION_CONFLICT,
    CONSTRAINT_CONFLICT,
};

const OVERLAP = 'OVERLAP';
const INVALID_ROLE = 'INVALID_ROLE';
const UNAVAILABLE_REASON = 'UNAVAILABLE_REASON';
export const FAILURE_REASONS = {
    OVERLAP,
    INVALID_ROLE,
    UNAVAILABLE_REASON,
};
export const FAILURE_REASONS_LABEL = {
    [OVERLAP]: 'Chevauchement',
    [INVALID_ROLE]: "Rôle insuffisant pour l'action souhaitée",
    [UNAVAILABLE_REASON]: 'Motif non valable',
};

const HALFDAY = 'HALFDAY';
const DAY = 'DAY';
const PERIOD = 'PERIOD';
const SPECIFIC_HOURS = 'SPECIFIC_HOURS';
export const UNAV_PERIOD_TYPE = {
    HALFDAY,
    DAY,
    PERIOD,
    SPECIFIC_HOURS,
};
export const UNAV_PERIOD_TYPE_POSITION = {
    [HALFDAY]: 0,
    [DAY]: 1,
    [PERIOD]: 2,
    [SPECIFIC_HOURS]: 3,
};

const DAILY = 'DAILY';
const WEEKLY = 'WEEKLY';
export const PERIODICITY_TYPE = {
    WEEKLY,
    DAILY,
};
export const PERIODICITY_TYPE_POSITION = {
    [WEEKLY]: 0,
    [DAILY]: 1,
};

export const CONSTRAINT_LVL = {
    AGENCY: 'AGENCY',
    ENTITY: 'ENTITY',
};

const CONSTRAINT = 'CONSTRAINT';
const HR = 'HR';
export const REASON_TYPE = {
    CONSTRAINT,
    HR,
};
export const REASON_TYPE_POSITION = {
    [HR]: 0,
    [CONSTRAINT]: 1,
};

export default {
    CONFLICT_ELEMENTS,

    FAILURE_REASONS,
    FAILURE_REASONS_LABEL,

    UNAV_PERIOD_TYPE,
    UNAV_PERIOD_TYPE_POSITION,

    PERIODICITY_TYPE,
    PERIODICITY_TYPE_POSITION,

    REASON_TYPE,
    REASON_TYPE_POSITION,

    CONSTRAINT_LVL,
};
