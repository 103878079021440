function getWeekDaysByWeekNumbers(weekNumbers) {
    const defaultWeekDays = [1, 2, 3, 4, 5, 6, 0];
    const result = [];
    let i = 0;
    while (i < weekNumbers) {
        result.push(...defaultWeekDays);
        i += 1;
    }
    return result;
}

const DAY_LABEL = 'Jour';
const WEEK_LABEL = 'Semaine';
const TWO_WEEK_LABEL = '2 Semaines';
const MONTH_LABEL = '4 Semaines';
const YEAR_LABEL = 'Année';

const DAY_DEFAULT_WEEKDAYS = [];
const WEEK_DEFAULT_WEEKDAYS = getWeekDaysByWeekNumbers(1);
const TWO_WEEK_DEFAULT_WEEKDAYS = getWeekDaysByWeekNumbers(2);
const MONTH_DEFAULT_WEEKDAYS = getWeekDaysByWeekNumbers(4);
const YEAR_DEFAULT_WEEKDAYS = getWeekDaysByWeekNumbers(53);

const DAY_POSITION = '0';
const WEEK_POSITION = '1';
const TWO_WEEK_POSITION = '2';
const MONTH_POSITION = '3';
const YEAR_POSITION = '4';

export const PERIOD_POSITIONS = {
    DAY_POSITION,
    WEEK_POSITION,
    TWO_WEEK_POSITION,
    MONTH_POSITION,
    YEAR_POSITION,
};

export const PERIOD_LABELS = {
    DAY_LABEL,
    WEEK_LABEL,
    TWO_WEEK_LABEL,
    MONTH_LABEL,
    YEAR_LABEL,
};

export const PERIOD_OPTIONS = [
    { text: DAY_LABEL, position: DAY_POSITION },
    { text: WEEK_LABEL, position: WEEK_POSITION },
    { text: TWO_WEEK_LABEL, position: TWO_WEEK_POSITION },
    { text: MONTH_LABEL, position: MONTH_POSITION },
    { text: YEAR_LABEL, position: YEAR_POSITION },
];

export const DEFAULT_WEEKDAYS = {
    [PERIOD_LABELS.DAY_LABEL]: DAY_DEFAULT_WEEKDAYS,
    [PERIOD_LABELS.WEEK_LABEL]: WEEK_DEFAULT_WEEKDAYS,
    [PERIOD_LABELS.TWO_WEEK_LABEL]: TWO_WEEK_DEFAULT_WEEKDAYS,
    [PERIOD_LABELS.MONTH_LABEL]: MONTH_DEFAULT_WEEKDAYS,
    [PERIOD_LABELS.YEAR_LABEL]: YEAR_DEFAULT_WEEKDAYS,
};

export const DAY_HOURS_NUMBER = 24;
export const HOUR_MINUTES_NUMBER = 60;
export const MAX_PERCENT = 100;

export const AVAILABILITY = {
    IS_AVAILABLE: 'isAvailable',
    MAY_BE_AVAILABLE: 'mayBeAvailable',
    NOT_AVAILABLE: 'notAvailable',
};

export default {
    PERIOD_POSITIONS,
    PERIOD_LABELS,
    PERIOD_OPTIONS,
    DEFAULT_WEEKDAYS,
    DAY_HOURS_NUMBER,
    HOUR_MINUTES_NUMBER,
    MAX_PERCENT,
    AVAILABILITY,
};
