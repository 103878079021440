<template>
    <v-overlay :value="true" absolute :opacity="opacity">
        <v-progress-circular :size="size" :width="width" indeterminate :color="color" />
    </v-overlay>
</template>

<script>
export default {
    name: 'Spinner',

    props: {
        size: {
            type: Number,
            default: 70,
            required: false,
        },
        width: {
            type: Number,
            default: 7,
            required: false,
        },
        color: {
            type: String,
            default: 'primary',
            required: false,
        },
        opacity: {
            type: Number,
            default: 0.3,
            required: false,
        },
    },
};
</script>
