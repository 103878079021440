import perimeterApi from '@/api/perimeter';
import perimeterService from '@/services/perimeter';

const fileName = 'perimeter.store';

const state = {
    perimeter: [],
};

const getters = {
    perimeterTree: (state) => perimeterService.getPerimeterTree(state.perimeter),
    perimeterEntities: (state) => perimeterService.getPerimeterEntities(state.perimeter),
    perimeterPeople: (state) => perimeterService.getPerimeterPeople(state.perimeter),
    perimeterObject: (state, getters) =>
        perimeterService.formatPerimeterPeople(getters.perimeterPeople),
};

const mutations = {
    setPerimeter(state, perimeter) {
        state.perimeter = perimeter;
    },

    clearPerimeter(state) {
        state.perimeter = [];
    },
};

const actions = {
    async loadPerimeter({ commit, state }) {
        if (!(state.perimeter && state.perimeter.length > 0)) {
            try {
                const perimeter = await perimeterApi.getPerimeter();
                commit('setPerimeter', perimeter);
            } catch (error) {
                commit('showErrorToast', { error, fileName }, { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
