<template>
    <v-dialog @input="onValueChange" :value="isDisplayed" scrollable max-width="1000">
        <v-card tile class="delegation-card">
            <v-card-title>
                <span>
                    <v-icon dark large class="mr-2">mdi-account-multiple-check</v-icon>
                    Délégations
                </span>

                <v-spacer />

                <v-btn icon dark @click="onValueChange(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" sm="6">
                            <MyDelegations />
                        </v-col>
                        <v-col cols="12" sm="6">
                            <MyDelegators :delegators="employee.delegations" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import MyDelegators from '@/components/delegation/MyDelegators.vue';
import MyDelegations from '@/components/delegation/MyDelegations.vue';

export default {
    name: 'Delegation',

    components: {
        MyDelegations,
        MyDelegators,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        employee: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isDisplayed() {
            return this.value;
        },
    },

    methods: {
        onValueChange(val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.delegation-card {
    width: 600px;
}
</style>
