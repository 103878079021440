import moment from 'moment';
import { oldApiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';
import { YEAR_WEEK_FORMAT } from '@/constants/dateFormat';

const fileName = 'workRythm.api.js';

async function getWorkRythms(rawEmployeesGids, startDate, endDate) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const startWeek = moment(startDate).format(YEAR_WEEK_FORMAT);
        const endWeek = moment(endDate).format(YEAR_WEEK_FORMAT);

        const response = await oldApiClient.post('work_rate/search', {
            employees: formattedUsersGids,
            period: [
                {
                    start_week: startWeek,
                    end_week: endWeek,
                },
            ],
        });
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.WORK_RYTHMS, fileName));
    }
}

export default {
    getWorkRythms,
};
