<template>
    <v-row no-gutters align="center" justify="start">
        <v-col cols="10" v-if="isPeriodic">
            <v-alert dense border="left" type="info" outlined>
                Seuls les motifs éligibles à la périodicité sont sélectionnables
            </v-alert>
        </v-col>

        <v-col cols="10" v-if="isSpecificHours">
            <v-alert dense border="left" type="info" outlined>
                Seuls les motifs pouvant être déclarés à l'heure sont sélectionnables
            </v-alert>
        </v-col>

        <v-col cols="10" v-if="selectedReason && selectedReason.is_constraint !== NO">
            <v-alert dense border="left" type="info" outlined>
                Rappel : cette déclaration n’est pas liée à GDT
            </v-alert>
        </v-col>

        <v-col cols="10" v-if="selectedReason && selectedReason.balance_related !== NO">
            <v-alert dense border="left" type="warning" outlined>
                Vérifiez les soldes pour ce motif
            </v-alert>
        </v-col>

        <v-col cols="10" v-if="selectedReason && selectedReason.validation_needed !== NO">
            <v-alert dense border="left" type="warning" outlined>
                Indisponibilité soumise à validation
            </v-alert>
        </v-col>

        <v-col cols="10" v-if="selectedReason && selectedReason.proof_needed !== NO">
            <v-alert dense border="left" type="warning" outlined>
                Un justificatif doit être fourni à votre référent(e) RH
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
import { NO } from '@/constants/shared';

export default {
    name: 'ReasonAlert',

    props: {
        isPeriodic: {
            type: Boolean,
            required: true,
        },
        isSpecificHours: {
            type: Boolean,
            required: true,
        },
        selectedReason: {
            type: Object,
            required: false,
            default: () => {},
        },
    },

    data() {
        return {
            NO,
        };
    },
};
</script>
