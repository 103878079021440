import { CACHE_ALLOWANCE_KEY } from '@/constants/localCache';

function setCacheElement(key, item) {
    const data = typeof item === 'string' ? item : JSON.stringify(item);
    localStorage.setItem(key, data);
}
function getCacheElement(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
}
function removeCacheElement(key) {
    localStorage.removeItem(key);
}

function getUserCacheAllowance(userGid) {
    const cacheKey = `${CACHE_ALLOWANCE_KEY}_${userGid}`;
    return getCacheElement(cacheKey);
}
function setUserCacheAllowance(userGid, value) {
    const cacheKey = `${CACHE_ALLOWANCE_KEY}_${userGid}`;
    setCacheElement(cacheKey, value);
}

function getCacheItem({ userGid, key, prop = null }) {
    const cacheKey = prop ? `${key}_${userGid}_${prop}` : `${key}_${userGid}`;
    return getCacheElement(cacheKey);
}
function setCacheItem({ userGid, key, prop = null }, item) {
    const cacheKey = prop ? `${key}_${userGid}_${prop}` : `${key}_${userGid}`;
    setCacheElement(cacheKey, item);
}
function removeCacheItem({ userGid, key, prop = null }) {
    const cacheKey = prop ? `${key}_${userGid}_${prop}` : `${key}_${userGid}`;
    removeCacheElement(cacheKey);
}

export default {
    getUserCacheAllowance,
    setUserCacheAllowance,
    getCacheItem,
    setCacheItem,
    removeCacheItem,
    getCacheElement,
    setCacheElement,
    removeCacheElement,
};
