const routesStateConstant = {
    CALENDAR: {
        url: '/',
        name: 'CalendarPage',
        title: 'Calendrier',
    },
    MY_REQUESTS: {
        url: '/myRequests',
        name: 'MyRequestsPage',
        title: 'Mes demandes',
    },
    MY_VALIDATIONS: {
        url: '/myValidations',
        name: 'MyValidationsPage',
        title: 'Mes validations',
    },
    RESCUE_VALIDATIONS: {
        url: '/rescueValidations',
        name: 'RescueValidationsPage',
        title: 'Mes validations de secours',
    },
    EXTRACTION: {
        url: '/extraction',
        name: 'ExtractionPage',
        title: 'Extraction',
    },
    LAST_CHANGES: {
        url: '/lastChanges',
        name: 'LastChangesPage',
        title: 'Derniers changements',
    },
};

export default routesStateConstant;
