<template>
    <v-dialog
        @input="onValueChange"
        :value="value"
        scrollable
        persistent
        max-width="350"
        @keydown.enter="startSupervisionAs(gidSupervision)"
    >
        <v-card tile>
            <v-card-title>
                <span>
                    <v-icon large class="mr-3" dark>mdi-eye-outline</v-icon>
                    Supervision
                </span>

                <v-spacer />

                <v-btn icon @click="closeSupervisionDialog()">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-form v-model="isValid" @submit.prevent>
                <div>
                    <v-container fluid>
                        <v-text-field
                            label="GID"
                            autofocus
                            @input="onUserInput"
                            :value="gidSupervision"
                            :rules="gidRules"
                            :disabled="isSupervising"
                            required
                        />
                    </v-container>
                </div>
            </v-form>

            <v-card-actions>
                <v-row no-gutters justify="space-between">
                    <v-col cols="5">
                        <v-btn block outlined tile color="accent" @click="closeSupervisionDialog()">
                            Retour
                        </v-btn>
                    </v-col>
                    <v-col cols="6">
                        <v-btn
                            v-if="!isSupervising || isLoading"
                            tile
                            depressed
                            block
                            class="primary"
                            :loading="isLoading"
                            :disabled="!isValid || isLoading"
                            @click="startSupervisionAs(gidSupervision)"
                        >
                            Valider
                        </v-btn>
                        <v-btn v-else block tile depressed color="error" @click="stopSupervision()">
                            Arrêt supervision
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, createNamespacedHelpers } from 'vuex';
import { setImpersonatingGid } from '@/api/client';
import routesStateConstant from '@/constants/routes';
import { CACHE_SUPERVISION_KEY } from '@/constants/localCache';
import localCacheService from '@/services/localCache';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
    mapGetters: employeeNameSpace.mapGetters,
    mapMutations: employeeNameSpace.mapMutations,
    mapActions: employeeNameSpace.mapActions,
};

export default {
    name: 'Supervision',

    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isLoading: null,
            isValid: false,
            gidSupervision: '',
            gidRules: [
                (v) => !!v || 'Le GID est requis !',
                (v) => (v && v.length <= 6) || 'Le GID renseigné est trop long !',
                (v) =>
                    v !== this.employee.gid_collaborateur ||
                    'Vous êtes déjà connecté en tant que vous-même...',
            ],
        };
    },

    computed: {
        ...employeeStore.mapState(['employee', 'admin']),
        ...employeeStore.mapGetters(['isSupervising']),
    },

    created() {
        if (this.isSupervising) {
            this.gidSupervision = this.employee.gid_collaborateur;
        }
    },

    methods: {
        ...mapActions(['clearWholeStore']),
        ...employeeStore.mapMutations(['setAdmin', 'setEmployee']),
        ...employeeStore.mapActions(['loadEmployee']),

        onUserInput(val) {
            this.gidSupervision = val.toUpperCase();
        },
        onValueChange(val) {
            this.$emit('input', val);
        },

        closeSupervisionDialog() {
            this.onValueChange(false);
        },

        async startSupervisionAs(gidSupervision) {
            if (!this.isSupervising || this.isLoading) {
                this.startButtonLoading();
                const employeeToAdmin = this.employee;
                setImpersonatingGid(gidSupervision);
                this.setEmployee({});
                this.clearWholeStore();

                await this.loadEmployee();
                this.setAdmin(employeeToAdmin);
                localCacheService.setCacheElement(CACHE_SUPERVISION_KEY, {
                    admin: employeeToAdmin,
                    employee: this.employee,
                });

                this.goToCalendar();
                this.onValueChange(false);
            }
        },

        stopSupervision() {
            this.startButtonLoading();
            this.setEmployee(this.admin);
            setImpersonatingGid('');
            this.setAdmin(null);
            this.clearWholeStore();

            localCacheService.removeCacheElement(CACHE_SUPERVISION_KEY);
            this.goToCalendar();
            this.onValueChange(false);
        },

        goToCalendar() {
            if (this.$route.name === routesStateConstant.CALENDAR.name) {
                this.$router.go();
            } else {
                this.$router.push({
                    path: routesStateConstant.CALENDAR.url,
                });
            }
        },
        startButtonLoading() {
            this.isLoading = true;
        },
    },
};
</script>
