<template>
    <v-row no-gutters align="center">
        <v-col cols="2">
            <v-btn
                class="primary-subtle"
                tile
                large
                depressed
                :ripple="false"
                :disabled="selectedDate === todayDate"
                @click="toActualDay()"
            >
                Aujourd'hui
            </v-btn>
        </v-col>

        <v-spacer />

        <template>
            <v-btn icon x-large @click="goBack()">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div>
                <ButtonDatePicker
                    text
                    block
                    large
                    :returnFormat="isoFormat"
                    :value="selectedDate"
                    @input="onSelectedDateUpdate"
                />
            </div>
            <v-btn icon x-large @click="goNext()">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </template>

        <v-spacer />

        <v-col cols="2">
            <label class="week-selection">
                <span class="select-label">Période d'affichage</span>
                <v-select
                    hide-details
                    return-object
                    outlined
                    :value="selectedPeriod"
                    :items="periodOptions"
                    @input="onSelectedPeriodUpdate"
                />
            </label>
        </v-col>
    </v-row>
</template>

<script>
import ButtonDatePicker from '@/components/pickers/ButtonDatePicker.vue';
import CalendarPeriodMixin from '@/services/mixins/CalendarPeriodMixin';

export default {
    name: 'CalendarPeriod',

    components: {
        ButtonDatePicker,
    },

    mixins: [CalendarPeriodMixin],
};
</script>

<style lang="scss" scoped>
.week-selection {
    ::v-deep .v-input__slot {
        min-height: unset;

        .v-select__slot {
            max-height: 40px;
        }

        .v-input__append-inner {
            align-self: center;
            margin-top: 0;
        }
    }
}
</style>
