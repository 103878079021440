<template>
    <div>
        <span>
            GID :
            <span class="bold">{{ employee.gid_collaborateur }}</span>
        </span>
        <br />

        <span>
            Matricule :
            <span class="bold">{{ employee.matricule }}</span>
        </span>
        <br />

        <span>
            Code entité :
            <span class="bold">{{ employee.code_entite }}</span>
        </span>
        <br />

        <span>
            Entité :
            <span class="bold">{{ employee.lib_niveau_entite }} {{ employee.nomc_entite }}</span>
        </span>
        <br />

        <span v-if="roles && roles.length">
            Rôles :
            <span class="bold">{{ rolesLitterals }}</span>
        </span>
    </div>
</template>

<script>
import employeeService from '@/services/employee';

export default {
    name: 'ProfileSummarySection',

    props: {
        employee: {
            type: Object,
            required: true,
        },
        roles: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    computed: {
        rolesLitterals() {
            let concatRoles = '';
            if (this.roles) {
                const rolesLibs = employeeService.getRolesLib(this.roles);
                rolesLibs.forEach((role, index) => {
                    concatRoles += index === 0 ? role : `, ${role}`;
                });
            }
            return concatRoles;
        },
    },
};
</script>
