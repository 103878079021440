<template>
    <div>
        <p class="bold">Complétez les informations suivantes</p>

        <Spinner v-if="isSpinnerDisplayed" />

        <v-form ref="form" :value="isValid" @input="setConstraintValidity" @submit.prevent>
            <template v-if="!isRepairManConstraintFamily">
                <v-row no-gutters align="center" justify="start">
                    <v-col cols="3">
                        <v-select
                            outlined
                            dense
                            :value="selectedLevel"
                            @change="onLevelChange"
                            :items="levels"
                            label="Filtrer par"
                            :disabled="!isSameTeam"
                        />
                    </v-col>

                    <v-col cols="7" class="ml-4">
                        <v-autocomplete
                            dense
                            clearable
                            return-object
                            outlined
                            :label="markIfOptional('Numéro d\'installation', propertyNumberRules)"
                            :no-data-text="
                                isPropertiesLoading
                                    ? 'Chargement...'
                                    : 'Aucune installation à afficher.'
                            "
                            :item-text="getPropertyLib"
                            :search-input.sync="propertySearch"
                            :value="property"
                            :items="properties"
                            :rules="propertyNumberRules"
                            :loading="isPropertiesLoading"
                            @input="onPropertyChange"
                        />
                    </v-col>
                </v-row>

                <v-row v-if="isWorksConstraintFamily" no-gutters align="center" justify="start">
                    <v-col cols="10">
                        <v-autocomplete
                            dense
                            clearable
                            return-object
                            outlined
                            :label="markIfOptional('Code chantier', constructionSiteCodeRules)"
                            :no-data-text="
                                isConstructionsLoading
                                    ? 'Chargement...'
                                    : 'Aucun chantier à afficher.'
                            "
                            :item-text="getConstructionLib"
                            :search-input.sync="constructionSearch"
                            :value="construction"
                            :items="constructions"
                            :rules="constructionSiteCodeRules"
                            :loading="isConstructionsLoading"
                            @input="onConstructionChange"
                        />
                    </v-col>
                </v-row>

                <v-row
                    v-if="!isRESPLAConstraintReason && !isCustomerAppointmentConstraintFamily"
                    no-gutters
                    align="center"
                    justify="start"
                >
                    <v-col cols="10">
                        <v-radio-group
                            :value="placementType"
                            @change="setConstraintPlacementType"
                            mandatory
                            row
                            label="Placement"
                        >
                            <v-radio label="fixe" :value="LOCKED_PLACEMENT_VALUE" />
                            <v-radio label="libre" :value="FREE_PLACEMENT_VALUE" />
                        </v-radio-group>
                    </v-col>
                </v-row>

                <v-row v-if="!isRESPLAConstraintReason" no-gutters align="center" justify="start">
                    <v-col cols="10">
                        <v-text-field
                            clearable
                            dense
                            maxlength="60"
                            counter="60"
                            :label="markIfOptional(worksLabelLib, worksLabelRules)"
                            :value="worksLabel"
                            @input="setConstraintWorksLabel"
                            :rules="worksLabelRules"
                        />
                    </v-col>
                </v-row>
            </template>

            <v-row no-gutters align="center" justify="start">
                <v-col cols="10">
                    <v-textarea
                        clearable
                        dense
                        multi-line
                        outlined
                        auto-grow
                        rows="2"
                        :maxlength="255"
                        :counter="255"
                        :label="markIfOptional(commentLib, commentRules)"
                        :value="comment"
                        @input="setComment"
                        :rules="commentRules"
                    />
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex';
import assetsApi from '@/api/assets';
import ConstraintDetailsMixin from '@/services/mixins/ConstraintDetailsMixin';
import { FREE_PLACEMENT_VALUE, LOCKED_PLACEMENT_VALUE } from '@/constants/unavailabilities';
import { CONSTRAINT_LVL } from '@/constants/addUnavailability';
import Spinner from '@/components/Spinner.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

const fileName = 'ConstraintForm.vue';

export default {
    name: 'ConstraintForm',

    components: {
        Spinner,
    },

    mixins: [ConstraintDetailsMixin],

    props: {
        selectedReason: {
            type: Object,
            required: true,
        },
        selectedRecipients: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            isSpinnerDisplayed: false,

            isPropertiesLoading: false,
            property: null,
            propertySearch: null,
            propertyTimeout: null,
            properties: [],

            isConstructionsLoading: false,
            construction: null,
            constructionSearch: null,
            constructionTimeout: null,
            constructions: [],

            selectedLevel: null,

            debounceValue: 500,
            levels: [
                { text: 'Equipe', value: CONSTRAINT_LVL.ENTITY },
                { text: 'Agence', value: CONSTRAINT_LVL.AGENCY },
            ],
            FREE_PLACEMENT_VALUE,
            LOCKED_PLACEMENT_VALUE,
        };
    },

    computed: {
        ...addUnavStore.mapState(['isEditing']),
        ...addUnavStore.mapState({
            isValid: (state) => state.unavReason.constraint.isValid,
            propertyNumber: (state) => state.unavReason.constraint.propertyNumber,
            constructionSiteCode: (state) => state.unavReason.constraint.constructionSiteCode,
            placementType: (state) => state.unavReason.constraint.placementType,
            worksLabel: (state) => state.unavReason.constraint.worksLabel,
            comment: (state) => state.comment,
        }),

        navFullId() {
            const navFullId = this.selectedRecipients[0].entity_nav_full_id;
            return this.selectedLevel === CONSTRAINT_LVL.AGENCY || this.isEditing
                ? this.getAgencyNavFullId(navFullId)
                : navFullId;
        },
        rawRecipientsGids() {
            return this.selectedRecipients.map((recipient) => recipient.gid_collaborateur);
        },

        // RG
        isSameTeam() {
            const base = this.selectedRecipients[0].entity_nav_full_id;
            return this.selectedRecipients.every(
                (recipient) => recipient.entity_nav_full_id === base
            );
        },
        isSameAgency() {
            const base = this.getAgencyNavFullId(this.selectedRecipients[0].entity_nav_full_id);
            return this.selectedRecipients
                .map((recipient) => this.getAgencyNavFullId(recipient.entity_nav_full_id))
                .every((navFullId) => navFullId === base);
        },
    },

    watch: {
        selectedReason(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.onReasonChange();
            }
        },
        propertySearch(newVal) {
            if (newVal !== this.getPropertyLib(this.property) && !this.isPropertiesLoading) {
                if (this.propertyTimeout) {
                    clearTimeout(this.propertyTimeout);
                }
                this.propertyTimeout = setTimeout(() => {
                    this.onPropertySearchChange(newVal);
                }, this.debounceValue);
            }
        },
        constructionSearch(newVal) {
            if (
                newVal !== this.getConstructionLib(this.construction) &&
                !this.isConstructionsLoading
            ) {
                if (this.constructionTimeout) {
                    clearTimeout(this.constructionTimeout);
                }
                this.constructionTimeout = setTimeout(() => {
                    this.onConstructionSearchChange(newVal);
                }, this.debounceValue);
            }
        },
    },

    created() {
        this.onInitialize();
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),
        ...addUnavStore.mapMutations([
            'initializeConstraint',
            'setConstraintValidity',
            'setConstaintPropertyNumber',
            'setConstraintConstructionCode',
            'setConstraintPlacementType',
            'setConstraintWorksLabel',
            'setComment',
        ]),

        // EVENTS
        async onPropertySearchChange(newVal) {
            this.setProperty(null);
            if (this.construction !== null) {
                this.setConstruction(null);
                await this.loadConstructions('', '', this.selectedReason.indisponibilite, '');
            }
            const parsedSearch = this.parseSearchInput(newVal);
            this.loadProperties(parsedSearch.codeSearch, parsedSearch.nameSearch, this.navFullId);
        },
        onConstructionSearchChange(newVal) {
            this.setConstruction(null);
            const parsedSearch = this.parseSearchInput(newVal);
            this.loadConstructions(
                parsedSearch.codeSearch,
                parsedSearch.nameSearch,
                this.selectedReason.indisponibilite,
                this.property ? this.property.id : ''
            );
        },
        async onPropertyChange(newProperty) {
            this.setProperty(newProperty);
            this.setConstruction(null);

            this.showSpinner();
            await this.loadConstructions(
                '',
                '',
                this.selectedReason.indisponibilite,
                newProperty ? newProperty.id : ''
            );
            this.hideSpinner();
        },
        async onConstructionChange(newConstruction) {
            this.setConstruction(newConstruction);

            if (!this.propertyNumber) {
                this.showSpinner();
                await this.loadPropertiesById(newConstruction.property_id, this.navFullId);
                this.setUniqueProperty();
                this.hideSpinner();
            }
        },
        async onInitialize() {
            if (this.isEditing) {
                this.showSpinner();
                this.selectedLevel = CONSTRAINT_LVL.AGENCY;

                if (this.propertyNumber) {
                    await this.loadProperties(this.propertyNumber, '', this.navFullId);
                    this.setUniqueProperty();
                }

                if (this.constructionSiteCode) {
                    await this.loadConstructions(
                        this.constructionSiteCode,
                        '',
                        this.selectedReason.indisponibilite,
                        this.property ? this.property.id : ''
                    );
                    this.setUniqueConstruction();
                }
                this.hideSpinner();
            } else {
                await this.onReasonChange();
            }
        },
        async onReasonChange() {
            this.initializeConstraint();

            if (!this.isSameTeam) {
                this.selectedLevel = CONSTRAINT_LVL.AGENCY;
            } else {
                this.selectedLevel = CONSTRAINT_LVL.ENTITY;
            }

            await this.reset();
        },
        async onLevelChange(newVal) {
            this.selectedLevel = newVal;
            await this.reset();
        },
        async reset() {
            this.showSpinner();
            await this.initProperties();
            await this.initConstructions();
            this.hideSpinner();
        },

        // CLUTCH
        async initProperties() {
            this.setProperty(null);
            await this.loadProperties('', '', this.navFullId);
        },
        async initConstructions() {
            this.setConstruction(null);
            await this.loadConstructions('', '', this.selectedReason.indisponibilite, '');
        },
        setUniqueProperty() {
            if (this.properties && this.properties.length === 1) {
                this.setProperty(this.properties[0]);
            } else {
                this.setProperty(null);
            }
        },
        setUniqueConstruction() {
            if (this.constructions && this.constructions.length === 1) {
                this.setConstruction(this.constructions[0]);
            } else {
                this.setConstruction(null);
            }
        },
        parseSearchInput(search) {
            const separator = ' - ';

            let codeSearch = '';
            let nameSearch = '';
            if (search) {
                const trimmedSearch = search.trim();
                const searchParts = trimmedSearch.split(separator);
                const firstSearchPart = searchParts.shift();
                const endOfSearch = searchParts.join(separator).trim();
                const isFirstSearchPartNumber = !Number.isNaN(parseInt(firstSearchPart, 10));
                if (isFirstSearchPartNumber) {
                    codeSearch = firstSearchPart;
                    nameSearch = endOfSearch;
                } else {
                    nameSearch = trimmedSearch;
                }
            }
            return { codeSearch, nameSearch };
        },

        // API
        async loadPropertiesById(propertyId, navFullId) {
            this.properties = [];
            if (!this.isRepairManConstraintFamily) {
                try {
                    this.showPropertiesLoading();
                    this.properties = await assetsApi.getPropertyById(propertyId, navFullId);
                    this.hidePropertiesLoading();
                } catch (error) {
                    this.hidePropertiesLoading();
                    this.showErrorToast({ error, fileName });
                }
            }
        },
        async loadProperties(codeSearch, nameSearch, navFullId) {
            this.properties = [];
            if (!this.isRepairManConstraintFamily) {
                try {
                    this.showPropertiesLoading();
                    this.properties = await assetsApi.searchProperties(
                        codeSearch,
                        nameSearch,
                        navFullId
                    );
                    this.hidePropertiesLoading();
                } catch (error) {
                    this.hidePropertiesLoading();
                    this.showErrorToast({ error, fileName });
                }
            }
        },
        async loadConstructions(idSearch, nameSearch, indisponibilite, propertyId) {
            this.constructions = [];
            if (this.isWorksConstraintFamily) {
                try {
                    this.showConstructionsLoading();
                    this.constructions = await assetsApi.searchConstructions(
                        idSearch,
                        nameSearch,
                        indisponibilite,
                        propertyId
                    );
                    this.hideConstructionsLoading();
                } catch (error) {
                    this.hideConstructionsLoading();
                    this.showErrorToast({ error, fileName });
                }
            }
        },

        // UTILS
        getAgencyNavFullId(navFullId) {
            return navFullId.slice(0, 15);
        },
        getPropertyLib(property) {
            return property ? `${property.code} - ${property.name}` : '';
        },
        getConstructionLib(construction) {
            return construction ? `${construction.id} - ${construction.work_name}` : '';
        },
        markIfOptional(label, rules) {
            return `${label} ${rules.length === 0 ? '(facultatif) :' : ' :'}`;
        },

        setProperty(property) {
            this.property = property;
            this.setConstaintPropertyNumber(property ? property.code : null);
        },
        setConstruction(construction) {
            this.construction = construction;
            this.setConstraintConstructionCode(construction ? construction.id : null);
        },

        showPropertiesLoading() {
            this.isPropertiesLoading = true;
        },
        hidePropertiesLoading() {
            this.isPropertiesLoading = false;
        },
        showConstructionsLoading() {
            this.isConstructionsLoading = true;
        },
        hideConstructionsLoading() {
            this.isConstructionsLoading = false;
        },
        showSpinner() {
            this.isSpinnerDisplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDisplayed = false;
        },
    },
};
</script>
