import moment from 'moment';
import unavailabilitiesApi from '@/api/unavailabilities';
import employeeService from '@/services/employee';
import { STATUT_INDISPO, UNAV_RIGHTS, ACTIONS_INDISPO } from '@/constants/unavailabilities';
import { OK, ASS_EXP, N1, N2, RH } from '@/constants/shared';
import { ISO_DATE_FORMAT, TIME_FORMAT } from '@/constants/dateFormat';
import { UNAV_PERIOD_TYPE } from '@/constants/addUnavailability';

function getUnavRights(employee, applicant, unavailability) {
    const isEmployeeUnav = applicant.gid_collaborateur === employee.gid_collaborateur;
    const isIntheFuture = moment(unavailability.date_fin).isAfter(moment());
    const isConstraintUnav = isConstraint(unavailability);
    const isWorkRelated = unavailability.fl_assim_travail === OK;
    const applicantRoles = employeeService.getRoles(applicant);

    const isAss = applicantRoles.includes(ASS_EXP);
    const isN1N2 = applicantRoles.some((role) => role === N1 || role === N2);
    const isN1N2Rh = applicantRoles.some((role) => role === N1 || role === N2 || role === RH);
    const isN1N2Ass = applicantRoles.some((role) => role === N1 || role === N2 || role === ASS_EXP);
    const isDelegator = employeeService.isDelegator(applicant);

    const unavRights = [];

    if (isEmployeeUnav) {
        switch (unavailability.statut) {
            case STATUT_INDISPO.VALIDEE:
                unavRights.push(UNAV_RIGHTS.CAN_ASK_TO_CANCEL);
                break;
            case STATUT_INDISPO.AVALIDER:
                if (isIntheFuture) {
                    unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
                break;
            case STATUT_INDISPO.CREE:
                if (isIntheFuture) {
                    unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
                break;
            default:
                break;
        }
        return unavRights;
    }

    if (isConstraintUnav) {
        switch (unavailability.statut) {
            case STATUT_INDISPO.CREE:
                if (isN1N2Ass) {
                    unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                }

                if (isN1N2 || (isIntheFuture && isAss)) {
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
                break;
            default:
                break;
        }
        return unavRights;
    }

    switch (unavailability.statut) {
        case STATUT_INDISPO.VALIDEE:
            if (isN1N2Rh) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                unavRights.push(UNAV_RIGHTS.CAN_EDIT);
            } else if (isDelegator) {
                unavRights.push(UNAV_RIGHTS.CAN_ASK_TO_CANCEL);
            }

            if (isAss) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);

                if (isWorkRelated) {
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
            }
            break;
        case STATUT_INDISPO.ATTENTEANNULATION:
            if (isN1N2Rh) {
                unavRights.push(UNAV_RIGHTS.CAN_ACCEPT);
                unavRights.push(UNAV_RIGHTS.CAN_REFUSE);
            }
            break;
        case STATUT_INDISPO.AVALIDER:
            if (isN1N2Rh) {
                unavRights.push(UNAV_RIGHTS.CAN_ACCEPT);
                unavRights.push(UNAV_RIGHTS.CAN_REFUSE);
                unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
            } else if (isDelegator && isIntheFuture) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                unavRights.push(UNAV_RIGHTS.CAN_EDIT);
            }

            if (isAss) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);

                if (isWorkRelated) {
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
            }
            break;
        case STATUT_INDISPO.CREE:
            if (isN1N2Rh) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                unavRights.push(UNAV_RIGHTS.CAN_EDIT);
            } else if (isDelegator && isIntheFuture) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);
                unavRights.push(UNAV_RIGHTS.CAN_EDIT);
            }

            if (isAss) {
                unavRights.push(UNAV_RIGHTS.CAN_CANCEL);

                if (isWorkRelated) {
                    unavRights.push(UNAV_RIGHTS.CAN_EDIT);
                }
            }
            break;
        default:
            break;
    }

    return unavRights;
}

async function performUnavAction(unavId, action, comment = '') {
    switch (action) {
        case ACTIONS_INDISPO.REFUS:
            await unavailabilitiesApi.updateUnavailability(unavId, STATUT_INDISPO.REFUSEE, comment);
            break;
        case ACTIONS_INDISPO.VALIDATION:
            await unavailabilitiesApi.updateUnavailability(unavId, STATUT_INDISPO.VALIDEE, comment);
            break;
        case ACTIONS_INDISPO.VALIDATION_ANNULATION:
            await unavailabilitiesApi.updateUnavailability(unavId, STATUT_INDISPO.ANNULEE, comment);
            break;
        case ACTIONS_INDISPO.REFUS_ANNULATION:
            await unavailabilitiesApi.updateUnavailability(unavId, STATUT_INDISPO.VALIDEE, comment);
            break;
        case ACTIONS_INDISPO.ANNULATION:
            await unavailabilitiesApi.updateUnavailability(unavId, STATUT_INDISPO.ANNULEE, comment);
            break;
        case ACTIONS_INDISPO.CREATION:
            break;
        case ACTIONS_INDISPO.SOUMISSION:
            break;
        case ACTIONS_INDISPO.DEMANDE_ANNULATION:
            await unavailabilitiesApi.updateUnavailability(
                unavId,
                STATUT_INDISPO.ATTENTEANNULATION,
                comment
            );
            break;
        default:
            break;
    }
}

async function performMassUnavAction(unavailabilities, action, comment = '') {
    switch (action) {
        case ACTIONS_INDISPO.MASS_VALIDATION:
            unavailabilities.forEach(async (unav) => {
                await performUnavAction(unav.id, ACTIONS_INDISPO.VALIDATION, comment);
            });
            break;
        case ACTIONS_INDISPO.MASS_REFUS:
            unavailabilities.forEach(async (unav) => {
                await performUnavAction(unav.id, ACTIONS_INDISPO.REFUS, comment);
            });
            break;
        case ACTIONS_INDISPO.MASS_VALIDATION_ANNULATION:
            unavailabilities.forEach(async (unav) => {
                await performUnavAction(unav.id, ACTIONS_INDISPO.VALIDATION_ANNULATION, comment);
            });
            break;
        case ACTIONS_INDISPO.MASS_REFUS_ANNULATION:
            unavailabilities.forEach(async (unav) => {
                await performUnavAction(unav.id, ACTIONS_INDISPO.REFUS_ANNULATION, comment);
            });
            break;
        default:
            break;
    }
}

function isConstraint(unav) {
    return unav && unav.is_constraint === OK;
}

function getUnavPeriod(unavailability) {
    const startMoment = moment(unavailability.date_debut);
    const endMoment = moment(unavailability.date_fin);

    const startDate = startMoment.format(ISO_DATE_FORMAT);
    const endDate = endMoment.format(ISO_DATE_FORMAT);
    const startTime = startMoment.format(TIME_FORMAT);
    const endTime = endMoment.format(TIME_FORMAT);

    let type;
    if (!startMoment.isSame(endMoment, 'day')) {
        type = UNAV_PERIOD_TYPE.PERIOD;
    } else if (startMoment.format('mm') === '00' && endMoment.format('mm') === '59') {
        const halfdayMinutesDuration = 11 * 60 + 59;
        const minutesDuration = endMoment.diff(startMoment, 'minutes');
        type =
            minutesDuration === halfdayMinutesDuration
                ? UNAV_PERIOD_TYPE.HALFDAY
                : UNAV_PERIOD_TYPE.DAY;
    } else {
        type = UNAV_PERIOD_TYPE.SPECIFIC_HOURS;
    }

    return {
        type,
        startDate,
        endDate,
        startTime,
        endTime,
        periodicity: undefined,
    };
}
function getConstraint(unavailability) {
    if (!isConstraint(unavailability)) {
        return undefined;
    }

    const constraintInfos = unavailability && unavailability.constraint_informations;
    if (constraintInfos) {
        return {
            isValid: true,
            propertyNumber: constraintInfos.property_number,
            constructionSiteCode: constraintInfos.construction_site_code,
            placementType: constraintInfos.placement,
            worksLabel: constraintInfos.works_label,
        };
    }
    return {};
}

export default {
    getUnavRights,

    isConstraint,

    getUnavPeriod,
    getConstraint,

    performUnavAction,
    performMassUnavAction,
};
