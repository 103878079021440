export const DATE_FORMAT = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const ISO_DATE_FORMAT = 'YYYY-MM-DD';
export const ISO_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const YEAR_WEEK_FORMAT = 'YYYYWW';

export default {
    DATE_FORMAT,
    ISO_DATE_FORMAT,
    ISO_DATE_TIME_FORMAT,
    DATE_TIME_FORMAT,
    YEAR_MONTH_FORMAT,
    YEAR_WEEK_FORMAT,
};
