import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'unavailabilities.api.js';

async function getMyUnavailabilities(offset, limit) {
    try {
        const response = await apiClient.get(`unavailabilities/employee`, {
            params: { offset, limit },
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.MY_REQUESTS, fileName));
    }
}

async function getMyUnavailabilitiesCount() {
    try {
        const response = await apiClient.get('/unavailabilities/my_requests/count');
        return response.data.count;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.MY_REQUESTS_COUNT, fileName));
    }
}

async function notifyMyUnavailabilitiesEvent() {
    try {
        const response = await apiClient.post('/events/mesdemandes');
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.MY_REQUESTS_EVENT, fileName));
    }
}

export default {
    getMyUnavailabilities,
    getMyUnavailabilitiesCount,
    notifyMyUnavailabilitiesEvent,
};
