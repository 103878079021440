<template>
    <v-simple-table>
        <template v-slot:default>
            <thead>
                <tr>
                    <th>Bénéficiaire</th>
                    <th>Conflit</th>
                    <th>Date de l'indisponibilité</th>
                    <th>Motif</th>
                    <th v-if="employee">Action</th>
                </tr>
            </thead>

            <tbody>
                <tr v-for="(invalidUnav, index) in invalidUnavs" :key="index">
                    <td>
                        {{ invalidUnav.recipient.nom }}
                        {{ invalidUnav.recipient.prenom }}
                    </td>

                    <td>
                        {{ FAILURE_REASONS_LABEL[invalidUnav.failure_reason] }}
                    </td>

                    <td>
                        Du {{ invalidUnav.date_debut | formatDateTime }} au
                        {{ invalidUnav.date_fin | formatDateTime }}
                    </td>

                    <td>
                        <span v-if="isOverlappedReason(invalidUnav)">
                            {{
                                invalidUnav.is_constraint === OK
                                    ? ''
                                    : `${invalidUnav.indisponibilite} - `
                            }}{{ invalidUnav.lib_absence }}
                        </span>
                        <span v-else>
                            {{ reason.is_constraint === OK ? '' : `${reason.indisponibilite} - `
                            }}{{ reason.lib_absence }}
                        </span>
                    </td>

                    <td v-if="employee">
                        <v-btn
                            v-if="isOverlappedReason(invalidUnav) && invalidUnav.canCancel"
                            depressed
                            tile
                            outlined
                            @click="performAction(invalidUnav)"
                        >
                            <v-icon left>
                                {{ ANNULATION_ICON.icon }}
                            </v-icon>
                            {{ ANNULATION_VERB }}
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment';
import unavailabilitiesService from '@/services/unavailabilities';
import {
    UNAV_RIGHTS,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_ICONS,
} from '@/constants/unavailabilities';
import { OK } from '@/constants/shared';
import { FAILURE_REASONS, FAILURE_REASONS_LABEL } from '@/constants/addUnavailability';
import { DATE_TIME_FORMAT } from '@/constants/dateFormat';

export default {
    name: 'ConflictTable',

    props: {
        invalidUnavailabilities: {
            required: true,
            type: Array,
        },
        recipients: {
            required: true,
            type: Array,
        },
        reason: {
            required: true,
            type: Object,
        },
        employee: {
            required: false,
            type: Object,
            default: undefined,
        },
    },

    data() {
        return {
            OK,
            FAILURE_REASONS_LABEL,
            ANNULATION_VERB: ACTIONS_INDISPO_VERB[ACTIONS_INDISPO.ANNULATION],
            ANNULATION_ICON: ACTIONS_INDISPO_ICONS[ACTIONS_INDISPO.ANNULATION],
        };
    },

    computed: {
        invalidUnavs() {
            return this.hasInvalidUnavailabilities
                ? this.invalidUnavailabilities
                      .map((invalidUnvailability) => this.formatInvalidUnav(invalidUnvailability))
                      .flat()
                : [];
        },
        hasInvalidUnavailabilities() {
            return this.invalidUnavailabilities && this.invalidUnavailabilities.length > 0;
        },
    },

    filters: {
        formatDateTime(date) {
            return date ? moment(date).format(DATE_TIME_FORMAT) : '';
        },
    },

    methods: {
        performAction(invalidUnav) {
            this.$emit('perform-action', invalidUnav);
        },
        isOverlappedReason(invalidUnav) {
            return invalidUnav.failure_reason === FAILURE_REASONS.OVERLAP;
        },

        formatInvalidUnav(invalidUnvailability) {
            const recipient = this.getRecipientFromGid(invalidUnvailability.gid_collaborateur);
            const { overlapped_unavailabilities: overlappedUnavailabilities, ...invalidUnavInfos } =
                invalidUnvailability;

            if (overlappedUnavailabilities && overlappedUnavailabilities.length) {
                return overlappedUnavailabilities.map((overlappedUnav) => {
                    const invalidUnav = {
                        failure_reason: invalidUnavInfos.failure_reason,
                        ...overlappedUnav,
                        recipient,
                    };

                    if (this.employee) {
                        const unavRights = unavailabilitiesService.getUnavRights(
                            this.employee,
                            recipient,
                            overlappedUnav
                        );
                        invalidUnav.canCancel = unavRights.includes(UNAV_RIGHTS.CAN_CANCEL);
                    }
                    return invalidUnav;
                });
            }
            return {
                ...invalidUnavInfos,
                recipient,
            };
        },
        getRecipientFromGid(gid) {
            return this.recipients.find((recipient) => recipient.gid_collaborateur === gid);
        },
    },
};
</script>
