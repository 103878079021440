<template>
    <v-app>
        <DefaultHeader />

        <v-main>
            <v-container class="mt-2" fluid>
                <Toast />

                <AddUnavailability v-if="isAddUnavDisplayed" />

                <Spinner v-if="isSpinnerDiplayed" />

                <FatalErrorViewer v-if="hasFatalError" />
                <slot v-else />
            </v-container>
        </v-main>

        <DefaultFooter />
    </v-app>
</template>

<script>
import { mapGetters, mapState, mapMutations, createNamespacedHelpers } from 'vuex';
import DefaultHeader from '@/components/DefaultHeader.vue';
import DefaultFooter from '@/components/DefaultFooter.vue';
import Toast from '@/components/Toast.vue';
import FatalErrorViewer from '@/components/FatalErrorViewer.vue';
import Spinner from '@/components/Spinner.vue';
import AddUnavailability from '@/components/addUnavailability/AddUnavailability.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
};

export default {
    name: 'LayoutDefault',

    components: {
        DefaultHeader,
        DefaultFooter,
        FatalErrorViewer,
        Toast,
        Spinner,
        AddUnavailability,
    },

    computed: {
        ...mapState(['screenSize', 'isSpinnerDiplayed']),
        ...mapGetters(['hasFatalError']),
        ...addUnavStore.mapState({ isAddUnavDisplayed: 'isDisplayed' }),
    },

    created() {
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },

    methods: {
        ...mapMutations(['setScreenSize']),
        onResize() {
            const actualSize = window.innerWidth;
            if (actualSize !== this.screenSize) {
                this.setScreenSize(actualSize);
            }
        },
    },
};
</script>
