<template>
    <div>
        <div v-show="isScreenXS">Veuillez passer en paysage pour afficher le calendrier.</div>

        <div v-show="!isScreenXS">
            <CalendarParams
                @update-filters="onFiltersUpdate"
                @update-selected-users="onSelectedUsersUpdate"
            />

            <v-divider class="my-6" />

            <CalendarPeriod @update-period="onPeriodUpdate" class="mb-6" />

            <CalendarView
                v-if="period && filters && displayedUsers"
                class="my-2"
                :period="period"
                :filters="filters"
                :displayedUsers="displayedUsers"
                :onDemandUnavLoad.sync="storeOnDemandUnavLoad"
            />

            <CalendarPagination
                v-if="selectedUsers"
                :selectedUsers="selectedUsers"
                @update-displayed-users="onDisplayedUsersUpdate"
            />

            <CalendarLegend class="my-5" />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import CalendarParams from '@/components/calendar/CalendarParams.vue';
import CalendarPeriod from '@/components/calendar/CalendarPeriod.vue';
import CalendarView from '@/components/calendar/CalendarView.vue';
import CalendarPagination from '@/components/calendar/CalendarPagination.vue';
import CalendarLegend from '@/components/calendar/CalendarLegend.vue';

export default {
    name: 'CalendarPage',

    components: {
        CalendarParams,
        CalendarPeriod,
        CalendarView,
        CalendarPagination,
        CalendarLegend,
    },

    data() {
        return {
            filters: undefined,
            selectedUsers: undefined,
            period: undefined,
            displayedUsers: undefined,

            isAddUnavDisplayed: true,
        };
    },

    computed: {
        ...mapState(['onDemandUnavLoad']),
        ...mapGetters(['isScreenXS']),

        storeOnDemandUnavLoad: {
            get() {
                return this.onDemandUnavLoad;
            },
            set(val) {
                this.setOnDemandUnavLoad(val);
            },
        },
    },

    methods: {
        ...mapMutations(['setOnDemandUnavLoad']),

        onSelectedUsersUpdate(newUsers) {
            this.selectedUsers = newUsers;
        },
        onFiltersUpdate(newFilters) {
            this.filters = newFilters;
        },

        onPeriodUpdate(newPeriod) {
            this.period = newPeriod;
        },

        onDisplayedUsersUpdate(newUsers) {
            this.displayedUsers = newUsers;
        },
    },
};
</script>
