<template>
    <v-sheet class="calendarContainer" :class="{ day: isDayPeriod }">
        <v-calendar
            class="edispo-calendar"
            color="primary"
            locale="fr-FR"
            type="custom-weekly"
            :show-month-on-first="false"
            :start="period.startDate"
            :now="period.date"
            :end="period.endDate"
            :weekdays="weekdays"
            :weekday-format="getFormattedWeekDay"
            :value="period.date"
        >
            <template v-slot:day-label="{ date, day }">
                <v-row
                    no-gutters
                    v-if="isWeekPeriod || is2WeekPeriod"
                    :class="{
                        'now-date': date === nowDate,
                    }"
                    class="reduced-font-size"
                >
                    <v-col cols="6">am</v-col>
                    <v-divider vertical />
                    <v-col cols="6">pm</v-col>
                </v-row>

                <v-row
                    no-gutters
                    v-else-if="isDayPeriod"
                    :class="{
                        'now-date': date === nowDate,
                    }"
                >
                    <v-col cols="6">
                        <v-row no-gutters>
                            <v-col
                                cols="1"
                                v-for="(hour, index) in getFormattedHours(0, 12)"
                                :key="index"
                                class="text-right"
                            >
                                {{ hour }}
                                <v-divider vertical />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="6">
                        <v-row no-gutters>
                            <v-col
                                cols="1"
                                v-for="(hour, index) in getFormattedHours(12, 24)"
                                :key="index"
                                class="text-right"
                            >
                                {{ hour }}
                                <v-divider vertical />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-row
                    no-gutters
                    v-else
                    :class="{
                        'now-date': date === nowDate,
                    }"
                >
                    <v-col
                        cols="12"
                        :class="day === 1 ? 'first-day-of-month-label' : 'month-label'"
                    >
                        {{ getFormattedMonth(date) }}
                    </v-col>
                </v-row>
            </template>

            <template v-slot:day="{ date, weekday }">
                <div
                    class="user-day-container"
                    v-if="filters.displayedZones && filters.displayedZones.length"
                >
                    <SchoolHolidayEvents
                        v-if="schoolHolidayEvents && schoolHolidayEvents[date]"
                        :events="schoolHolidayEvents[date]"
                        :displayedZones="filters.displayedZones"
                    />
                </div>

                <div class="user-day-container" v-for="(userGid, i) in displayedUsersGids" :key="i">
                    <WeekendEvents v-if="weekday === 6 || weekday === 0" />
                    <HighlightEvents
                        v-if="highlightEvents && highlightEvents[date]"
                        :events="highlightEvents[date]"
                    />
                    <HolidayEvents
                        v-if="holidayEvents[userGid] && holidayEvents[userGid][date]"
                        :events="holidayEvents[userGid][date]"
                    />
                    <DutyCallEvents
                        v-if="
                            filters.isDutyCallDisplayed &&
                            dutyCallEvents[userGid] &&
                            dutyCallEvents[userGid][date]
                        "
                        :events="dutyCallEvents[userGid][date]"
                    />
                    <WorkRythmEvents
                        v-if="
                            filters.isWorkRythmDisplayed &&
                            workRythmEvents[userGid] &&
                            workRythmEvents[userGid][date]
                        "
                        :events="workRythmEvents[userGid][date]"
                    />
                    <UnavEvents
                        v-if="unavEvents[userGid] && unavEvents[userGid][date]"
                        :events="unavEvents[userGid][date]"
                        @show-unav-event="showEvent"
                    />
                    <ConstraintUnavEvents
                        v-if="
                            filters.isConstraintsDisplayed &&
                            contraintUnavEvents[userGid] &&
                            contraintUnavEvents[userGid][date]
                        "
                        :events="contraintUnavEvents[userGid][date]"
                        @show-unav-event="showEvent"
                    />
                </div>
            </template>
        </v-calendar>

        <UnavActionsModal
            v-if="isActionsModalDisplayed"
            v-model="isActionsModalDisplayed"
            :selectedUnav="selectedUnav"
            @unavs-update="onUnavsUpdate()"
        />

        <v-tooltip
            color="gray"
            bottom
            :activator="selectedElement"
            v-show="isTooltipOpened"
            v-model="isTooltipOpened"
        >
            <UnavTooltipSection v-if="selectedUnav" :selectedUnav="selectedUnav" />
        </v-tooltip>
    </v-sheet>
</template>

<script>
import moment from 'moment';

import { PERIOD_LABELS, DEFAULT_WEEKDAYS } from '@/constants/calendar';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';

import UnavEvents from '@/components/calendar/calendarEvents/UnavEvents.vue';
import ConstraintUnavEvents from '@/components/calendar/calendarEvents/ConstraintUnavEvents.vue';
import WorkRythmEvents from '@/components/calendar/calendarEvents/WorkRythmEvents.vue';
import DutyCallEvents from '@/components/calendar/calendarEvents/DutyCallEvents.vue';
import HolidayEvents from '@/components/calendar/calendarEvents/HolidayEvents.vue';
import WeekendEvents from '@/components/calendar/calendarEvents/WeekendEvents.vue';
import SchoolHolidayEvents from '@/components/calendar/calendarEvents/SchoolHolidayEvents.vue';
import HighlightEvents from '@/components/calendar/calendarEvents/HighlightEvents.vue';

import UnavTooltipSection from '@/components/sections/unavs/UnavTooltipSection.vue';

import UnavActionsModal from '@/components/unavActions/UnavActionsModal.vue';

export default {
    name: 'CalendarContent',

    components: {
        WeekendEvents,
        HolidayEvents,
        DutyCallEvents,
        WorkRythmEvents,
        UnavEvents,
        ConstraintUnavEvents,
        SchoolHolidayEvents,
        HighlightEvents,

        UnavTooltipSection,
        UnavActionsModal,
    },

    props: {
        period: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        displayedUsersGids: {
            type: Array,
            required: true,
        },
        unavEvents: {
            type: Object,
            required: true,
        },
        contraintUnavEvents: {
            type: Object,
            required: true,
        },
        holidayEvents: {
            type: Object,
            required: true,
        },
        workRythmEvents: {
            type: Object,
            required: true,
        },
        dutyCallEvents: {
            type: Object,
            required: true,
        },
        schoolHolidayEvents: {
            type: Object,
            required: true,
        },
        highlightEvents: {
            type: Object,
            required: false,
            default: () => {},
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            nowDate: moment().format(ISO_DATE_FORMAT),

            selectedUnav: undefined,
            selectedElement: null,

            isTooltipOpened: false,
            isActionsModalDisplayed: false,
        };
    },

    computed: {
        isDayPeriod() {
            return this.period.periodType === PERIOD_LABELS.DAY_LABEL;
        },
        isWeekPeriod() {
            return this.period.periodType === PERIOD_LABELS.WEEK_LABEL;
        },
        is2WeekPeriod() {
            return this.period.periodType === PERIOD_LABELS.TWO_WEEK_LABEL;
        },
        isMonthPeriod() {
            return this.period.periodType === PERIOD_LABELS.MONTH_LABEL;
        },
        isYearPeriod() {
            return this.period.periodType === PERIOD_LABELS.YEAR_LABEL;
        },

        weekdays() {
            if (this.isDayPeriod) {
                const weekDay = moment(this.period.date, ISO_DATE_FORMAT).day();
                return [weekDay];
            }
            return DEFAULT_WEEKDAYS[this.period.periodType];
        },
    },

    methods: {
        showEvent({ nativeEvent, unav, isTooltip }) {
            const open = () => {
                this.selectedUnav = unav;
                this.selectedElement = nativeEvent.target;

                if (isTooltip) {
                    requestAnimationFrame(() => requestAnimationFrame(() => this.displayTooltip()));
                } else if (!this.readOnly) {
                    this.displayModal();
                }
            };

            if (this.isActionsModalDisplayed || this.isTooltipOpened) {
                this.hideTooltip();

                if (isTooltip) {
                    requestAnimationFrame(() => requestAnimationFrame(() => open()));
                } else {
                    open();
                }
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        onUnavsUpdate() {
            this.$emit('unavs-update');
        },

        getFormattedHours(startHour, endHour) {
            let i = startHour;
            const hours = [];
            while (i < endHour) {
                i += 1;
                hours.push(`${i}h`);
            }
            return hours;
        },
        getFormattedWeekDay(data) {
            const weekDay = moment(data.date, ISO_DATE_FORMAT).locale('fr').format('ddd');
            if (this.isMonthPeriod || this.isYearPeriod) {
                return `${weekDay.substring(0, 1).toUpperCase()}.${data.day}`;
            }
            return `${weekDay}${data.day}`;
        },
        getFormattedMonth(date) {
            const formattedMonth = moment(date, ISO_DATE_FORMAT).locale('fr').format('MMM');
            return formattedMonth;
        },

        isInPeriod(date, period) {
            if (period) {
                return (
                    moment(date).isSameOrBefore(moment(period.endDate)) &&
                    moment(date).isSameOrAfter(period.startDate)
                );
            }
            return false;
        },

        displayTooltip() {
            this.isTooltipOpened = true;
        },
        hideTooltip() {
            this.isTooltipOpened = false;
        },
        displayModal() {
            this.isActionsModalDisplayed = true;
        },
        hideModal() {
            this.isActionsModalDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

// STRUCTURAL CALENDAR CSS
.calendarContainer {
    overflow-x: auto;
    white-space: nowrap;
}
.calendarContainer.day ::v-deep .v-calendar-weekly {
    min-width: 800px;
}
::v-deep .v-calendar-weekly__day {
    border-right: none !important;
}
::v-deep .v-calendar-weekly__head-weekday,
::v-deep .v-calendar-weekly__day {
    min-width: 50px;
    overflow: visible;
}
::v-deep .v-calendar-weekly__day-label {
    height: 25px;
    max-height: 25px;
    cursor: default;
    border-right: $border-color 1px solid;
    margin: 0px;

    .row {
        height: 100%;
    }
}
.user-day-container {
    height: 31px;
    border-top: $border-color 1px solid;
    border-right: $border-color 1px solid;
}

// BEAUTY
::v-deep .v-calendar-weekly__head-weekday.v-past {
    color: $black !important;
}
::v-deep .v-present .v-calendar-weekly__day-label {
    color: var(--v-primary-base);

    .month-label {
        color: inherit;
    }
}
.first-day-of-month-label {
    color: $black;
}
.month-label {
    color: $dark-gray;
}
.reduced-font-size {
    font-size: 12px;
}
.now-date {
    background-color: $very-light-blue;
}
.highlighted {
    background-color: var(--v-accent-lighten5);
}
</style>
