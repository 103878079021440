<template>
    <v-footer color="transparent" app padless absolute>
        <RGPDMention v-if="isAppLoaded" />
    </v-footer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import RGPDMention from '@/components/RGPDMention.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
};

export default {
    name: 'DefaultFooter',

    components: { RGPDMention },

    computed: {
        ...employeeStore.mapState(['employee']),
        isAppLoaded() {
            return this.employee && this.employee.gid_collaborateur;
        },
    },
};
</script>
