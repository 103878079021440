<template>
    <div class="calendar-pagination">
        <div class="pagination-pages">
            <v-pagination
                v-if="numberOfPage > 1"
                v-model="pageNumber"
                :length="numberOfPage"
                :total-visible="7"
            />
        </div>

        <div class="pagination-pages-number">
            <label>
                <span class="select-label">Résultats par page</span>
                <v-select
                    hide-details
                    outlined
                    :value="numberPerPage"
                    :items="numberOptions"
                    @input="onNumberPerPageChange"
                />
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CalendarPagination',

    props: {
        selectedUsers: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            pageNumber: 1,
            numberPerPage: 20,
            numberOptions: [10, 20, 30],
        };
    },

    computed: {
        numberOfPage() {
            return this.selectedUsers && this.selectedUsers.length > 0
                ? Math.ceil(this.selectedUsers.length / this.numberPerPage)
                : 1;
        },
        displayedUsers() {
            return this.selectedUsers && this.selectedUsers.length > 0
                ? this.selectedUsers.slice(
                      (this.pageNumber - 1) * this.numberPerPage,
                      this.pageNumber * this.numberPerPage
                  )
                : [];
        },
    },

    created() {
        this.updateDisplayedUsers(this.displayedUsers);
    },

    watch: {
        selectedUsers() {
            this.pageNumber = 1;
        },
        displayedUsers(newVal) {
            this.updateDisplayedUsers(newVal);
        },
    },

    methods: {
        onNumberPerPageChange(newVal) {
            this.pageNumber = 1;
            this.numberPerPage = newVal;
        },
        updateDisplayedUsers(displayedUsers) {
            this.$emit('update-displayed-users', displayedUsers);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.calendar-pagination {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    .pagination-pages {
        justify-self: center;

        ::v-deep .v-pagination__item,
        ::v-deep .v-pagination__navigation {
            margin-bottom: 0;
            border-radius: 0;
            border: none;
            transition: background-color 250ms linear;

            &:hover {
                background-color: $secondary !important;
            }
        }

        ::v-deep .v-pagination__item {
            position: relative;
            color: $neutral;
            background-color: transparent !important;

            &:after {
                content: '';
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                border-bottom: solid 2px $primary;
                transform: scaleX(0);
                transition: transform 250ms ease-in-out;
            }

            &:hover {
                &:after {
                    transform: scaleX(1);
                }
            }

            &.v-pagination__item--active {
                background-color: $primary-lighten-2 !important;
                font-weight: bold;

                &:after {
                    transform: scaleX(1);
                    border-bottom: 2px $primary solid;
                }
            }
        }
    }

    .pagination-pages-number {
        max-width: 110px;

        ::v-deep .v-text-field--outlined > .v-input__control > .v-input__slot {
            max-height: 32px;
            min-height: unset;
            display: flex;
            align-items: center;

            .v-input__append-inner {
                align-self: center;
                margin: 0;
            }
        }
    }
}

::v-deep .theme--light.v-pagination .v-pagination__navigation {
    background-color: transparent;
    box-shadow: none;
}

::v-deep .v-pagination__item {
    border: 1px solid $dark-gray;
    box-shadow: none;
}
</style>
