<template>
    <v-autocomplete
        outlined
        v-bind="$attrs"
        :value="value"
        :items="groupsSelection"
        @change="onChange"
    >
        <template v-slot:selection="{ item, index }">
            <div>
                <v-chip v-if="index <= maxChipsDisplayed - 1" color="gray" small>
                    <v-icon v-if="deletableChips" @click.stop="deleteItem(index)" left small>
                        mdi-close-circle
                    </v-icon>
                    <span>{{ item.text || item }}</span>
                </v-chip>

                <span v-if="index === maxChipsDisplayed" class="grey--text text-caption">
                    {{ hiddenItemsLabel }}
                </span>
            </div>
        </template>
    </v-autocomplete>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import groupsService from '@/services/groups';

const groupsNameSpace = createNamespacedHelpers('groups');
const groupsStore = {
    mapGetters: groupsNameSpace.mapGetters,
    mapActions: groupsNameSpace.mapActions,
};

export default {
    name: 'GroupSelectionPicker',

    props: {
        value: {
            type: Array,
            required: true,
        },
        population: {
            type: Array,
            required: true,
        },
        deletableChips: {
            type: Boolean,
            required: false,
            default: false,
        },
        maxChipsDisplayed: {
            type: Number,
            required: false,
            default: 1,
        },
        groupAttribute: {
            type: String,
            required: false,
            default: 'gid_collaborateur',
        },
    },

    computed: {
        ...groupsStore.mapGetters(['groupsTree']),

        hiddenItemsLabel() {
            return this.value.length - this.maxChipsDisplayed === 1
                ? '(+1 autre)'
                : `(+${this.value.length - this.maxChipsDisplayed} autres)`;
        },

        groupsSelection() {
            const groupsSelection = [];
            this.groupsTree.forEach((group) => {
                const groupPeople = [];
                groupsService.collectPeople(group, groupPeople);

                groupsSelection.push({
                    ...groupPeople,
                    text: group.groupname,
                    value: groupPeople.map((person) => person[this.groupAttribute]),
                });
            });
            if (groupsSelection && groupsSelection.length) {
                groupsSelection.push({
                    divider: true,
                });
            }

            this.population.forEach((person) => {
                groupsSelection.push({
                    ...person,
                    text: `${person.nom} ${person.prenom}`,
                    value: person[this.groupAttribute],
                });
            });
            return groupsSelection;
        },
    },

    created() {
        this.loadGroups();
    },

    methods: {
        ...groupsStore.mapActions(['loadGroups']),

        onChange(value) {
            this.$emit('input', value);
        },

        deleteItem(index) {
            const newVal = this.value;
            newVal.splice(index, 1);
            this.onChange(newVal);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__control {
    max-width: 800px;
}
</style>
