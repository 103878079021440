module.exports = {
    NODE_ENV: 'preproduction',
    ISSUER: 'https://engieapppreview.oktapreview.com',
    CLIENT_ID: '0oa5nepeyzDscdLrc0x7',
    API_BASE_URL: 'https://apis-ppr.svc.engie-solutions.fr/availabilities/api/v2',
    OLD_API_BASE_URL: 'https://apis-ppr.svc.engie-solutions.fr/availabilities/api/v1',
    ASSETS_BASE_URL: 'https://apis-ppr.svc.engie-solutions.fr/assets/api/v2',
    GETFEEDBACK_BUTTON_MOB: 'bb9c168b6e22',
    GETFEEDBACK_BUTTON_WEB: '583cceb1a822',
};
