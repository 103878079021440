module.exports = {
    NODE_ENV: 'development',
    ISSUER: 'https://engieapppreview.oktapreview.com',
    CLIENT_ID: '0oa6ieip8IwaQt2Qe0x6',
    API_BASE_URL: 'https://apis-rec1.svc.engie-solutions.fr/availabilities/api/v2',
    OLD_API_BASE_URL: 'https://apis-rec1.svc.engie-solutions.fr/availabilities/api/v1',
    ASSETS_BASE_URL: 'https://apis-rec1.svc.engie-solutions.fr/assets/api/v2',
    GETFEEDBACK_BUTTON_MOB: 'bb9c168b6e22',
    GETFEEDBACK_BUTTON_WEB: '583cceb1a822',
};
