<template>
    <div>
        Responsable N+1 :
        <span class="bold" v-if="employee.nom_n1 || employee.prenom_n1">
            {{ employee.nom_n1 }} {{ employee.prenom_n1 }}
        </span>
        <span v-else>Non renseigné</span>

        <br />

        Responsable N+2 :
        <span class="bold" v-if="employee.nom_n2 || employee.prenom_n2">
            {{ employee.nom_n2 }} {{ employee.prenom_n2 }}
        </span>
        <span v-else>Non renseigné</span>

        <br />

        Interlocuteurs RH :
        <span v-if="employee.rrh && employee.rrh.length > 0" class="bold">
            <span v-for="(rh, index) in employee.rrh" :key="index">
                <span v-if="index === 0">{{ rh.nom }} {{ rh.prenom }}</span>
                <span v-else>, {{ rh.nom }} {{ rh.prenom }}</span>
            </span>
        </span>
        <span v-else>Non renseigné</span>

        <br />

        Mes N-1 :
        <span v-if="employee.nm1 && employee.nm1.length > 0" class="bold">
            <span v-for="(subordinate, index) in employee.nm1" :key="index">
                <span v-if="index === 0">{{ subordinate.nom }} {{ subordinate.prenom }}</span>
                <span v-else>, {{ subordinate.nom }} {{ subordinate.prenom }}</span>
            </span>
        </span>
        <span v-else>Non renseigné</span>
    </div>
</template>

<script>
export default {
    name: 'HierachySection',

    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
};
</script>
