<template>
    <v-dialog @input="showDialog" :value="isDisplayed" max-width="760" scrollable persistent>
        <v-card tile>
            <v-card-title>
                <span v-if="isEditing">
                    <v-icon dark class="mr-2">mdi-pencil</v-icon>
                    Modifier un groupe
                </span>
                <span v-else>
                    <v-icon dark large class="mr-2">mdi-plus</v-icon>
                    Créer un groupe
                </span>

                <v-spacer />

                <v-btn icon dark @click="showDialog(false)">
                    <v-icon dark large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row no-gutters align="center" justify="center">
                        <v-text-field
                            tile
                            autofocus
                            :rules="rules"
                            :counter="20"
                            :maxlength="20"
                            label="Nom du groupe*"
                            class="group-text-field"
                            v-model="groupName"
                        />
                        <el-transfer
                            ref="groupTransferRef"
                            filterable
                            filter-placeholder="Entrer un nom"
                            :filter-method="filterMethod"
                            :titles="[
                                'Mon périmètre',
                                `${groupName ? groupName : 'Nom du groupe'}`,
                            ]"
                            :button-texts="['Retirer', 'Ajouter']"
                            :format="{
                                noChecked: '${total}',
                                hasChecked: '${checked}/${total}',
                            }"
                            :props="{
                                key: 'gid_collaborateur',
                            }"
                            v-model="groupUsersGids"
                            :data="candidates"
                        >
                            <span slot-scope="{ option }">
                                {{ option.nom }} {{ option.prenom }}
                            </span>
                        </el-transfer>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-container fluid>
                    <v-row v-if="isEditing">
                        <v-col cols="12">
                            <v-btn dark outlined tile block color="error" @click="deleteGroup()">
                                Supprimer
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6">
                            <v-btn
                                tile
                                dark
                                block
                                outlined
                                color="grey darken-1"
                                @click="showDialog(false)"
                            >
                                Annuler
                            </v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn
                                v-if="isEditing"
                                tile
                                block
                                depressed
                                class="primary"
                                :disabled="!isValid"
                                @click="updateGroup()"
                            >
                                Mettre à jour
                            </v-btn>
                            <v-btn
                                v-else
                                tile
                                block
                                depressed
                                class="primary"
                                :disabled="!isValid"
                                @click="createGroup()"
                            >
                                Créer
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex';
import groupsApi from '@/api/groups';
import groupsService from '@/services/groups';
import { formatSearchWords } from '@/services/shared';
import { GROUPS } from '@/constants/succesMessages';

const groupsNameSpace = createNamespacedHelpers('groups');
const groupsStore = {
    mapGetters: groupsNameSpace.mapGetters,
    mapActions: groupsNameSpace.mapActions,
};

const fileName = 'AddGroup.vue';

export default {
    name: 'AddGroup',

    props: {
        value: {
            required: true,
            type: Boolean,
        },
        group: {
            required: false,
            type: Object,
            default: undefined,
        },
        candidates: {
            required: true,
            type: Array,
        },
    },

    data() {
        return {
            isDisplayed: this.value,
            rules: [
                (value) => !!value || 'Le nom du groupe est obligatoire',
                (value) => value.length <= 20 || '20 caractères max',
            ],

            groupUsersGids: [],
            groupName: '',

            initalTransferList: [],
        };
    },

    computed: {
        isEditing() {
            return this.group && !!this.group.id;
        },
        isValid() {
            if (!(this.groupName && this.groupUsersGids && this.groupUsersGids.length > 0)) {
                return false;
            }
            if (this.isEditing) {
                return (
                    this.groupName !== this.group.groupname ||
                    this.groupUsersGids.length !== this.initalTransferList.length ||
                    this.groupUsersGids
                        .slice()
                        .sort()
                        .some((value, index) => value !== this.initalTransferList[index])
                );
            }
            return true;
        },
    },

    created() {
        if (this.isEditing) {
            const groupPeople = [];
            groupsService.collectPeople(this.group, groupPeople);
            this.initalTransferList = groupPeople.map((person) => person.gid_collaborateur).sort();

            this.groupUsersGids = this.initalTransferList;
            this.groupName = this.group.groupname;
        }
    },

    watch: {
        value(newVal) {
            this.isDisplayed = newVal;
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),
        ...groupsStore.mapActions(['addGroup', 'editGroup', 'removeGroup']),

        async createGroup() {
            try {
                const createdGroupWithMaj = await groupsApi.createGroup(
                    this.groupName,
                    this.groupUsersGids
                );
                const createdGroup = groupsService.fromGroupToTreeNode(createdGroupWithMaj);
                this.addGroup(createdGroup);
                this.showToast({
                    title: GROUPS.CREATE_GROUPS.TITLE,
                    message: GROUPS.CREATE_GROUPS.MESSAGE,
                    dismiss: false,
                });
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
            this.showDialog(false);
        },
        async updateGroup() {
            try {
                const updatedGroupWithMaj = await groupsApi.updateGroup(
                    this.group.groupid,
                    this.groupName,
                    this.groupUsersGids
                );
                const updatedGroup = groupsService.fromGroupToTreeNode(updatedGroupWithMaj);
                this.editGroup(updatedGroup);
                this.showToast({
                    title: GROUPS.UPDATE_GROUPS.TITLE,
                    message: GROUPS.UPDATE_GROUPS.MESSAGE,
                    dismiss: false,
                });
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
            this.showDialog(false);
        },
        async deleteGroup() {
            try {
                await groupsApi.deleteGroup(this.group.groupid);
                this.removeGroup(this.group.groupid);
                this.showToast({
                    title: GROUPS.DELETE_GROUPS.TITLE,
                    message: GROUPS.DELETE_GROUPS.MESSAGE,
                    dismiss: false,
                });
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
            this.showDialog(false);
        },

        filterMethod(query, item) {
            const label = `${item.nom} ${item.prenom}`;
            const formattedSearchWords = formatSearchWords(query);
            return formattedSearchWords.every((word) =>
                label.toUpperCase().includes(word.toUpperCase())
            );
        },
        showDialog(newVal) {
            this.$emit('input', newVal);
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.group-text-field {
    max-width: 675px;
}

::v-deep .v-dialog > .v-card > .v-card__text {
    padding: 20px 5px !important;
}

::v-deep .el-button {
    color: $white;
    font-weight: 700;
    border-radius: 0;
}

::v-deep .el-input__inner {
    border-radius: 0px;
}
</style>
