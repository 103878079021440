import { SCREEN_WIDTH } from '@/constants/shared';
import employeeService from '@/services/employee';

export default {
    hasFatalError: (state) => state.fatalErrors.length > 0,
    fatalError: (state, getters) => (getters.hasFatalError ? state.fatalErrors[0] : undefined),

    isScreenXS: (state) => state.screenSize < SCREEN_WIDTH.XS,
    isScreenSM: (state) => state.screenSize < SCREEN_WIDTH.SM,
    isScreenMD: (state) => state.screenSize < SCREEN_WIDTH.MD,
    isScreenLG: (state) => state.screenSize < SCREEN_WIDTH.LG,
    isScreenXL: (state) => state.screenSize > SCREEN_WIDTH.XL,

    authorizedPages: (state, getters) =>
        employeeService.getUserAuthorizedPages(getters['employee/roles']),
};
