<template>
    <v-sheet outlined id="calendar-legend">
        <div v-if="isLegendDisplayed" class="ma-2">
            <LegendSection title="Statut" avatarClass="status-avatar" :items="statuses" />
            <LegendSection
                title="Motifs contraintes"
                avatarClass="constraint-avatar"
                :items="constraints_reasons"
            />
            <LegendSection title="Motifs RH" avatarClass="reason-avatar" :items="reasons" />
            <LegendSection
                title="Informations"
                avatarClass="information-avatar"
                :items="informations"
            />
            <LegendSection title="Disponibilité à l'instant t" :items="availabilities" />
        </div>

        <v-btn class="secondary" block depressed tile :ripple="false" @click="toggleLegend()">
            {{ isLegendDisplayed ? 'Masquer la légende' : 'Afficher la légende' }}
            <v-icon>
                {{ isLegendDisplayed ? 'mdi-menu-up' : 'mdi-menu-down' }}
            </v-icon>
        </v-btn>
    </v-sheet>
</template>

<script>
import LegendSection from '@/components/sections/LegendSection.vue';

export default {
    name: 'CalendarLegend',

    components: {
        LegendSection,
    },

    data() {
        return {
            isLegendDisplayed: false,

            constraints_reasons: [
                {
                    class: 'unavailabilities_reason_DEPANN',
                    code: 'DEPANN',
                    title: 'Dépanneur',
                },
                {
                    class: 'unavailabilities_reason_INTSPE',
                    code: 'INTSPE',
                    title: 'Intervention spécifique',
                },
                {
                    class: 'unavailabilities_reason_RDV',
                    code: 'RDV',
                    title: 'RDV client/accompagnement sur site',
                },
                {
                    class: 'unavailabilities_reason_TRA',
                    code: 'TRA',
                    title: 'Travaux',
                },
            ],
            reasons: [
                {
                    class: 'unavailabilities_reason_CNG',
                    code: 'CNG',
                    title: 'Congés',
                },
                {
                    class: 'unavailabilities_reason_MAL',
                    code: 'MAL',
                    title: 'Maladie',
                },
                {
                    class: 'unavailabilities_reason_ATR',
                    code: 'ATR',
                    title: 'Accident du travail',
                },
                {
                    class: 'unavailabilities_reason_THR',
                    code: 'THR',
                    title: 'Heures Récupération',
                },
                {
                    class: 'unavailabilities_reason_FRM',
                    code: 'FRM',
                    title: 'Formation',
                },
                {
                    class: 'unavailabilities_reason_DLG',
                    code: 'DLG',
                    title: 'Délégation',
                },
                {
                    class: 'unavailabilities_reason_FER',
                    code: 'FER',
                    title: 'Fériés',
                },
                {
                    class: 'unavailabilities_reason_FEP',
                    code: 'FEP',
                    title: 'Fériés présence',
                },
                {
                    class: 'unavailabilities_reason_GES',
                    code: 'GES',
                    title: 'Gestion',
                },
                {
                    class: 'unavailabilities_reason_ECT',
                    code: 'ECT',
                    title: 'Encadrement',
                },
                {
                    class: 'unavailabilities_reason_CLI',
                    code: 'CLI',
                    title: 'Relation Client',
                },
                {
                    class: 'unavailabilities_reason_REU',
                    code: 'REU',
                    title: 'Réunion',
                },
                {
                    class: 'unavailabilities_reason_AAB',
                    code: 'AAB',
                    title: 'Autres absences',
                },
            ],
            statuses: [
                {
                    class: 'unavailabilities_reason_waiting',
                    title: 'En attente de validation',
                },
                {
                    class: 'unavailabilities_reason_cancel',
                    title: "En attente d'annulation",
                },
            ],
            informations: [
                {
                    class: 'work-rythm',
                    title: 'Rythme de travail',
                },
                {
                    class: 'duty-call',
                    title: 'Astreinte',
                },
                {
                    class: 'holiday',
                    title: 'Jour Férié',
                },
            ],
            availabilities: [
                {
                    class: 'green',
                    title: 'Disponible',
                },
                {
                    class: 'orange',
                    title: 'Indisponibilité en cours de validation',
                },
                {
                    class: 'red',
                    title: 'Indisponible',
                },
            ],
        };
    },

    methods: {
        toggleLegend() {
            this.isLegendDisplayed = !this.isLegendDisplayed;
        },
    },
};
</script>
