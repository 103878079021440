import { STATUS, STATUT_INDISPO, STATUT_INDISPO_LABEL } from '@/constants/unavailabilities';

export const OK = 'O';
export const YES = 'Y';
export const NO = 'N';

export const CAN_DECLARE_CONSTRAINT = 'can_declare_constraint';

export const ADMIN = 'is_admin';
export const EXTERNAL = 'is_external';
export const ORDO = 'is_ordo';
export const DISPATCHEUR = 'is_dispatcheur';
export const ASS_EXP = 'is_ass_exp';
export const N1 = 'is_n1';
export const N2 = 'is_n2';
export const RH = 'is_rh';
export const DELEGATOR = 'is_delegator';
export const TECHNICIAN = 'is_technician';

export const ROLES = [ADMIN, EXTERNAL, ORDO, DISPATCHEUR, ASS_EXP, N1, N2, RH, TECHNICIAN];
export const ROLES_LIBS = {
    [EXTERNAL]: 'Externe',
    [ADMIN]: 'Administrateur',
    [ORDO]: 'Ordonnanceur',
    [DISPATCHEUR]: 'Dispatcheur',
    [ASS_EXP]: "Assistant(e) d'exploitation",
    [N1]: 'Responsable N+1',
    [N2]: 'Responsable N+2',
    [RH]: 'RRH',
    [TECHNICIAN]: 'Technicien',
};

export const NIVEAUX_ENTITES = {
    EQUIPE: 'EQUIPE',
    DEPARTEMENT: 'DEPARTEMENT',
    AGENCE: 'AGENCE',
};

export const SCREEN_WIDTH = {
    XS: 600,
    SM: 960,
    MD: 1264,
    LG: 1904,
    XL: 1904,
};

export const API_MAX_LIMIT = 2000;

export const STATUS_TEXT_SELECT_DATA = STATUS.map((statut) => ({
    text: STATUT_INDISPO_LABEL[statut],
    value: STATUT_INDISPO[statut],
}));

export default {
    OK,
    YES,
    NO,

    CAN_DECLARE_CONSTRAINT,

    ROLES,
    ROLES_LIBS,

    SCREEN_WIDTH,
    NIVEAUX_ENTITES,
    API_MAX_LIMIT,
};
