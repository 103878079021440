<template>
    <div class="error-page">
        <h1 class="error-title">Erreur</h1>
        <div class="error-message">
            <span>
                Certaines informations nécessaires à la création de l'indisponibilité sont
                manquantes.
            </span>
            <span>Veuillez vérifier que vous avez bien saisi des informations valides.</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FatalStep',
};
</script>

<style scoped lang="scss">
.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-title {
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .error-message {
        font-size: 1.1em;
    }
}
</style>
