<template>
    <div class="calendar-layout">
        <Spinner v-if="isSpinnerDiplayed" />

        <v-row no-gutters>
            <v-col cols="2" sm="3" lg="2">
                <CalendarSideInfos
                    :period="period"
                    :filters="filters"
                    :displayedUsers="displayedUsers"
                    :availabilities="availabilities"
                    :readOnly="readOnly"
                />
            </v-col>
            <v-col cols="10" sm="9" lg="10">
                <CalendarContent
                    :period="period"
                    :filters="filters"
                    :displayedUsersGids="displayedUsersGids"
                    :unavEvents="unavEvents"
                    :contraintUnavEvents="contraintUnavEvents"
                    :holidayEvents="holidayEvents"
                    :workRythmEvents="workRythmEvents"
                    :dutyCallEvents="dutyCallEvents"
                    :schoolHolidayEvents="schoolHolidayEvents"
                    :highlightEvents="highlightEvents"
                    :readOnly="readOnly"
                    @unavs-update="loadUnavailabilities()"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
/* eslint-disable func-names */
import moment from 'moment';
import { mapMutations, createNamespacedHelpers } from 'vuex';

import unavailabilitiesApi from '@/api/unavailabilities';
import holidaysApi from '@/api/holidays';
import dutyCallApi from '@/api/dutyCall';
import workRythmApi from '@/api/workRythm';

import {
    getAvailabilities,
    getUnavs,
    getUnavEvents,
    getConstraintUnavEvents,
    getHolidayEvents,
    getWorkRythmEvents,
    getDutyCallEvents,
    getSchoolHolidaysEvents,
    getHighlightsEvents,
} from '@/services/calendar';

import CalendarSideInfos from '@/components/calendar/calendarView/CalendarSideInfos.vue';
import CalendarContent from '@/components/calendar/calendarView/CalendarContent.vue';
import Spinner from '@/components/Spinner.vue';

const unavailabilityReasonsNameSpace = createNamespacedHelpers('unavailabilityReasons');
const unavailabilityReasonsStore = {
    mapGetters: unavailabilityReasonsNameSpace.mapGetters,
    mapActions: unavailabilityReasonsNameSpace.mapActions,
};

const fileName = 'CalendarView.vue';

export default {
    name: 'CalendarView',

    components: {
        Spinner,
        CalendarSideInfos,
        CalendarContent,
    },

    props: {
        period: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        displayedUsers: {
            type: Array,
            required: true,
        },
        highlightPeriod: {
            type: Object,
            required: false,
            default: undefined,
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
        onDemandUnavLoad: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            isSpinnerDiplayed: false,
            nowDate: moment(),

            unavTimer: null,
            unavInterval: 30000,
            dutyCallTimer: null,
            dutyCallInterval: 180000,

            unavailabilities: [],
            holidays: [],
            workRythms: [],
            dutyCalls: [],

            schoolHolidays: [],
            schoolHolidaysIntervals: {
                startYearInterval: '',
                endYearInterval: '',
            },
        };
    },

    computed: {
        ...unavailabilityReasonsStore.mapGetters(['unavailabilityReasonsObject']),

        displayedUsersGids() {
            return this.displayedUsers.map((user) => user.gid_collaborateur);
        },

        unavs() {
            return getUnavs(
                this.displayedUsers,
                this.unavailabilities,
                this.period.startDate,
                this.period.endDate,
                this.unavailabilityReasonsObject
            );
        },

        unavEvents() {
            return getUnavEvents(
                this.displayedUsers,
                this.unavs,
                this.period.startDate,
                this.period.endDate
            );
        },
        contraintUnavEvents() {
            return getConstraintUnavEvents(
                this.displayedUsers,
                this.unavs,
                this.period.startDate,
                this.period.endDate
            );
        },
        holidayEvents() {
            return getHolidayEvents(
                this.displayedUsers,
                this.holidays,
                this.period.startDate,
                this.period.endDate
            );
        },
        workRythmEvents() {
            return getWorkRythmEvents(
                this.displayedUsers,
                this.workRythms,
                this.period.startDate,
                this.period.endDate
            );
        },
        dutyCallEvents() {
            return getDutyCallEvents(
                this.displayedUsers,
                this.dutyCalls,
                this.period.startDate,
                this.period.endDate
            );
        },
        schoolHolidayEvents() {
            return getSchoolHolidaysEvents(
                this.schoolHolidays,
                this.period.startDate,
                this.period.endDate,
                this.filters.displayedZones
            );
        },
        highlightEvents() {
            if (
                this.highlightPeriod &&
                this.highlightPeriod.startDate &&
                this.highlightPeriod.endDate
            ) {
                return getHighlightsEvents(
                    this.highlightPeriod,
                    this.period.startDate,
                    this.period.endDate
                );
            }
            return {};
        },

        availabilities() {
            return getAvailabilities(
                this.nowDate,
                this.displayedUsers,
                this.workRythmEvents,
                this.unavs
            );
        },
    },

    async created() {
        this.showSpinner();
        await this.loadUnavailabilityReasons();
        await this.loadCalendarData();
        this.hideSpinner();
    },

    mounted() {
        this.unavTimer = setInterval(() => {
            this.loadUnavailabilities();
            this.nowDate = moment();
        }, this.unavInterval);
        this.dutyCallTimer = setInterval(() => {
            this.loadDutyCalls();
        }, this.dutyCallInterval);
    },

    beforeDestroy() {
        clearInterval(this.unavTimer);
        clearInterval(this.dutyCallTimer);
    },

    watch: {
        async period(newVal, oldVal) {
            if (newVal.startDate !== oldVal.startDate || newVal.endDate !== oldVal.endDate) {
                this.showSpinner();
                await this.loadCalendarData();
                this.hideSpinner();
            }
        },
        async displayedUsers(newVal, oldVal) {
            if (newVal && newVal.length > 0 && newVal !== oldVal) {
                this.showSpinner();
                await this.loadCalendarData();
                this.hideSpinner();
            }
        },
        'filters.isDutyCallDisplayed': async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.showSpinner();
                await this.loadDutyCalls();
                this.hideSpinner();
            }
        },
        'filters.isWorkRythmDisplayed': async function (newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.showSpinner();
                await this.loadWorkRythm();
                this.hideSpinner();
            }
        },
        'filters.displayedZones': async function (newVal, oldVal) {
            if (newVal.length && newVal.length !== oldVal.length) {
                this.showSpinner();
                await this.loadSchoolHolidays();
                this.hideSpinner();
            }
        },
        onDemandUnavLoad(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.loadUnavailabilities();
                this.$emit('update:onDemandUnavLoad', false);
            }
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'addFatalError']),
        ...unavailabilityReasonsStore.mapActions(['loadUnavailabilityReasons']),

        // CLUTCH
        async loadCalendarData() {
            await this.loadUnavailabilities();
            await this.loadHolidays();
            if (this.filters.isDutyCallDisplayed) {
                await this.loadDutyCalls();
            }
            if (this.filters.isWorkRythmDisplayed) {
                await this.loadWorkRythm();
            }
            if (this.filters.displayedZones && this.filters.displayedZones.length) {
                await this.loadSchoolHolidays();
            }
        },

        // LOADERS
        async loadUnavailabilities() {
            if (this.displayedUsersGids && this.displayedUsersGids.length > 0) {
                try {
                    const unavailabilities = await unavailabilitiesApi.searchUnavailabilities(
                        this.displayedUsersGids,
                        this.period.startDate,
                        this.period.endDate
                    );
                    this.unavailabilities = unavailabilities;
                } catch (error) {
                    this.addFatalError({ error, fileName });
                }
            } else {
                this.unavailabilities = [];
            }
        },
        async loadHolidays() {
            if (this.displayedUsersGids && this.displayedUsersGids.length > 0) {
                try {
                    const holidays = await holidaysApi.getHolidays(
                        this.displayedUsersGids,
                        this.period.startDate,
                        this.period.endDate
                    );
                    this.holidays = holidays;
                } catch (error) {
                    this.showErrorToast({ error, fileName });
                }
            } else {
                this.holidays = [];
            }
        },
        async loadSchoolHolidays() {
            try {
                const startYearInterval = this.getSchoolHolidayYearInterval(this.period.startDate);
                const endYearInterval = this.getSchoolHolidayYearInterval(this.period.endDate);

                if (
                    !(this.schoolHolidays && this.schoolHolidays.length) ||
                    this.schoolHolidaysIntervals.startYearInterval !== startYearInterval ||
                    this.schoolHolidaysIntervals.endYearInterval !== endYearInterval
                ) {
                    let schoolHolidays = await holidaysApi.getSchoolHolidaysByYear(
                        startYearInterval
                    );

                    if (startYearInterval !== endYearInterval) {
                        const endYearSchoolHolidays = await holidaysApi.getSchoolHolidaysByYear(
                            endYearInterval
                        );
                        schoolHolidays = schoolHolidays.concat(endYearSchoolHolidays);
                    }

                    this.schoolHolidays = schoolHolidays;
                    this.schoolHolidaysIntervals.startYearInterval = startYearInterval;
                    this.schoolHolidaysIntervals.endYearInterval = endYearInterval;
                }
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },
        async loadDutyCalls() {
            if (this.displayedUsersGids && this.displayedUsersGids.length > 0) {
                try {
                    const dutyCalls = await dutyCallApi.getDutyCalls(
                        this.displayedUsersGids,
                        this.period.startDate,
                        this.period.endDate
                    );
                    this.dutyCalls = dutyCalls;
                } catch (error) {
                    this.showErrorToast({ error, fileName });
                }
            } else {
                this.dutyCalls = [];
            }
        },
        async loadWorkRythm() {
            if (this.displayedUsersGids && this.displayedUsersGids.length > 0) {
                try {
                    const workRythms = await workRythmApi.getWorkRythms(
                        this.displayedUsersGids,
                        this.period.startDate,
                        this.period.endDate
                    );
                    this.workRythms = workRythms;
                } catch (error) {
                    this.showErrorToast({ error, fileName });
                }
            } else {
                this.workRythm = [];
            }
        },

        // HELPERS
        getSchoolHolidayYearInterval(date) {
            let year = '';
            const dateMoment = moment(date);
            if (dateMoment.format('MM') >= 9 && dateMoment.format('MM') <= 12) {
                year = dateMoment.format('YYYY');
            } else {
                year = dateMoment.subtract(1, 'years').format('YYYY');
            }
            const interval = `${year}-${Number(year) + 1}`;
            return interval;
        },
        showSpinner() {
            this.isSpinnerDiplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDiplayed = false;
        },
    },
};
</script>

<style scoped>
.calendar-layout {
    position: relative;
}
</style>
