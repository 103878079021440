import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'groups.api.js';

async function getGroups() {
    try {
        const response = await apiClient.get('groups');
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.GET_GROUPS, fileName));
    }
}

async function createGroup(groupName, rawGroupUsersGids) {
    try {
        const formattedUsersGids = rawGroupUsersGids.map((gid) => ({ gid }));
        const response = await apiClient.post('groups', {
            name: groupName,
            employees: formattedUsersGids,
        });
        return response.data.items[0];
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.POST_GROUPS, fileName));
    }
}

async function updateGroup(groupid, groupName, rawGroupUsersGids) {
    try {
        const formattedUsersGids = rawGroupUsersGids.map((gid) => ({ gid }));
        const response = await apiClient.put(`groups/${groupid}`, {
            name: groupName,
            employees: formattedUsersGids,
        });
        return response.data.items[0];
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.PUT_GROUPS, fileName));
    }
}

async function deleteGroup(groupid) {
    try {
        const response = await apiClient.delete(`groups/${groupid}`);
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.GET_GROUPS, fileName));
    }
}

export default {
    getGroups,
    createGroup,
    updateGroup,
    deleteGroup,
};
