<template>
    <v-dialog @input="onValueChange" :value="isDisplayed" scrollable max-width="700">
        <v-card tile>
            <v-card-title>
                <span>Comprendre son compteur de soldes</span>

                <v-spacer />

                <v-btn icon @click="onValueChange(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text class="counter-balance-definition">
                <p class="fist-paragraph">
                    Les compteurs d'heures de récupérations sont divisés de la manière suivante:
                </p>
                <ul>
                    <li>
                        <p>
                            Le compteur de Repos Compensateur de Remplacement (RCR) dédié aux
                            <span class="font-weight-bold">heures d'astreinte</span>
                            (code GHS) regroupe deux compteurs:
                        </p>

                        <p>
                            <span class="font-weight-bold">GHA</span>
                            pour les plus récents (moins de deux mois)
                            <br />
                            <span class="font-weight-bold">GHB</span>
                            pour les plus anciens (plus de deux mois)
                        </p>

                        <p>
                            La prise de récupération est à saisir sous le code
                            <span class="font-weight-bold">
                                RCA-Prise d'heures de récupération Astreinte.
                            </span>
                        </p>
                    </li>
                    <li>
                        <p>
                            Le compteur de Repos Compensateur de Remplacement (RCR) dédié aux
                            <span class="font-weight-bold">heures hors astreinte</span>
                            (code GHR) regroupe deux compteurs :
                        </p>

                        <p>
                            <span class="font-weight-bold">GHC</span>
                            pour les plus récents (moins de deux mois)
                            <br />
                            <span class="font-weight-bold">GHD</span>
                            pour les plus anciens (plus de deux mois)
                        </p>

                        <p>
                            La prise de récupération est à saisir sous le code
                            <span class="font-weight-bold">
                                REC-Prise d'heures de récupération hors Astreinte.
                            </span>
                        </p>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CounterBalancedefinition',

    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isDisplayed: this.value,
        };
    },

    watch: {
        value(newVal) {
            this.isDisplayed = newVal;
        },
    },

    methods: {
        onValueChange(val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style lang="scss" scoped>
.counter-balance-definition {
    text-align: justify;
    text-justify: inter-word;
    word-break: break-word;

    .fist-paragraph {
        margin-top: 16px;
    }
}
</style>
