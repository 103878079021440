<template>
    <v-card tile>
        <v-card-text>
            <div>
                <v-switch
                    :input-value="filters.isDutyCallDisplayed"
                    @change="updateDutyCallDisplay"
                    label="Afficher astreintes"
                    hide-details
                />
                <v-switch
                    :input-value="filters.isWorkRythmDisplayed"
                    @change="updateWorkRythmDisplay"
                    label="Afficher rythmes de travail"
                    hide-details
                />
                <v-switch
                    :input-value="filters.isConstraintsDisplayed"
                    @change="updateConstraintsDisplay"
                    label="Afficher motifs contraintes"
                    hide-details
                />
            </div>

            <v-divider class="my-5" />

            <div class="scool-holiday-zones" fluid>
                <v-checkbox
                    v-model="isAllZonesSelected"
                    :indeterminate="
                        !isAllZonesSelected &&
                        filters.displayedZones &&
                        filters.displayedZones.length !== 0
                    "
                    hide-details
                    label="Zones de vacances scolaires"
                />

                <v-container fluid>
                    <v-checkbox
                        v-for="(zone, index) in zonesData"
                        :key="index"
                        multiple
                        :input-value="filters.displayedZones"
                        @change="updateDisplayedZones"
                        hide-details
                        :value="zone.value"
                        :color="zone.color"
                    >
                        <template v-slot:label>
                            <div :class="zone.value">
                                {{ zone.label }}
                            </div>
                        </template>
                    </v-checkbox>
                </v-container>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
import { ZONES_CHECKBOX_DATA } from '@/constants/holidays';

export default {
    name: 'FiltersCard',

    props: {
        filters: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            zonesData: ZONES_CHECKBOX_DATA,
        };
    },

    computed: {
        isAllZonesSelected: {
            get() {
                return (
                    this.filters.displayedZones &&
                    this.filters.displayedZones.length === this.zonesData.length
                );
            },
            set(val) {
                let displayedZones = [];
                if (val) {
                    displayedZones = this.zonesData.map((zone) => zone.value);
                }
                this.updateDisplayedZones(displayedZones);
            },
        },
    },

    methods: {
        updateDutyCallDisplay(newVal) {
            const { filters } = this;
            filters.isDutyCallDisplayed = !!newVal;
            this.$emit('update-filters', filters);
        },
        updateConstraintsDisplay(newVal) {
            const { filters } = this;
            filters.isConstraintsDisplayed = !!newVal;
            this.$emit('update-filters', filters);
        },
        updateWorkRythmDisplay(newVal) {
            const { filters } = this;
            filters.isWorkRythmDisplayed = !!newVal;
            this.$emit('update-filters', filters);
        },
        updateDisplayedZones(newVal) {
            const { filters } = this;
            filters.displayedZones = newVal;
            this.$emit('update-filters', filters);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.scool-holiday-zones {
    ::v-deep .v-input--selection-controls {
        margin-top: 0px;
        padding-top: 0px;
    }

    .zoneA {
        color: $dark-orange;
    }
    .zoneB {
        color: $dark-green;
    }
    .zoneC {
        color: $light-green;
    }
}
</style>
