<template>
    <div>
        <div>
            <span class="bold">{{ employee.nom }} {{ employee.prenom }}</span>
        </div>

        <v-divider class="my-2" />

        <div>
            <span>
                GID :
                <span class="bold">{{ employee.gid_collaborateur }}</span>
            </span>
            <br />

            <span>
                Matricule :
                <span class="bold">{{ employee.matricule }}</span>
            </span>
            <br />

            <span>
                Responsable N+1 :
                <span v-if="employee.nom_n1 && employee.prenom_n1" class="bold">
                    {{ employee.nom_n1 }} {{ employee.prenom_n1 }}
                </span>
                <span v-else>Non renseigné</span>
            </span>
        </div>

        <v-divider class="my-2" v-if="employee.telephone && employee.telephone.length" />

        <div v-if="employee.telephone && employee.telephone.length">
            <span class="bold">Numéros :</span>
            <br />

            <span v-for="number in employee.telephone" :key="number.id">
                {{ number }}
                <br />
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InfoSummarySection',

    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
};
</script>
