<template>
    <div class="calendar-side-infos">
        <div class="header">
            <v-row no-gutters align="center">
                <v-col cols="12">
                    <v-row no-gutters align="center" justify="center" class="bold">
                        Semaine {{ getWeekNumber(period.startDate) }}
                        <span
                            v-if="getWeekNumber(period.endDate) !== getWeekNumber(period.startDate)"
                        >
                            - {{ getWeekNumber(period.endDate) }}
                        </span>
                    </v-row>

                    <v-row
                        v-if="formatDate(period.startDate) === formatDate(period.endDate)"
                        no-gutters
                        align="center"
                        justify="center"
                        class="subtitle"
                    >
                        {{ formatDate(period.startDate) | elipsis(isScreenSM) }}
                    </v-row>
                    <v-row v-else no-gutters align="center" justify="center" class="subtitle">
                        Du {{ formatDate(period.startDate) | elipsis(isScreenSM, isScreenMD) }} au
                        {{ formatDate(period.endDate) | elipsis(isScreenSM, isScreenMD) }}
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <div v-if="filters.displayedZones && filters.displayedZones.length">
            <v-row no-gutters align="center" justify="center" class="school-holiday-cell">
                <v-col cols="12" class="text-center">
                    {{ 'VACANCES SCOLAIRES' | elipsis(isScreenSM) }}
                </v-col>
            </v-row>
            <v-divider />
        </div>

        <div v-for="user in displayedUsers" :key="user.gid_collaborateur">
            <v-row
                no-gutters
                align="center"
                justify="center"
                class="cell-readOnly"
                :class="{ cell: !readOnly }"
                @click="displayEmployee($event, user)"
            >
                <v-col cols="3" class="text-center">
                    {{ user.code_entite }}
                </v-col>
                <v-col cols="1" class="text-center">
                    <span
                        v-if="actualDateIsInPeriod"
                        class="dot"
                        :class="{
                            green:
                                availabilities[user.gid_collaborateur] ===
                                availability.IS_AVAILABLE,
                            red:
                                availabilities[user.gid_collaborateur] ===
                                availability.NOT_AVAILABLE,
                            orange:
                                availabilities[user.gid_collaborateur] ===
                                availability.MAY_BE_AVAILABLE,
                        }"
                    />
                </v-col>
                <v-col cols="8" class="text-left">
                    {{ `${user.nom} ${user.prenom}` | elipsis(isScreenSM) }}
                </v-col>
            </v-row>
            <v-divider />
        </div>

        <v-menu
            v-model="isMenuOpened"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-y
        >
            <div class="spinner" v-if="isSpinnerDiplayed">
                <Spinner />
            </div>
            <EmployeeInfoCard v-if="displayedEmployee" :employee="displayedEmployee" />
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import employeeService from '@/services/employee';
import { AVAILABILITY } from '@/constants/calendar';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';
import Spinner from '@/components/Spinner.vue';
import EmployeeInfoCard from '@/components/employee/EmployeeInfoCard.vue';

const fileName = 'CalendarSideInfos.vue';

export default {
    name: 'CalendarSideInfos',

    components: {
        Spinner,
        EmployeeInfoCard,
    },

    props: {
        period: {
            type: Object,
            required: true,
        },
        displayedUsers: {
            type: Array,
            required: true,
        },
        availabilities: {
            type: Object,
            required: true,
        },
        filters: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            availability: AVAILABILITY,
            nowDate: moment().format(ISO_DATE_FORMAT),

            displayedEmployee: undefined,
            selectedElement: null,
            isMenuOpened: false,
            isSpinnerDiplayed: false,
        };
    },

    computed: {
        ...mapGetters(['isScreenSM', 'isScreenMD']),

        actualDateIsInPeriod() {
            return (
                moment(this.period.startDate).isSameOrBefore(moment(this.nowDate)) &&
                moment(this.period.endDate).isSameOrAfter(moment(this.nowDate))
            );
        },
    },

    filters: {
        elipsis(word, isScreenSM, isScreenMD = false) {
            if (word.length > 10 && isScreenSM) {
                return `${word.substring(0, 7)}...`;
            }
            if (word.length > 14 && isScreenMD) {
                return `${word.substring(0, 10)}...`;
            }
            return word;
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'addFatalError']),

        displayEmployee(nativeEvent, user) {
            if (!this.readOnly) {
                const open = () => {
                    this.displayedEmployee = undefined;
                    this.selectedElement = nativeEvent.target;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.displayMenu()));
                    this.getEmployeeByGid(user.gid_collaborateur);
                };

                if (this.isMenuOpened) {
                    this.hideMenu();
                    requestAnimationFrame(() => requestAnimationFrame(() => open()));
                } else {
                    open();
                }

                nativeEvent.stopPropagation();
            }
        },

        async getEmployeeByGid(gid) {
            try {
                this.showSpinner();
                this.displayedEmployee = await employeeService.getEmployeeByGid(gid);
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
            this.hideSpinner();
        },
        formatDate(date) {
            return moment(date, ISO_DATE_FORMAT).locale('fr').format('DD MMMM YYYY');
        },
        getWeekNumber(date) {
            return moment(date, ISO_DATE_FORMAT).locale('fr').format('WW');
        },
        displayMenu() {
            this.isMenuOpened = true;
        },
        hideMenu() {
            this.isMenuOpened = false;
        },
        showSpinner() {
            this.isSpinnerDiplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDiplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/userAvailability';

.calendar-side-infos {
    font-size: 14px;
    white-space: nowrap;

    .header {
        height: 43px;
        background-color: $white;
        border-left: $border-color 1px solid;
        border-bottom: $border-color 1px solid;
        border-top: $border-color 1px solid;
        color: $black;

        .row {
            height: 100%;
        }
    }
    .subtitle {
        font-size: 12px;
    }
    .cell-readOnly {
        height: 30px;
        background-color: $very-light-blue;
        color: $black;
    }
    .cell:hover {
        cursor: pointer;
        color: $white;
        background-color: $primary;
    }
    .school-holiday-cell {
        font-weight: bold;
        height: 30px;
        background-color: $very-light-blue;
        color: $black;
    }
}
.spinner {
    position: relative;
    width: 100%;
    height: 100px;
}
</style>
