<template>
    <div>
        <span class="bold">{{ title }}</span>
        <v-row no-gutters>
            <v-col :cols="cols" v-for="(item, i) in items" :key="i">
                <v-list-item :title="item.title">
                    <v-list-item-avatar :size="avatarSize" :class="avatarClass">
                        <v-icon :class="item.class" />
                    </v-list-item-avatar>
                    <v-list-item-title>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'LegendSection',

    props: {
        title: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
        avatarClass: {
            type: String,
            required: false,
            default: '',
        },
        numberPerRow: {
            type: Number,
            required: false,
            default: 4,
        },
        avatarSize: {
            type: Number,
            required: false,
            default: 25,
        },
    },

    computed: {
        cols() {
            return 12 / this.numberPerRow;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/userAvailability';
@import '@/styles/unavailabilitiesColors';

div {
    font-size: 12px;
}

.constraint-avatar {
    border-radius: 0px;
}
.information-avatar {
    border-radius: 4px;
}
.status-avatar {
    border-radius: 2px;
}
.reason-avatar {
    border-radius: 8px;
}
</style>
