<template>
    <div>
        <div
            v-for="(event, i) in events"
            :key="i"
            class="unav-event"
            :class="event.element.attributes.classes"
            :style="{
                left: `${event.position}%`,
                width: `${event.duration}%`,
            }"
            @click.stop="showEvent($event, event, false)"
            @mouseover="showEvent($event, event, true)"
        >
            {{ event.element.attributes.title }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnavEvents',

    props: {
        events: {
            type: Array,
            required: true,
        },
    },

    methods: {
        showEvent(nativeEvent, event, isTooltip) {
            this.$emit('show-unav-event', {
                nativeEvent,
                unav: event.element,
                isTooltip,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/unavailabilitiesColors';

.unav-event {
    height: 23px;
    margin: 3px 0px 0px 0px;

    position: absolute;
    cursor: pointer;
    opacity: 0.6;
    z-index: 4;
    overflow: hidden;
    border-radius: 10px;
    background-color: #9e9e9e;

    color: white;
    font-size: 12px;
    line-height: 23px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;

    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transition: -webkit-transform 0.2s;
    transition: -webkit-transform 0.2s;
    transition: transform 0.2s;
    transition: transform 0.2s, -webkit-transform 0.2s;
}

.unav-event:hover,
.unav-event:focus,
.unav-event:active {
    opacity: 1 !important;
    transform: scaleY(1.1) !important;
    cursor: pointer !important;
}

.from_past {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.to_future {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.is-overlapping {
    height: 12px;
    line-height: 12px;
    margin: 2px 0px 0px 0px;
    position: relative;
}
</style>
