<template>
    <div>
        <v-tabs :value="actualTab" active-class="active-tab" hide-slider>
            <v-tab @click="selectWeekly()">Hebdomadaire</v-tab>
            <v-tab @click="selectDaily()" class="text-no-wrap">Quotidienne</v-tab>
        </v-tabs>

        <v-tabs-items v-model="actualTab">
            <v-tab-item class="ma-2">
                <v-row no-gutters align="center" justify="start">
                    Toutes les
                    <div class="mx-2 number-input">
                        <v-text-field
                            type="number"
                            :min="1"
                            :max="NumberOfWeeks"
                            :value="numberWeeks"
                            @input="setNumberWeeks"
                        />
                    </div>
                    semaine(s)

                    <v-btn-toggle
                        color="primary"
                        class="ml-2"
                        mandatory
                        tile
                        multiple
                        :value="periodicityDays"
                        @change="setPeriocityDays"
                    >
                        <v-btn :ripple="false">L</v-btn>
                        <v-btn :ripple="false">M</v-btn>
                        <v-btn :ripple="false">M</v-btn>
                        <v-btn :ripple="false">J</v-btn>
                        <v-btn :ripple="false">V</v-btn>
                        <v-btn :ripple="false">S</v-btn>
                        <v-btn :ripple="false">D</v-btn>
                    </v-btn-toggle>
                </v-row>
            </v-tab-item>

            <v-tab-item>
                <v-row no-gutters align="center" justify="start">
                    <v-radio-group
                        dense
                        hide-details
                        column
                        :value="isWorkingDay"
                        @change="setIsWorkingDay"
                    >
                        <v-radio :value="false">
                            <template v-slot:label>
                                <v-row no-gutters align="center">
                                    Tous les
                                    <div class="mx-2 number-input">
                                        <v-text-field
                                            type="number"
                                            :min="1"
                                            :max="365"
                                            :value="numberDays"
                                            @input="setNumberDays"
                                        />
                                    </div>
                                    jour(s)
                                </v-row>
                            </template>
                        </v-radio>
                        <v-radio label="Tous les jours ouvrables" :value="true" />
                    </v-radio-group>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import moment from 'moment';
import { createNamespacedHelpers } from 'vuex';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';
import { PERIODICITY_TYPE_POSITION, PERIODICITY_TYPE } from '@/constants/addUnavailability';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

export default {
    name: 'Periodicity',

    computed: {
        ...addUnavStore.mapState({
            selectedStartDate: (state) => state.unavPeriod.startDate,
            periodicityType: (state) => state.unavPeriod.periodicity.type,
            isWorkingDay: (state) => state.unavPeriod.periodicity.isWorkingDay,
            numberDays: (state) => state.unavPeriod.periodicity.numberDays,
            numberWeeks: (state) => state.unavPeriod.periodicity.numberWeeks,
            periodicityDays: (state) => state.unavPeriod.periodicity.periodicityDays,
        }),

        actualTab() {
            return this.periodicityType ? PERIODICITY_TYPE_POSITION[this.periodicityType] : 0;
        },
        NumberOfWeeks() {
            return moment(this.selectedStartDate, ISO_DATE_FORMAT).isoWeeksInYear();
        },
    },

    created() {
        this.showTab(PERIODICITY_TYPE.WEEKLY);
    },

    methods: {
        ...addUnavStore.mapMutations([
            'initializePeriodicity',
            'setPeriodicityType',
            'setIsWorkingDay',
            'setNumberDays',
            'setNumberWeeks',
            'setPeriocityDays',
        ]),

        selectDaily() {
            this.initializePeriodicity();
            this.showTab(PERIODICITY_TYPE.DAILY);
        },
        selectWeekly() {
            this.initializePeriodicity();
            this.showTab(PERIODICITY_TYPE.WEEKLY);
        },

        showTab(periodicityType) {
            this.setPeriodicityType(periodicityType);
        },
    },
};
</script>

<style lang="scss" scoped>
.number-input {
    width: 80px;
}
</style>
