function fromGroupToTreeNode(group) {
    const { children, ...data } = group;
    const groupsTree = {
        ...data,
        id: data.groupid || data.gid_collaborateur,
        label: data.groupname || `${data.nom} ${data.prenom}`,
    };

    if (children) {
        groupsTree.children = children.map((childGroup) => fromGroupToTreeNode(childGroup));
        groupsTree.children.sort((a, b) => a.label.localeCompare(b.label));
    } else {
        groupsTree.prenom = data.prenom;
        groupsTree.nom = data.nom;
    }

    return groupsTree;
}

function fromTeamsToTreeNode(teams) {
    return {
        id: 0,
        groupid: 0,
        groupname: 'Mon équipe',
        label: 'Mon équipe',
        noneditable: true,
        children: teams.map((e) => ({
            label: `${e.nom} ${e.prenom}`,
            id: e.gid_collaborateur,
            ...e,
        })),
    };
}

function formatGroupPeople(people) {
    return people.reduce((result, person) => {
        result[person.gid_collaborateur] = person;
        return result;
    }, {});
}

function collectPeople(group, array) {
    if (!group.children) {
        array.push({
            gid_collaborateur: group.gid_collaborateur,
            code_entite: group.code_entite,
            nom: group.nom,
            prenom: group.prenom,
        });
    } else {
        group.children.forEach((item) => {
            collectPeople(item, array);
        });
    }
}

function addTeamsNodeToGroupTree(groupsTree, teams) {
    const teamsNode = fromTeamsToTreeNode(teams);
    groupsTree.unshift(teamsNode);
    return groupsTree;
}

function getGroupsTree(groups) {
    return groups.map((group) => fromGroupToTreeNode(group));
}

function getGroupsPeople(groups) {
    const people = [];
    groups.forEach((group) => {
        collectPeople(group, people);
    });
    return people
        .sort((a, b) => a.nom.localeCompare(b.nom))
        .filter(
            (item, pos, array) =>
                !pos || item.gid_collaborateur !== array[pos - 1].gid_collaborateur
        );
}

export default {
    fromTeamsToTreeNode,
    addTeamsNodeToGroupTree,
    getGroupsTree,
    getGroupsPeople,
    collectPeople,
    fromGroupToTreeNode,
    formatGroupPeople,
};
