const VALIDEE = 'VALIDEE';
const REFUSEE = 'REFUSEE';
const ANNULEE = 'ANNULEE';
const ATTENTEANNULATION = 'ATTENTEANNULATION';
const AVALIDER = 'AVALIDER';
const CREE = 'CREE';

export const STATUS = [VALIDEE, REFUSEE, ANNULEE, ATTENTEANNULATION, AVALIDER, CREE];
export const STATUT_INDISPO = {
    VALIDEE,
    REFUSEE,
    ANNULEE,
    ATTENTEANNULATION,
    AVALIDER,
    CREE,
};
export const STATUT_INDISPO_LABEL = {
    [VALIDEE]: 'Validée',
    [REFUSEE]: 'Refusée',
    [ANNULEE]: 'Annulée',
    [ATTENTEANNULATION]: "En attente d'annulation",
    [AVALIDER]: 'En attente de validation',
    [CREE]: 'Créée',
};
export const STATUT_INDISPO_TOOLTIP_LABEL = {
    [VALIDEE]: 'Demande validée',
    [REFUSEE]: 'Demande refusée',
    [ANNULEE]: 'Demande annulée',
    [ATTENTEANNULATION]: "Demande en attente d'annulation",
    [AVALIDER]: 'Demande en attente de validation',
    [CREE]: 'Demande créée',
};

const REFUS = 'REFUS';
const VALIDATION = 'VALIDATION';
const VALIDATION_RAPIDE = 'VALIDATION_RAPIDE';
const VALIDATION_ANNULATION = 'VALIDATION_ANNULATION';
const REFUS_ANNULATION = 'REFUS_ANNULATION';
const ANNULATION = 'ANNULATION';
const CREATION = 'CREATION';
const SOUMISSION = 'SOUMISSION';
const DEMANDE_ANNULATION = 'DEMANDE_ANNULATION';
const EDITION = 'EDITION';
const MASS_VALIDATION = 'MASS_VALIDATION';
const MASS_REFUS = 'MASS_REFUS';
const MASS_VALIDATION_ANNULATION = 'MASS_VALIDATION_ANNULATION';
const MASS_REFUS_ANNULATION = 'MASS_REFUS_ANNULATION';
const SHOW_HISTORIC = 'SHOW_HISTORIC';

export const ACTIONS = [
    REFUS,
    VALIDATION,
    VALIDATION_RAPIDE,
    VALIDATION_ANNULATION,
    REFUS_ANNULATION,
    ANNULATION,
    CREATION,
    SOUMISSION,
    DEMANDE_ANNULATION,
    EDITION,
    MASS_VALIDATION,
    MASS_REFUS,
    MASS_VALIDATION_ANNULATION,
    MASS_REFUS_ANNULATION,
    SHOW_HISTORIC,
];
export const ACTIONS_INDISPO = {
    REFUS,
    VALIDATION,
    VALIDATION_RAPIDE,
    VALIDATION_ANNULATION,
    REFUS_ANNULATION,
    ANNULATION,
    CREATION,
    SOUMISSION,
    DEMANDE_ANNULATION,
    EDITION,
    MASS_VALIDATION,
    MASS_REFUS,
    MASS_VALIDATION_ANNULATION,
    MASS_REFUS_ANNULATION,
    SHOW_HISTORIC,
};
export const ACTIONS_INDISPO_VERB = {
    [REFUS]: 'Refuser la demande',
    [VALIDATION]: 'Valider la demande',
    [VALIDATION_RAPIDE]: 'Valider automatiquement la demande',
    [VALIDATION_ANNULATION]: "Valider l'annulation",
    [REFUS_ANNULATION]: "Refuser l'annulation",
    [ANNULATION]: "Annuler l'indisponibilité",
    [CREATION]: "Créer l'indisponibilité",
    [SOUMISSION]: "Soumettre l'indisponibilité",
    [DEMANDE_ANNULATION]: "Demander l'annulation",
    [EDITION]: "Modifier l'indisponibilité",
    [MASS_VALIDATION]: 'Valider la sélection',
    [MASS_REFUS]: 'Refuser la sélection',
    [MASS_VALIDATION_ANNULATION]: 'Valider les annulations',
    [MASS_REFUS_ANNULATION]: 'Refuser les annulations',
    [SHOW_HISTORIC]: "Voir l'historique",
};
export const ACTIONS_INDISPO_LABEL = {
    [REFUS]: "Refus d'une demande",
    [VALIDATION]: "Validation d'une demande",
    [VALIDATION_RAPIDE]: 'Validation automatique de la demande',
    [VALIDATION_ANNULATION]: "Validation de l'annulation",
    [REFUS_ANNULATION]: "Refus de l'annulation",
    [ANNULATION]: "Annulation d'une demande",
    [CREATION]: "Création d'une demande",
    [SOUMISSION]: "Soumission d'une demande",
    [DEMANDE_ANNULATION]: "Demande d'annulation",
    [EDITION]: "Modification d'indisponibilité",
    [MASS_VALIDATION]: "Validation d'une sélection",
    [MASS_REFUS]: "Refus d'une sélection",
    [MASS_VALIDATION_ANNULATION]: 'Validation des annulations',
    [MASS_REFUS_ANNULATION]: 'Refus des annulations',
};
export const ACTIONS_INDISPO_ICONS = {
    [REFUS]: { icon: 'mdi-minus-circle-outline', color: 'error' },
    [VALIDATION]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [VALIDATION_RAPIDE]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [VALIDATION_ANNULATION]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [REFUS_ANNULATION]: { icon: 'mdi-minus-circle-outline', color: 'error' },
    [ANNULATION]: { icon: 'mdi-close-circle-outline', color: 'primary' },
    [CREATION]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [SOUMISSION]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [DEMANDE_ANNULATION]: { icon: 'mdi-close-circle-outline', color: 'primary' },
    [EDITION]: { icon: 'mdi-pencil-circle-outline', color: 'primary' },
    [MASS_VALIDATION]: { icon: 'mdi-check-circle-outline', color: 'primary' },
    [MASS_REFUS]: { icon: 'mdi-minus-circle-outline', color: 'error' },
    [MASS_VALIDATION_ANNULATION]: { icon: 'mdi-close-circle-outline', color: 'primary' },
    [MASS_REFUS_ANNULATION]: { icon: 'mdi-minus-circle-outline', color: 'primary' },
    [SHOW_HISTORIC]: { icon: 'mdi-history', color: 'primary' },
};

const CAN_CANCEL = 'canCancel';
const CAN_ACCEPT = 'canAccept';
const CAN_REFUSE = 'canRefuse';
const CAN_ASK_TO_CANCEL = 'canAskToCancel';
const CAN_EDIT = 'canEdit';

export const RIGHTS = [CAN_CANCEL, CAN_ACCEPT, CAN_REFUSE, CAN_ASK_TO_CANCEL, CAN_EDIT];
export const UNAV_RIGHTS = {
    CAN_CANCEL,
    CAN_ACCEPT,
    CAN_REFUSE,
    CAN_ASK_TO_CANCEL,
    CAN_EDIT,
};

export const FREE_PLACEMENT_VALUE = 'Libre';
export const LOCKED_PLACEMENT_VALUE = 'Fixe';

export default {
    STATUS,
    STATUT_INDISPO,
    STATUT_INDISPO_LABEL,
    STATUT_INDISPO_TOOLTIP_LABEL,

    ACTIONS,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_LABEL,
    ACTIONS_INDISPO_ICONS,

    RIGHTS,
    UNAV_RIGHTS,

    FREE_PLACEMENT_VALUE,
    LOCKED_PLACEMENT_VALUE,
};
