import { isNetworkError as isAxiosRetryNetworkError } from 'axios-retry';
import errorMessages from '@/constants/errorMessages';
import EdispoError from '@/services/errors/EdispoError.model';

function isConnected() {
    try {
        return navigator.onLine;
    } catch (e) {
        return true;
    }
}

function isErrorStatusCode(error, codeOrCodes) {
    if (!error || !error.response) {
        return false;
    }
    const codes = [].concat(codeOrCodes);
    const statusCode = error.response && error.response.status;
    return statusCode === undefined || statusCode === null ? false : codes.includes(statusCode);
}

function isNetworkError(error) {
    return (
        !isConnected() ||
        (error instanceof Error && error.message === 'Network Error') ||
        isAxiosRetryNetworkError(error)
    );
}

export function getApiError(error, specificMessage, callerFileName) {
    let title = errorMessages.UNKNOWN.TITLE;
    let message = specificMessage;
    let stack = `Unknown Api error: ${error}`;

    if (isNetworkError(error)) {
        title = errorMessages.NETWORK.TITLE;
        message = errorMessages.NETWORK.MESSAGE;
        stack = undefined;
    }
    if (isErrorStatusCode(error, 401)) {
        title = errorMessages.HTTP._401.TITLE;
        message = errorMessages.HTTP._401.MESSAGE;
        stack = undefined;
    }
    if (isErrorStatusCode(error, 403)) {
        title = errorMessages.HTTP._403;
        stack = undefined;
    }
    if (isErrorStatusCode(error, 404)) {
        title = errorMessages.HTTP._404;
        stack = undefined;
    }
    if (isErrorStatusCode(error, 409)) {
        title = errorMessages.HTTP._409;
        stack = undefined;
    }
    if (isErrorStatusCode(error, [500, 503, 504])) {
        title = errorMessages.HTTP._500;
        stack = `Server Api error: ${error}`;
    }
    return new EdispoError({
        title,
        message,
        stack,
        callerFileName,
    });
}

export default {
    getApiError,
    isNetworkError,
    isErrorStatusCode,
    isConnected,
};
