/* eslint-disable no-extend-native */
/* eslint-disable func-names */
String.prototype.toParamString = function (...params) {
    let parameterizedString = this;

    params.forEach((param) => {
        parameterizedString = parameterizedString.replace(/{.*?}/, param);
    });

    return parameterizedString;
};

export const GROUPS = {
    CREATE_GROUPS: {
        TITLE: 'Succès',
        MESSAGE: 'Groupe créé avec succès.',
    },
    UPDATE_GROUPS: {
        TITLE: 'Succès',
        MESSAGE: 'Groupe modifié avec succès.',
    },
    DELETE_GROUPS: {
        TITLE: 'Succès',
        MESSAGE: 'Groupe supprimé avec succès',
    },
};

export const DELEGATIONS = {
    CREATE_DELEGATIONS: {
        TITLE: 'Succès',
        MESSAGE: 'Délégation créée avec succès.',
    },
    UPDATE_DELEGATIONS: {
        TITLE: 'Succès',
        MESSAGE: 'Délégation modifiée avec succès.',
    },
    DELETE_DELEGATIONS: {
        TITLE: 'Succès',
        MESSAGE: 'Délégation supprimée avec succès',
    },
};

export const ADD_UNAVAILABILITY = {
    SINGLE_CREATE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilité créée avec succès',
    },
    SINGLE_SUBMISSION: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilité soumise avec succès',
    },
    SINGLE_VALIDATION_RAPIDE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilité soumise et validée avec succès',
    },

    MULTIPLE_CREATE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilités créées avec succès',
    },
    MULTIPLE_SUBMISSION: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilités soumises avec succès',
    },
    MULTIPLE_VALIDATION_RAPIDE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilités soumises et validées avec succès',
    },

    SINGLE_REPLACE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilité modifiée avec succès',
    },
    MULTIPLE_REPLACE: {
        TITLE: 'Succès',
        MESSAGE: 'Indisponibilités modifiées avec succès',
    },
};

export const UNAV_ACTIONS = {
    TITLE: 'Succès',
    MESSAGE: "L'action: '{action}' a bien été effectuée.",
};
export const MASS_UNAV_ACTIONS = {
    TITLE: 'Succès',
    MESSAGE: "L'action: '{action}'  a bien été effectuée.",
};

export default {
    GROUPS,
};
