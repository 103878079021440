<template>
    <v-row no-gutters align="center" justify="start">
        <v-col v-if="!unavPrevisions" cols="12">
            <v-alert dense border="left" type="error" outlined>
                Une erreur est survenue lors du test de validité des indisponibilités.
            </v-alert>
        </v-col>
        <v-col v-if="cancellationFailureReason" cols="12">
            <v-alert dense border="left" type="error" outlined>
                L'indisponibilité n'a pas pu être modifiée car :
                {{ FAILURE_REASONS_LABEL[cancellationFailureReason] }}.
            </v-alert>
        </v-col>
        <v-col v-if="hasInvalidUnavs" cols="12">
            <v-alert dense border="left" type="error" outlined>
                Une ou plusieurs demandes d'indisponibilités sont en conflit.
            </v-alert>
        </v-col>
    </v-row>
</template>

<script>
import { FAILURE_REASONS_LABEL } from '@/constants/addUnavailability';

export default {
    name: 'SummaryAlert',

    props: {
        unavPrevisions: {
            required: false,
            type: Object,
        },
    },

    data() {
        return {
            FAILURE_REASONS_LABEL,
        };
    },

    computed: {
        cancellationFailureReason() {
            return this.unavPrevisions && this.unavPrevisions.cancellation_failure_reason;
        },
        hasInvalidUnavs() {
            return (
                this.unavPrevisions &&
                this.unavPrevisions.invalid_unavailabilities &&
                this.unavPrevisions.invalid_unavailabilities.length
            );
        },
    },
};
</script>
