export default {
    computed: {
        // LABELS
        commentLib() {
            return this.isRepairManConstraintFamily ? 'Commentaire' : 'Consigne';
        },
        worksLabelLib() {
            return this.isWorksConstraintFamily ? 'Libellé travaux' : "Libellé d'intervention";
        },

        // RULES
        propertyNumberRules() {
            if (
                !this.isP7ConstraintReason &&
                !this.isRESPLAConstraintReason &&
                !this.isRepairManConstraintFamily
            ) {
                return [
                    (v) => !!v || "Vous devez renseigner un numéro d'installation",
                    (v) =>
                        (this.construction && v ? this.construction.property_id === v.id : true) ||
                        "Ce numéro d'installation est en contradiction avec le code chantier",
                ];
            }
            return [];
        },
        constructionSiteCodeRules() {
            if (this.isP7ConstraintReason) {
                return [
                    (v) => !!v || 'Vous devez renseigner le code chantier',
                    (v) =>
                        (this.property && v ? this.property.id === v.property_id : true) ||
                        `Ce code chantier est en contradiction avec le numéro d'installation ${this.property.id} ${v.property_id}`,
                ];
            }
            return [];
        },
        worksLabelRules() {
            if (!this.isRepairManConstraintFamily) {
                return [
                    (v) =>
                        !!v ||
                        `Vous devez renseigner un libellé ${
                            this.isWorksConstraintFamily ? 'travaux' : "d'intervention"
                        }`,
                ];
            }
            return [];
        },
        commentRules() {
            if (this.isRESPLAConstraintReason || this.selectedReason.indisponibilite === 'RDVACC') {
                return [(v) => !!v || 'Vous devez renseigner une consigne'];
            }
            return [];
        },

        // FAMILIES
        isWorksConstraintFamily() {
            return this.selectedReason
                ? this.selectedReason.code_abs_non_affectee === 'TRA'
                : false;
        },
        isCustomerAppointmentConstraintFamily() {
            return this.selectedReason
                ? this.selectedReason.code_abs_non_affectee === 'RDV'
                : false;
        },
        isSpecificWorkOrderConstraintFamily() {
            return this.selectedReason
                ? this.selectedReason.code_abs_non_affectee === 'AAB'
                : false;
        },
        isRepairManConstraintFamily() {
            return this.selectedReason
                ? this.selectedReason.code_abs_non_affectee === 'DEPANN'
                : false;
        },

        // TYPES
        isRESPLAConstraintReason() {
            return this.selectedReason ? this.selectedReason.indisponibilite === 'RESPLA' : false;
        },
        isP7ConstraintReason() {
            return this.selectedReason ? this.selectedReason.indisponibilite === 'P7' : false;
        },
    },
};
