<template>
    <v-menu
        offset-y
        bottom
        :nudge-left="nudgeLeft"
        min-width="auto"
        transition="scale-transition"
        ref="textDatePickerMenu"
        :close-on-content-click="false"
        :return-value.sync="selectedDate"
    >
        <template v-slot:activator="{ on }">
            <v-btn v-bind="$attrs" v-on="on" class="date-label">
                {{ formattedDate }}
                <v-icon dark>mdi-menu-down</v-icon>
            </v-btn>
        </template>

        <v-date-picker
            no-title
            locale="fr-FR"
            first-day-of-week="1"
            :max="maxVal"
            :min="minVal"
            v-model="selectedDate"
            @input="saveDate(selectedDate)"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';
import { DATE_FORMAT, ISO_DATE_FORMAT } from '@/constants/dateFormat';

export default {
    inheritAttrs: false,

    name: 'ButtonDatePicker',

    props: {
        value: {
            type: String,
            required: false,
            default: null,
        },
        max: {
            type: String,
            required: false,
            default: null,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        returnFormat: {
            type: String,
            required: false,
            default: DATE_FORMAT,
        },
        nudgeLeft: {
            type: Number,
            required: false,
            default: 40,
        },
    },

    data() {
        return {
            selectedDate: '',
            minVal: '',
            maxVal: '',

            isMenuOpened: false,
        };
    },

    created() {
        this.selectedDate = this.formatToIso(this.value);
        this.minVal = this.formatToIso(this.min);
        this.maxVal = this.formatToIso(this.max);
    },

    watch: {
        value(newVal) {
            this.selectedDate = this.formatToIso(newVal);
        },
    },

    computed: {
        returnedDate() {
            return this.formatToReturnFormat(this.selectedDate);
        },
        formattedDate() {
            return this.returnedDate
                ? moment(this.returnedDate, this.returnFormat).format(DATE_FORMAT)
                : null;
        },
    },

    methods: {
        formatToIso(date) {
            return date ? moment(date, this.returnFormat).format(ISO_DATE_FORMAT) : null;
        },
        formatToReturnFormat(date) {
            return date ? moment(date, ISO_DATE_FORMAT).format(this.returnFormat) : null;
        },
        saveDate(date) {
            this.selectedDate = date;
            this.$emit('input', this.returnedDate);
            this.$refs.textDatePickerMenu.save(date);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.date-label {
    color: $black;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 400;
}
</style>
