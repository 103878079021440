<template>
    <div>
        <Supervision v-if="isSupervisionDisplayed" v-model="isSupervisionDisplayed" />

        <MyProfile
            v-if="!isExternal && isProfileDisplayed"
            v-model="isProfileDisplayed"
            :roles="roles"
            :employee="employee"
        />

        <Delegation
            v-if="isDelegationDisplayed"
            v-model="isDelegationDisplayed"
            :employee="employee"
        />

        <v-app-bar :elevation="0" color="white" tabs app>
            <div @click="goToCalendar()" class="edispo-logo">
                <v-row>
                    <v-img :src="engieLogo" max-height="60" max-width="120" />
                    <v-img :src="eDispoLogo" max-height="60" max-width="120" />
                </v-row>
            </div>

            <v-spacer />

            <template v-if="isAppLoaded">
                <div v-if="isAdmin">
                    <v-btn icon class="primary" :ripple="false" @click="showSupervision()">
                        <v-icon medium>mdi-eye-outline</v-icon>
                    </v-btn>
                </div>

                <v-menu :close-on-content-click="false" offset-y bottom>
                    <template v-slot:activator="{ on }">
                        <div>
                            <v-row
                                no-gutters
                                align="center"
                                :class="{ 'employee-panel': !isExternal }"
                                v-on="on"
                            >
                                <v-icon class="mr-md-1" color="primary">mdi-account</v-icon>

                                <span
                                    v-if="!isSupervising && !(admin && admin.nom)"
                                    class="hidden-xs-only text-employee-panel"
                                >
                                    {{ employee.prenom | capitalize }} {{ employee.nom }}
                                </span>
                                <v-col v-else class="hidden-xs-only text-employee-panel">
                                    <v-row no-gutters align="center">
                                        <span>{{ admin.prenom | capitalize }} {{ admin.nom }}</span>
                                    </v-row>
                                    <v-row no-gutters align="center">
                                        <span class="connected-as">
                                            En tant que {{ employee.prenom | capitalize }}
                                            {{ employee.nom }}
                                        </span>
                                    </v-row>
                                </v-col>

                                <v-icon class="hidden-xs-only" v-if="!isExternal" color="primary">
                                    mdi-menu-down
                                </v-icon>
                            </v-row>
                        </div>
                    </template>

                    <ProfileCard
                        :isCacheAllowed="isCacheAllowed"
                        :employee="employee"
                        @show-user-profile="showUserProfile()"
                        @show-delegation="showDelegation()"
                        @update-cache-allowance="updateCacheAllowance"
                    />
                </v-menu>
            </template>

            <template v-if="isAppLoaded && !hasFatalError" slot="extension">
                <v-tabs
                    class="extension-header"
                    slider-color="primary"
                    show-arrows
                    align-with-title
                >
                    <v-tab
                        class="title"
                        :ripple="false"
                        :key="route.name"
                        :to="route.url"
                        v-for="route in authorizedPages"
                    >
                        <span>{{ route.title }}</span>
                        <NotificationBadge
                            v-if="route.name === routesStateConstant.MY_VALIDATIONS.name"
                            :label="validationsCount"
                        />
                        <NotificationBadge
                            v-else-if="route.name === routesStateConstant.MY_REQUESTS.name"
                            :label="myRequestsCount"
                        />
                    </v-tab>
                </v-tabs>
            </template>
        </v-app-bar>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import eDispoLogo from '@/assets/logo-edispo-black.svg';
import engieLogo from '@/assets/logo-engie.svg';
import validationsApi from '@/api/validations';
import myRequestsApi from '@/api/myRequests';
import employeeService from '@/services/employee';
import routesStateConstant from '@/constants/routes';
import ProfileCard from '@/components/employee/ProfileCard.vue';
import MyProfile from '@/components/employee/MyProfile.vue';
import Supervision from '@/components/Supervision.vue';
import Delegation from '@/components/delegation/Delegation.vue';
import NotificationBadge from '@/components/NotificationBadge.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
    mapGetters: employeeNameSpace.mapGetters,
};

const fileName = 'DefaultHeader.vue';

export default {
    name: 'DefaultHeader',

    components: {
        NotificationBadge,
        ProfileCard,
        MyProfile,
        Supervision,
        Delegation,
    },

    data() {
        return {
            isProfileDisplayed: false,
            isSupervisionDisplayed: false,
            isDelegationDisplayed: false,
            eDispoLogo,
            engieLogo,
            routesStateConstant,

            interval: 30000,

            validationTimer: null,
            validationsCount: undefined,

            myRequestsTimer: null,
            myRequestsCount: undefined,
        };
    },

    computed: {
        ...mapState(['isCacheAllowed', 'onDemandUnavLoad']),
        ...mapGetters(['hasFatalError', 'authorizedPages']),
        ...employeeStore.mapState(['employee', 'admin']),
        ...employeeStore.mapGetters(['isExternal', 'isAdmin', 'isSupervising', 'isN1', 'roles']),

        isAppLoaded() {
            return this.employee && this.employee.gid_collaborateur;
        },
    },

    watch: {
        isAppLoaded(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                employeeService.setLoggerUser(this.$auth);
                this.loadCacheAllowance();
                this.loadValidationsCount();
                this.loadMyRequestsCount();
            }
        },
        onDemandUnavLoad(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.loadValidationsCount();
                this.loadMyRequestsCount();
            }
        },
    },

    created() {
        this.loadValidationsCount();
        this.loadMyRequestsCount();
    },

    mounted() {
        this.validationTimer = setInterval(() => {
            this.loadValidationsCount();
        }, this.interval);

        this.myRequestsTimer = setInterval(() => {
            this.loadMyRequestsCount();
        }, this.interval);
    },

    beforeDestroy() {
        clearInterval(this.validationTimer);
        clearInterval(this.myRequestsTimer);
    },

    methods: {
        ...mapMutations(['showErrorToast', 'clearErrors']),
        ...mapActions(['loadCacheAllowance', 'updateCacheAllowance']),

        async loadValidationsCount() {
            try {
                if (this.isAppLoaded && this.isN1) {
                    this.validationsCount = await validationsApi.getToValidateCount();
                }
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },
        async loadMyRequestsCount() {
            try {
                if (this.isAppLoaded && !this.isExternal) {
                    this.myRequestsCount = await myRequestsApi.getMyUnavailabilitiesCount();
                }
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },
        goToCalendar() {
            if (this.$route.name === routesStateConstant.CALENDAR.name) {
                this.$router.go();
            } else {
                this.clearErrors();
                this.$router.push({
                    path: routesStateConstant.CALENDAR.url,
                });
            }
        },
        showUserProfile() {
            if (!this.isExternal) {
                this.isProfileDisplayed = true;
            }
        },
        showSupervision() {
            this.isSupervisionDisplayed = true;
        },
        showDelegation() {
            this.isDelegationDisplayed = true;
        },
    },
    filters: {
        capitalize(value) {
            if (!value) {
                return '';
            }

            const words = value.split(/\s/);

            return words
                .filter((word) => word.length > 0)
                .map((word) =>
                    word
                        .split('-')
                        .map((part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
                        .join('-')
                )
                .join(' ');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.edispo-logo {
    cursor: pointer;
    margin-left: 28px;
}
.employee-panel:hover {
    cursor: pointer;
    padding: 10px 0 10px 0;
    background-color: rgba(250, 250, 250, 0.12);
}
.hidden-xs-only {
    color: $primary;
    margin: 5px;
}
.extension-header {
    border-bottom: solid 1px $neutral-gray;

    .title {
        font-size: 1rem !important;
    }
    .title:hover {
        border-bottom: solid 2px $primary;
    }
}
.text-employee-panel {
    user-select: none;
    font-weight: bold;
    font-size: 1rem;
}
.connected-as {
    color: $light-orange;
    font-size: 12px;
    font-weight: bold;
}
::v-deep .v-toolbar {
    height: auto !important;
}
::v-deep .v-toolbar__content {
    border-bottom: solid 1px $neutral-gray;
}
::v-deep .v-tabs-bar {
    margin-left: 36px;
}
::v-deep .v-image__image--cover {
    background-size: auto;
}
::v-deep span .v-badge__badge {
    color: $black;
}
::v-deep .v-toolbar__extension i {
    color: $black;
}
::v-deep .v-tab {
    color: $black !important;
    margin-top: 16px;
    margin-left: 16px;
}
</style>
