<template>
    <v-dialog @input="updateValue" :value="value" max-width="720">
        <Spinner v-if="isLoaderDisplayed" />

        <v-card tile v-if="unavailability && applicant" class="action-card">
            <v-card-title>
                <span v-if="isCancelPending && unavRights && unavRights.length">
                    Actions sur la demande d'annulation
                </span>
                <span v-else-if="isToValidate && unavRights && unavRights.length">
                    Actions sur la demande d'indisponibilité
                </span>
                <span v-else-if="unavRights && unavRights.length">
                    Actions sur l'indisponibilité
                </span>
                <span v-else>Détails de l'indisponibilité</span>

                <v-spacer />

                <v-btn icon dark @click="updateValue(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-container fluid>
                <UnavSummarySection :unavailability="unavailability" />

                <v-form v-model="isValid" v-if="unavRights && unavRights.length">
                    <v-text-field
                        outlined
                        label="Commentaire (facultatif)"
                        v-model="enteredComment"
                        :counter="255"
                        :rules="commentRules"
                        required
                    />
                </v-form>
            </v-container>

            <v-card-actions v-if="unavRights && unavRights.length">
                <v-row v-if="isCancelPending" no-gutters align="center" justify="end">
                    <v-col
                        cols="12"
                        md="3"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_ACCEPT)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.VALIDATION_ANNULATION.color"
                            @click="performAction(ACTIONS_INDISPO.VALIDATION_ANNULATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.VALIDATION_ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.VALIDATION_ANNULATION }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        md="3"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_REFUSE)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.REFUS_ANNULATION.color"
                            @click="performAction(ACTIONS_INDISPO.REFUS_ANNULATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.REFUS_ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.REFUS_ANNULATION }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-else-if="isToValidate" no-gutters align="center" justify="end">
                    <v-col
                        cols="12"
                        :md="mdColumnNumber"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_EDIT)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.EDITION.color"
                            @click="editUnavailabity()"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.EDITION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.EDITION }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        :md="mdColumnNumber"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_ACCEPT)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.VALIDATION.color"
                            @click="performAction(ACTIONS_INDISPO.VALIDATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.VALIDATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.VALIDATION }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        :md="mdColumnNumber"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_REFUSE)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.REFUS.color"
                            @click="performAction(ACTIONS_INDISPO.REFUS)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.REFUS.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.REFUS }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        :md="mdColumnNumber"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_CANCEL)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.ANNULATION.color"
                            @click="performAction(ACTIONS_INDISPO.ANNULATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.ANNULATION }}
                        </v-btn>
                    </v-col>
                </v-row>

                <v-row v-else no-gutters align="center" justify="end">
                    <v-col
                        cols="12"
                        md="4"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_EDIT)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.EDITION.color"
                            @click="editUnavailabity()"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.EDITION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.EDITION }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_CANCEL)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            class="primary"
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            @click="performAction(ACTIONS_INDISPO.ANNULATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.ANNULATION }}
                        </v-btn>
                    </v-col>

                    <v-col
                        cols="12"
                        md="4"
                        v-if="hasTheRight(UNAV_RIGHTS.CAN_ASK_TO_CANCEL)"
                        class="mx-1 my-1"
                    >
                        <v-btn
                            tile
                            block
                            depressed
                            large
                            :ripple="false"
                            :class="ACTIONS_INDISPO_ICONS.DEMANDE_ANNULATION.color"
                            @click="performAction(ACTIONS_INDISPO.DEMANDE_ANNULATION)"
                        >
                            <v-icon left>
                                {{ ACTIONS_INDISPO_ICONS.DEMANDE_ANNULATION.icon }}
                            </v-icon>
                            {{ ACTIONS_INDISPO_VERB.DEMANDE_ANNULATION }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex';
import unavailabilitiesApi from '@/api/unavailabilities';

import employeesService from '@/services/employee';
import unavailabilitiesService from '@/services/unavailabilities';

import {
    STATUT_INDISPO,
    UNAV_RIGHTS,
    ACTIONS_INDISPO,
    ACTIONS_INDISPO_VERB,
    ACTIONS_INDISPO_ICONS,
    ACTIONS_INDISPO_LABEL,
} from '@/constants/unavailabilities';
import { UNAV_ACTIONS } from '@/constants/succesMessages';

import Spinner from '@/components/Spinner.vue';
import UnavSummarySection from '@/components/sections/unavs/UnavSummarySection.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
};

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapMutations: addUnavNameSpace.mapMutations,
};

const fileName = 'UnavActionsModal.vue';

export default {
    name: 'UnavActionsModal',

    components: {
        Spinner,
        UnavSummarySection,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        selectedUnav: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isLoaderDisplayed: false,

            STATUT_INDISPO,
            ACTIONS_INDISPO,
            UNAV_RIGHTS,
            ACTIONS_INDISPO_VERB,
            ACTIONS_INDISPO_ICONS,

            enteredComment: '',
            isValid: true,
            commentRules: [(v) => v.length <= 255 || 'La taille maximum est de 255!'],

            unavailability: undefined,
            applicant: undefined,
        };
    },

    computed: {
        ...employeeStore.mapState(['employee']),

        isCancelPending() {
            return (
                this.unavailability &&
                this.unavailability.statut === STATUT_INDISPO.ATTENTEANNULATION
            );
        },
        isToValidate() {
            return this.unavailability && this.unavailability.statut === STATUT_INDISPO.AVALIDER;
        },

        unavRights() {
            return unavailabilitiesService.getUnavRights(
                this.employee,
                this.applicant,
                this.unavailability
            );
        },

        mdColumnNumber() {
            return this.unavRights.length >= 4 ? 12 : 4;
        },
    },

    async created() {
        this.showSpinner();
        await this.loadUnavailabity();
        await this.loadApplicant();
        this.hideSpinner();
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),
        ...addUnavStore.mapMutations(['displayEditUnavailability']),

        async performAction(action) {
            try {
                this.showSpinner();
                await unavailabilitiesService.performUnavAction(
                    this.unavailability.id,
                    action,
                    this.enteredComment
                );
                this.showToast({
                    title: UNAV_ACTIONS.TITLE,
                    message: UNAV_ACTIONS.MESSAGE.toParamString(ACTIONS_INDISPO_LABEL[action]),
                    dismiss: false,
                });
                this.hideSpinner();
                this.$emit('unavs-update');
                this.updateValue(false);
            } catch (error) {
                this.hideSpinner();
                this.showErrorToast({ error, fileName });
                this.updateValue(false);
            }
        },

        editUnavailabity() {
            this.updateValue(false);
            this.displayEditUnavailability(this.unavailability);
        },

        async loadUnavailabity() {
            try {
                const unavailability = await unavailabilitiesApi.getUnavailability(
                    this.selectedUnav.id
                );
                this.unavailability = unavailability;
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideSpinner();
            }
        },
        async loadApplicant() {
            try {
                const applicant = await employeesService.getEmployeeByGid(
                    this.selectedUnav.gid_collaborateur
                );
                this.applicant = applicant;
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideSpinner();
            }
        },
        hasTheRight(right) {
            return this.unavRights.includes(right);
        },

        updateValue(val) {
            this.$emit('input', !!val);
        },
        showSpinner() {
            this.isLoaderDisplayed = true;
        },
        hideSpinner() {
            this.isLoaderDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-btn {
    white-space: normal;
}

.action-card {
    max-width: 720px;
}

.spinner {
    position: relative;
    width: 100%;
    height: 100px;
}
</style>
