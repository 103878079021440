<template>
    <div>
        <div
            v-for="(event, i) in events"
            :key="i"
            class="duty-call-event duty-call"
            :style="{
                left: `${event.position}%`,
                width: `${event.duration}%`,
            }"
        />
    </div>
</template>

<script>
export default {
    name: 'DutyCallEvents',

    props: {
        events: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/unavailabilitiesColors';

.duty-call-event {
    height: 30px;
    position: absolute;
    border-radius: 0px;
    z-index: 2;
}
</style>
