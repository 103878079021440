import moment from 'moment';
import EdispoError from '@/services/errors/EdispoError.model';
import errorMessages from '@/constants/errorMessages';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';

export function formatSearchWords(search) {
    return search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // les caractères avec accents "éàçèñ"
        .toUpperCase()
        .split(' ');
}

export function treeFilterNodeMethod(search, node, keyParam) {
    if (!search) {
        return true;
    }
    const nodeLabel = node[keyParam].toUpperCase();
    const formattedSearchWords = formatSearchWords(search);
    return formattedSearchWords.every((word) => nodeLabel.includes(word));
}

export function removeDuplicatesByProp(myArray, prop) {
    return myArray.filter(
        (object, index, array) => array.map((elem) => elem[prop]).indexOf(object[prop]) === index
    );
}

export function removeDuplicates(myArr) {
    return [...new Set(myArr)];
}

export function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

export function groupBy(xs, key) {
    return xs.reduce((result, actualElement) => {
        (result[actualElement[key]] = result[actualElement[key]] || []).push(actualElement);
        return result;
    }, {});
}

function sortPeople(people) {
    const sortedPeople = people.slice();
    sortedPeople.sort((a, b) =>
        `${a.code_entite} + ${a.nom} + ${a.prenom}`.localeCompare(
            `${b.code_entite} + ${b.nom} + ${b.prenom}`
        )
    );
    return sortedPeople;
}

function orderPeople(people, userGid) {
    const repositionedPeople = people.slice();
    const userIndex = repositionedPeople.findIndex((user) => user.gid_collaborateur === userGid);

    if (userIndex >= 0) {
        const userData = repositionedPeople.splice(userIndex, 1);
        repositionedPeople.unshift(...userData);
    }

    return repositionedPeople;
}

export function sortAndOrderPeople(people, userGid) {
    const peopleSet = removeDuplicatesByProp(people, 'gid_collaborateur');
    const sortedPeople = sortPeople(peopleSet);
    return orderPeople(sortedPeople, userGid);
}

function mapToUser(people) {
    return {
        gid_collaborateur: people.gid_collaborateur,
        code_entite: people.code_entite,
        nom: people.nom || people.name,
        prenom: people.prenom || people.forename,
    };
}

export function formatToIsoString(date, format = ISO_DATE_FORMAT) {
    return date ? moment(date, format).toISOString() : null;
}

export function formatToIsoStringEndOfDay(date, format = ISO_DATE_FORMAT) {
    return date ? moment(date, format).endOf('d').toISOString() : null;
}

export function formatToIsoStringStartOfDay(date, format = ISO_DATE_FORMAT) {
    return date ? moment(date, format).startOf('d').toISOString() : null;
}

export function isAuthorizedPage(availablePages, page) {
    const isAuthorized = availablePages.map((availablePage) => availablePage.name).includes(page);
    if (!isAuthorized) {
        throw new EdispoError({
            title: errorMessages.HTTP._403,
            message: errorMessages.HTTP._401.MESSAGE,
        });
    }
    return isAuthorized;
}

export default {
    mapToUser,
    sortAndOrderPeople,
    formatSearchWords,
    treeFilterNodeMethod,
    formatToIsoString,
    formatToIsoStringEndOfDay,
    removeDuplicatesByProp,
    removeDuplicates,
};
