import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'unavailabilityReasons.api.js';

async function getUnavailabilityReasons() {
    try {
        const response = await apiClient.get('unavailability_reasons');
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.UNAVAILABILITY_REASONS, fileName));
    }
}

async function getCommonUnavailabilityReasons(rawEmployeesGids) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const response = await apiClient.post('unavailability_reasons/employees/search', {
            employees: formattedUsersGids,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(
            getApiError(error, errorMessages.COMMON_UNAVAILABILITY_REASONS, fileName)
        );
    }
}

export default {
    getUnavailabilityReasons,
    getCommonUnavailabilityReasons,
};
