<template>
    <v-menu
        offset-y
        bottom
        rounded
        transition="slide-y-transition"
        v-model="isTreeDisplayed"
        max-height="60%"
        :close-on-content-click="false"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                class="filter-field"
                append-icon="mdi-magnify"
                clearable
                hide-details
                single-line
                :label="textLabel"
                v-model="enteredSearch"
                v-on="on"
            />
        </template>

        <el-tree
            v-if="isTreeDisplayed"
            node-key="gid_collaborateur"
            :data="treeData"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-checked-keys="selectedKeys"
            :default-expanded-keys="defaultExpandedKeys"
            accordion
            show-checkbox
            ref="textTreePickerRef"
            @check="sendSelectedLeafs"
        >
            <span class="custom-tree-node" slot-scope="{ node }">
                <span>{{ node.label }}</span>
            </span>
        </el-tree>
    </v-menu>
</template>

<script>
import { treeFilterNodeMethod } from '@/services/shared';

export default {
    name: 'TextTreePicker',

    props: {
        textLabel: {
            type: String,
            required: true,
        },
        treeData: {
            type: Array,
            required: true,
        },
        selectedKeys: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            defaultProps: {
                children: 'children',
                label: 'label',
                disabled: 'disabled',
            },
            isTreeDisplayed: false,
            enteredSearch: '',

            timeout: null,
            debounceValue: 500,
        };
    },

    computed: {
        defaultExpandedKeys() {
            return this.treeData && this.treeData.length > 0 ? [this.treeData[0].id] : [];
        },
    },

    watch: {
        enteredSearch(newVal) {
            this.isTreeDisplayed = true;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.$refs.textTreePickerRef.filter(newVal);
            }, this.debounceValue);
        },
        isTreeDisplayed(val) {
            if (val && this.enteredSearch) {
                this.$nextTick(() => {
                    this.$refs.textTreePickerRef.filter(this.enteredSearch);
                });
            }
        },
    },

    methods: {
        filterNode(search, node) {
            return treeFilterNodeMethod(search, node, this.defaultProps.label);
        },

        sendSelectedLeafs() {
            const selectedLeafs = this.$refs.textTreePickerRef
                .getCheckedNodes()
                .filter((node) => !node.children);
            this.$emit('update-selected', selectedLeafs);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.filter-field {
    padding: 0;
    margin-top: 0;
}

::v-deep .v-input__slot {
    border: solid 1px $gray;
}
</style>
