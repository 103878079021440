import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'teams.api.js';

async function getTeams() {
    try {
        const response = await apiClient.get('employees/teams');
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.TEAMS, fileName));
    }
}

export default {
    getTeams,
};
