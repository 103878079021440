import localCacheService from '@/services/localCache';
import { isAuthorizedPage } from '@/services/shared';

const fileName = 'rootActions.store';

export default {
    async loadCacheAllowance({ commit, state }) {
        try {
            const isCacheAllowed = !!localCacheService.getUserCacheAllowance(
                state.employee.employee.gid_collaborateur
            );
            commit('setIsCacheAllowed', isCacheAllowed);
        } catch (error) {
            commit('showErrorToast', { error, fileName }, { root: true });
        }
    },
    async updateCacheAllowance({ commit, state }, newVal) {
        try {
            localCacheService.setUserCacheAllowance(
                state.employee.employee.gid_collaborateur,
                newVal
            );
            commit('setIsCacheAllowed', newVal);
        } catch (error) {
            commit('showErrorToast', { error, fileName }, { root: true });
        }
    },

    async clearWholeStore({ commit }) {
        commit('clearErrors', { root: true });
        commit('perimeter/clearPerimeter', { root: true });
        commit('groups/clearGroups', { root: true });
        commit('unavailabilityReasons/clearReasons', { root: true });
    },

    async checkPageAuthorization({ commit, getters }, page) {
        try {
            isAuthorizedPage(getters.authorizedPages, page);
        } catch (error) {
            commit('addFatalError', { error, fileName }, { root: true });
        }
    },
};
