<template>
    <div>
        <p>
            La demande concerne
            <span class="bold" v-for="(recipient, index) in recipients" :key="index">
                {{ recipient.nom }} {{ recipient.prenom
                }}{{ index !== recipients.length - 1 ? ',' : '' }}
            </span>
        </p>

        <p>
            Motif :
            <span v-if="reason && reason.indisponibilite" class="bold">
                {{ isConstraint ? '' : `${reason.indisponibilite} - ` }}
                {{ reason.lib_absence }}
            </span>
            <span v-else class="insufficient-rights">
                Vous n'avez pas les droits suffisants pour visualiser le motif d'indisponibilité.
            </span>
        </p>

        <ConstraintDetailsSection v-if="isConstraint" :reason="reason" :constraint="constraint" />

        <p v-if="!!unavPeriod.periodicity">
            Tous les
            <span v-if="unavPeriod.periodicity.type === PERIODICITY_TYPE.DAILY" class="bold">
                {{
                    unavPeriod.periodicity.isWorkingDay
                        ? 'jours ouvrables'
                        : `${
                              unavPeriod.periodicity.numberDays > 1
                                  ? unavPeriod.periodicity.numberDays
                                  : ''
                          } jours`
                }}
            </span>
            <span v-else>
                <span
                    v-for="(day, index) in unavPeriod.periodicity.periodicityDays"
                    :key="index"
                    class="bold"
                >
                    {{ fromPeriodicDayToLabel(day)
                    }}{{ index !== unavPeriod.periodicity.periodicityDays.length - 1 ? ',' : '' }}
                </span>
                chaque
                <span class="bold">
                    {{
                        unavPeriod.periodicity.numberWeeks > 1
                            ? `${unavPeriod.periodicity.numberWeeks} semaines`
                            : 'semaine'
                    }}
                </span>
            </span>
            de
            <span class="bold">{{ unavPeriod.startTime }}</span>
            à
            <span class="bold">{{ unavPeriod.endTime }}</span>
            du
            <span class="bold">{{ unavPeriod.startDate | formatDate }}</span>
            au
            <span class="bold">{{ unavPeriod.endDate | formatDate }}</span>
        </p>
        <p v-else>
            Du
            <span class="bold">
                {{ unavPeriod.startDate | formatDate }} à {{ unavPeriod.startTime }}
            </span>
            au
            <span class="bold">
                {{ unavPeriod.endDate | formatDate }} à {{ unavPeriod.endTime }}
            </span>
        </p>
    </div>
</template>

<script>
import moment from 'moment';
import { ISO_DATE_FORMAT, DATE_FORMAT } from '@/constants/dateFormat';
import { OK } from '@/constants/shared';
import { PERIODICITY_TYPE } from '@/constants/addUnavailability';
import ConstraintDetailsSection from '@/components/sections/unavs/ConstraintDetailsSection.vue';

export default {
    name: 'RecapSection',

    props: {
        unavPeriod: {
            type: Object,
            required: true,
        },
        recipients: {
            type: Array,
            required: true,
        },
        reason: {
            type: Object,
            required: true,
        },
        constraint: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    components: {
        ConstraintDetailsSection,
    },

    data() {
        return {
            OK,
            PERIODICITY_TYPE,
        };
    },

    computed: {
        isConstraint() {
            return this.reason.is_constraint === OK;
        },
    },

    filters: {
        formatDate(date) {
            return date ? moment(date, ISO_DATE_FORMAT).format(DATE_FORMAT) : '';
        },
    },

    methods: {
        fromPeriodicDayToLabel(periodicDay) {
            switch (periodicDay) {
                case 0:
                    return 'Lundi';
                case 1:
                    return 'Mardi';
                case 2:
                    return 'Mercredi';
                case 3:
                    return 'Jeudi';
                case 4:
                    return 'Vendredi';
                case 5:
                    return 'Samedi';
                case 6:
                    return 'Dimanche';
                default:
                    return '';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

.insufficient-rights {
    color: $dark-gray;
    font-style: italic;
}
</style>
