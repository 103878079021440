<template>
    <div>
        <template v-if="!isRepairManConstraintFamily">
            <p>
                Numéro d'installation :
                <span class="bold">{{ constraintInfos.property_number || undefinedLabel }}</span>
            </p>

            <p v-if="isWorksConstraintFamily">
                Code chantier :
                <span class="bold">
                    {{ constraintInfos.construction_site_code || undefinedLabel }}
                </span>
            </p>

            <p v-if="!isRESPLAConstraintReason && !isCustomerAppointmentConstraintFamily">
                Placement :
                <span class="bold">{{ constraintInfos.placement || undefinedLabel }}</span>
            </p>

            <p v-if="!isRESPLAConstraintReason">
                {{ worksLabelLib }} :
                <span class="bold">{{ constraintInfos.works_label || undefinedLabel }}</span>
            </p>
        </template>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ConstraintDetailsMixin from '@/services/mixins/ConstraintDetailsMixin';

const unavailabilityReasonsNameSpace = createNamespacedHelpers('unavailabilityReasons');
const unavailabilityReasonsStore = {
    mapGetters: unavailabilityReasonsNameSpace.mapGetters,
    mapActions: unavailabilityReasonsNameSpace.mapActions,
};

export default {
    name: 'ConstraintDetailsSection',

    props: {
        unavailability: {
            type: Object,
            required: false,
            default: undefined,
        },
        reason: {
            type: Object,
            required: false,
            default: undefined,
        },
        constraint: {
            type: Object,
            required: false,
            default: undefined,
        },
    },

    mixins: [ConstraintDetailsMixin],

    data() {
        return {
            selectedReason: {},
        };
    },

    computed: {
        ...unavailabilityReasonsStore.mapGetters(['unavailabilityReasonsObject']),

        undefinedLabel() {
            return 'Non Renseigné';
        },

        constraintInfos() {
            if (this.unavailability && this.unavailability.constraint_informations) {
                return this.unavailability.constraint_informations;
            }
            return this.constraint
                ? {
                      property_number: this.constraint.propertyNumber,
                      construction_site_code: this.constraint.constructionSiteCode,
                      placement: this.constraint.placementType,
                      works_label: this.constraint.worksLabel,
                  }
                : {};
        },
    },

    async created() {
        if (this.reason) {
            this.selectedReason = this.reason;
        } else {
            await this.loadUnavailabilityReasons();
            this.selectedReason =
                this.unavailabilityReasonsObject[this.unavailability.indisponibilite];
        }
    },

    methods: {
        ...unavailabilityReasonsStore.mapActions(['loadUnavailabilityReasons']),
    },
};
</script>
