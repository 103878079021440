<template>
    <div>
        <div v-for="(event, index) in events" :key="index">
            <v-tooltip bottom v-if="isDisplayed(event.element.zones)">
                <template v-slot:activator="{ on }">
                    <div
                        class="school-holiday-event"
                        :class="event.element.attributes.classes"
                        :style="{
                            left: `${event.position}%`,
                            width: `${event.duration}%`,
                        }"
                        v-on="on"
                    />
                </template>
                <span>{{ event.element.attributes.tooltip }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
import { ZONEA, ZONEB, ZONEC } from '@/constants/holidays';

export default {
    name: 'SchoolHolidayEvents',

    props: {
        events: {
            type: Array,
            required: true,
        },
        displayedZones: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            isTooltipVisible: false,

            ZONEA,
            ZONEB,
            ZONEC,
        };
    },

    computed: {
        isZoneA() {
            return this.displayedZones.includes(ZONEA);
        },
        isZoneB() {
            return this.displayedZones.includes(ZONEB);
        },
        isZoneC() {
            return this.displayedZones.includes(ZONEC);
        },
    },

    methods: {
        isDisplayed(zone) {
            return this.displayedZones.includes(zone);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/unavailabilitiesColors';

.school-holiday-event {
    height: 6px;
    margin: 3px 0px 0px 0px;

    position: absolute;
    opacity: 0.6;
    z-index: 1;
    overflow: hidden;
    background-color: #9e9e9e;
}

.zone-b {
    margin-top: 12px;
}
.zone-c {
    margin-top: 21px;
}
</style>
