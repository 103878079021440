<template>
    <div>
        <p class="bold">Choisissez le bénéficiaire de l'indisponibilité</p>

        <v-row no-gutters align="center" justify="start">
            <v-col cols="12" lg="8" v-if="ineligibleGroups && ineligibleGroups.length">
                <v-alert tile outlined dense border="left" color="warning">
                    <v-icon color="warning">mdi-alert</v-icon>
                    Vous n'avez pas les droits de déclaration pour une ou plusieurs personnes dans
                    les groupes suivants :
                    <li v-for="(group, i) in ineligibleGroups" :key="i">{{ group }}</li>
                </v-alert>
            </v-col>

            <v-col cols="12" lg="8">
                <GroupSelectionPicker
                    return-object
                    clearable
                    dense
                    no-data-text="Aucune donnée à afficher."
                    label="Sélectionnez un ou plusieurs bénéficiaires"
                    multiple
                    :maxChipsDisplayed="10"
                    deletableChips
                    :population="eligibleRecipients"
                    @input="onRecipientsChange"
                    :value="selectedRecipients"
                />
            </v-col>

            <v-col cols="12" lg="8" v-if="isInThePast">
                <v-alert dense border="left" type="info" outlined>
                    Vous ne pouvez déclarer pour vous-même dans le passé.
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { removeDuplicatesByProp, removeDuplicates } from '@/services/shared';
import GroupSelectionPicker from '@/components/pickers/GroupSelectionPicker.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

export default {
    name: 'RecipientsStep',

    components: {
        GroupSelectionPicker,
    },

    props: {
        eligibleRecipients: {
            type: Array,
            required: true,
        },
        isInThePast: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            selectedRecipients: [],
            ineligibleGroups: [],
        };
    },

    computed: {
        ...addUnavStore.mapState(['recipients']),
    },

    async created() {
        this.selectedRecipients = this.recipients.map((recipient) => ({
            ...recipient,
            value: recipient.gid_collaborateur,
        }));
    },

    watch: {
        recipients(newVal) {
            this.selectedRecipients = newVal.map((recipient) => ({
                ...recipient,
                value: recipient.gid_collaborateur,
            }));
        },
    },

    methods: {
        ...addUnavStore.mapMutations(['setRecipients']),

        onRecipientsChange(newRecipients) {
            this.selectedRecipients = newRecipients;
            const eligibleSelectedRecipients = [];
            const ineligibleGroups = [];

            newRecipients.forEach((element) => {
                if (element.value instanceof Array && element.value.length > 1) {
                    element.value.forEach((peopleGid) => {
                        const recipient = this.getRecipientFromGid(peopleGid);
                        if (recipient) {
                            eligibleSelectedRecipients.push(recipient);
                        } else {
                            ineligibleGroups.push(element.text);
                        }
                    });
                } else {
                    eligibleSelectedRecipients.push(element);
                }
            });

            if (ineligibleGroups.length) {
                this.ineligibleGroups = removeDuplicates(
                    this.ineligibleGroups.concat(ineligibleGroups)
                );
            }

            this.setRecipients(
                removeDuplicatesByProp(eligibleSelectedRecipients, 'gid_collaborateur')
            );
        },

        getRecipientFromGid(gid) {
            return this.eligibleRecipients.find(
                (eligibleRecipient) => eligibleRecipient.gid_collaborateur === gid
            );
        },
    },
};
</script>
