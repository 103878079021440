<template>
    <v-card>
        <v-card-text>
            <InfoSummarySection :employee="employee" />

            <v-divider class="my-2" v-if="employee.solde_rtt !== null" />

            <CounterBalanceSection
                :employee="employee"
                :colsNumber="1"
                v-if="employee.solde_rtt !== null"
            />
        </v-card-text>
    </v-card>
</template>

<script>
import CounterBalanceSection from '@/components/sections/employee/CounterBalanceSection.vue';
import InfoSummarySection from '@/components/sections/employee/InfoSummarySection.vue';

export default {
    name: 'EmployeeInfoCard',

    components: {
        CounterBalanceSection,
        InfoSummarySection,
    },

    props: {
        employee: {
            type: Object,
            required: true,
        },
    },
};
</script>
