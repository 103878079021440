<template>
    <div>
        <p class="bold">Choisissez la date de l'indisponibilité</p>

        <v-tabs :value="actualTab" active-class="active-tab" hide-slider>
            <v-tab @click="selectHalfDay()" class="text-no-wrap">Demi-journée</v-tab>
            <v-tab @click="selectDay()">Journée</v-tab>
            <v-tab @click="selectPeriod()">Période</v-tab>
            <v-tab @click="selectSpecificHours()" class="text-no-wrap">Heures spécifiques</v-tab>
        </v-tabs>

        <div class="ma-3">
            <v-row no-gutters align="center" justify="start">
                <v-col cols="6" md="3">
                    <TextDatePicker
                        dense
                        readonly
                        :label="startDateLabel"
                        prepend-icon="mdi-calendar"
                        :min="minStartDate"
                        :value="selectedStartDate"
                        @input="onSelectedDateUpdate"
                    />
                </v-col>

                <v-col v-if="isHalfDaySelected || isPeriodSelected" cols="5" offset="1">
                    <v-radio-group v-model="isMorningStartDate" row>
                        <v-radio
                            label="Matin"
                            :value="true"
                            :disabled="isStartMorningButtonDisabled"
                        />
                        <v-radio label="Après-midi" :value="false" />
                    </v-radio-group>
                </v-col>

                <v-col v-if="isSpecificHoursSelected" cols="12" md="5" offset="1">
                    <v-row no-gutters align="center" justify="space-between">
                        <v-col cols="12" sm="6">
                            Heure de début
                            <el-time-select
                                :value="startTime"
                                @input="setStartTime"
                                :clearable="false"
                                size="small"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:15',
                                    end: '19:00',
                                }"
                                arrow-control="true"
                            ></el-time-select>
                        </v-col>

                        <v-col cols="12" sm="6">
                            Heure de fin
                            <el-time-select
                                :value="endTime"
                                @input="setEndTime"
                                :clearable="false"
                                size="small"
                                :picker-options="{
                                    start: '00:00',
                                    step: '00:15',
                                    end: '23:45',
                                    minTime: minEndTime,
                                }"
                                arrow-control="true"
                            ></el-time-select>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row v-if="isPeriodSelected || isPeriodic" no-gutters align="center" justify="start">
                <v-col cols="6" md="3">
                    <TextDatePicker
                        dense
                        readonly
                        :label="endDateLabel"
                        prepend-icon="mdi-calendar"
                        :min="minEndDate"
                        :max="maxEndDate"
                        :value="selectedEndDate"
                        @input="setEndDate"
                    />
                </v-col>

                <v-col v-if="!isPeriodic" cols="5" offset="1">
                    <v-radio-group v-model="isMorningEndDate" row>
                        <v-radio label="Matin" :value="true"></v-radio>
                        <v-radio label="Après-midi" :value="false"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>

            <v-row v-if="!isPeriodSelected && !isEditing" no-gutters align="center" justify="start">
                <v-col cols="12">
                    <v-switch
                        inset
                        dense
                        hide-details
                        label="Définir une périodicité"
                        v-model="isPeriodic"
                    />
                </v-col>
            </v-row>
        </div>

        <Periodicity v-if="isPeriodic" class="ma-2 pt-2" />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import moment from 'moment';

import { PERIOD_POSITIONS, PERIOD_OPTIONS } from '@/constants/calendar';
import { ISO_DATE_TIME_FORMAT, ISO_DATE_FORMAT, TIME_FORMAT } from '@/constants/dateFormat';
import { UNAV_PERIOD_TYPE_POSITION, UNAV_PERIOD_TYPE } from '@/constants/addUnavailability';
import { NO } from '@/constants/shared';

import CalendarPeriodMixin from '@/services/mixins/CalendarPeriodMixin';

import TextDatePicker from '@/components/pickers/TextDatePicker.vue';
import Periodicity from '@/components/addUnavailability/dateStep/Periodicity.vue';

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

export default {
    name: 'DateStep',

    components: {
        TextDatePicker,
        Periodicity,
    },

    props: {
        hasDelegates: {
            type: Boolean,
            required: true,
        },
        isN1N2Rh: {
            type: Boolean,
            required: true,
        },
    },

    mixins: [CalendarPeriodMixin],

    data() {
        return {
            NO,
        };
    },

    computed: {
        ...addUnavStore.mapState({
            isEditing: 'isEditing',
            unavPeriodType: (state) => state.unavPeriod.type,
            selectedStartDate: (state) => state.unavPeriod.startDate,
            selectedEndDate: (state) => state.unavPeriod.endDate,
            startTime: (state) => state.unavPeriod.startTime,
            endTime: (state) => state.unavPeriod.endTime,
            periodicity: (state) => state.unavPeriod.periodicity,
        }),

        canAddInPast() {
            return this.hasDelegates && this.isN1N2Rh;
        },
        isMorningStartDate: {
            get() {
                return this.startTime === '00:00';
            },
            set(val) {
                if (val) {
                    this.setStartTime('00:00');

                    if (this.isHalfDaySelected) {
                        this.setEndTime('11:59');
                    }
                } else {
                    this.setStartTime('12:00');

                    if (this.isHalfDaySelected) {
                        this.setEndTime('23:59');
                    }
                }
            },
        },
        isMorningEndDate: {
            get() {
                return this.endTime === '11:59';
            },
            set(val) {
                if (val) {
                    this.setEndTime('11:59');
                } else {
                    this.setEndTime('23:59');
                }
            },
        },
        isPeriodic: {
            get() {
                return !!this.periodicity;
            },
            set(val) {
                if (val) {
                    this.initializePeriodicity();
                    this.onSelectedPeriodUpdate(PERIOD_OPTIONS[PERIOD_POSITIONS.MONTH_POSITION]);
                    this.setEndDate(this.minEndDate);
                } else {
                    this.setPeriodicity(undefined);
                    this.setEndDate(this.selectedStartDate);
                }
            },
        },

        // VALIDATION
        isDateValid() {
            return (
                this.selectedStartDate &&
                this.startTime &&
                this.selectedEndDate &&
                this.endTime &&
                moment(
                    `${this.selectedEndDate}T${this.endTime}:00`,
                    ISO_DATE_TIME_FORMAT
                ).isSameOrAfter(
                    moment(
                        `${this.minEndDate}T${this.minEndTime ? this.minEndTime : '00:00'}:00`,
                        ISO_DATE_TIME_FORMAT
                    )
                )
            );
        },
        isStartMorningButtonDisabled() {
            return this.minEndTime
                ? this.isMomentAfternoon(moment(this.minEndTime, TIME_FORMAT))
                : false;
        },
        isStartDateBeforeMin() {
            return this.minStartDate
                ? moment(this.selectedStartDate, ISO_DATE_FORMAT).isBefore(
                      moment(this.minStartDate, ISO_DATE_FORMAT)
                  )
                : false;
        },
        isAfternoon() {
            return this.isMomentAfternoon(moment());
        },

        // RULES
        minStartDate() {
            if (!this.canAddInPast && !this.isPeriodSelected && !this.isPeriodic) {
                return moment().format(ISO_DATE_FORMAT);
            }
            return '';
        },
        minEndDate() {
            const selectedStartDateMoment = moment(this.selectedStartDate, ISO_DATE_FORMAT);
            const minStartDateMoment = moment();
            const minMoment =
                !this.canAddInPast && selectedStartDateMoment.isBefore(minStartDateMoment)
                    ? minStartDateMoment
                    : selectedStartDateMoment;

            if (this.isPeriodSelected || this.isPeriodic) {
                return minMoment.add(1, 'days').format(ISO_DATE_FORMAT);
            }
            return minMoment.format(ISO_DATE_FORMAT);
        },
        maxEndDate() {
            return this.isPeriodic
                ? moment(this.selectedStartDate, ISO_DATE_FORMAT)
                      .add(1, 'year')
                      .format(ISO_DATE_FORMAT)
                : '';
        },
        minEndTime() {
            const isEndDateInPast = moment(this.selectedEndDate, ISO_DATE_FORMAT).isSameOrBefore(
                moment()
            );
            const minEndTime = moment(this.startTime, TIME_FORMAT).isBefore(moment())
                ? moment().format(TIME_FORMAT)
                : this.startTime;

            if (isEndDateInPast && !this.canAddInPast) {
                return minEndTime;
            }
            return this.isSpecificHoursSelected ? this.startTime : '';
        },

        // MODAL UTILS
        actualTab() {
            return this.unavPeriodType ? UNAV_PERIOD_TYPE_POSITION[this.unavPeriodType] : 0;
        },
        startDateLabel() {
            if (this.isPeriodic) {
                return 'Date de début de la periodicité';
            }
            if (this.isPeriodSelected) {
                return 'Date de début';
            }
            return "Date de l'indisponibilité";
        },
        endDateLabel() {
            if (this.isPeriodic) {
                return 'Date de fin de la periodicité';
            }
            if (this.isPeriodSelected) {
                return 'Date de fin';
            }
            return '';
        },
        isHalfDaySelected() {
            return this.unavPeriodType === UNAV_PERIOD_TYPE.HALFDAY;
        },
        isDaySelected() {
            return this.unavPeriodType === UNAV_PERIOD_TYPE.DAY;
        },
        isPeriodSelected() {
            return this.unavPeriodType === UNAV_PERIOD_TYPE.PERIOD;
        },
        isSpecificHoursSelected() {
            return this.unavPeriodType === UNAV_PERIOD_TYPE.SPECIFIC_HOURS;
        },
    },

    created() {
        if (this.isEditing) {
            this.onSelectedDateUpdate(this.selectedStartDate);
        } else {
            this.setStartDate(this.selectedDate);
            this.selectHalfDay();
        }
    },

    watch: {
        selectedDate(newVal) {
            this.setStartDate(newVal);

            const selectedEndMoment = moment(this.selectedEndDate, ISO_DATE_FORMAT);
            const newValMoment = moment(newVal, ISO_DATE_FORMAT);
            const isEndDateBeforeStartDate = selectedEndMoment.isBefore(newValMoment);
            const is1yearGreaterDiff = selectedEndMoment.diff(newValMoment, 'years', true) > 1;

            if (isEndDateBeforeStartDate || (!this.isPeriodSelected && !this.isPeriodic)) {
                this.setEndDate(this.minEndDate);
            } else if (is1yearGreaterDiff && this.isPeriodic) {
                this.setEndDate(this.maxEndDate);
            }
        },
        startTime(newVal) {
            if (!newVal) {
                this.setStartTime('08:00');
            }
            const isEndTimeBeforeStartTime = moment(this.endTime, TIME_FORMAT).isSameOrBefore(
                moment(newVal, TIME_FORMAT)
            );
            if (isEndTimeBeforeStartTime && this.isSpecificHoursSelected) {
                this.setEndTime(
                    moment(this.minEndTime, TIME_FORMAT).add(15, 'minutes').format(TIME_FORMAT)
                );
            }
        },
        isDateValid(newVal) {
            if (!newVal) {
                switch (this.unavPeriodType) {
                    case UNAV_PERIOD_TYPE.HALFDAY:
                        this.selectHalfDay();
                        break;
                    case UNAV_PERIOD_TYPE.DAY:
                        this.selectDay();
                        break;
                    case UNAV_PERIOD_TYPE.PERIOD:
                        this.selectPeriod();
                        break;
                    case UNAV_PERIOD_TYPE.SPECIFIC_HOURS:
                        this.selectSpecificHours();
                        break;
                    default:
                        this.selectHalfDay();
                        break;
                }
            }
        },
    },

    methods: {
        ...addUnavStore.mapMutations([
            'setUnavType',
            'setUnavPeriod',
            'setStartDate',
            'setEndDate',
            'setStartTime',
            'setEndTime',

            'initializePeriodicity',
            'setPeriodicity',
        ]),

        selectHalfDay() {
            this.showTab(UNAV_PERIOD_TYPE.HALFDAY);
            this.onSelectedPeriodUpdate(PERIOD_OPTIONS[PERIOD_POSITIONS.WEEK_POSITION]);

            if (this.isStartDateBeforeMin) {
                this.onSelectedDateUpdate(this.minStartDate);
            }
            this.setEndDate(this.selectedDate);

            if (this.isAfternoon) {
                this.setStartTime('12:00');
                this.setEndTime('23:59');
            } else {
                this.setStartTime('00:00');
                this.setEndTime('11:59');
            }
        },
        selectDay() {
            this.showTab(UNAV_PERIOD_TYPE.DAY);
            this.onSelectedPeriodUpdate(PERIOD_OPTIONS[PERIOD_POSITIONS.WEEK_POSITION]);

            if (this.isStartDateBeforeMin) {
                this.onSelectedDateUpdate(this.minStartDate);
            }
            this.setEndDate(this.selectedDate);

            this.setStartTime('00:00');
            this.setEndTime('23:59');
        },
        selectPeriod() {
            this.showTab(UNAV_PERIOD_TYPE.PERIOD);
            this.onSelectedPeriodUpdate(PERIOD_OPTIONS[PERIOD_POSITIONS.MONTH_POSITION]);

            if (this.isStartDateBeforeMin) {
                this.onSelectedDateUpdate(this.minStartDate);
            }
            this.setEndDate(this.minEndDate);

            if (this.isAfternoon) {
                this.setStartTime('12:00');
            } else {
                this.setStartTime('00:00');
            }
            this.setEndTime('23:59');
        },
        selectSpecificHours() {
            this.showTab(UNAV_PERIOD_TYPE.SPECIFIC_HOURS);
            this.onSelectedPeriodUpdate(PERIOD_OPTIONS[PERIOD_POSITIONS.DAY_POSITION]);

            if (this.isStartDateBeforeMin) {
                this.onSelectedDateUpdate(this.minStartDate);
            }
            this.setEndDate(this.selectedDate);

            this.setStartTime('08:00');
            this.setEndTime('19:00');
        },

        isMomentAfternoon(momentDate) {
            return momentDate.isAfter(moment('11:59', TIME_FORMAT));
        },

        showTab(periodType) {
            this.setPeriodicity(undefined);
            this.setUnavType(periodType);
        },
    },
};
</script>
