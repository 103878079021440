<template>
    <v-card tile>
        <AddGroup
            v-if="isAddGroupDisplayed"
            v-model="isAddGroupDisplayed"
            :group="groupToEdit"
            :candidates="perimeterPeople"
        />
        <v-card-title>
            <v-text-field
                append-icon="mdi-magnify"
                clearable
                hide-details
                single-line
                label="Rechercher une personne"
                v-model="enteredSearch"
            />
        </v-card-title>
        <v-card-text>
            <v-btn @click="addNewGroup()" class="mb-3 primary" depressed tile>
                <v-icon class="mr-2">mdi-plus</v-icon>
                Créer un groupe
            </v-btn>
            <el-tree
                node-key="id"
                :data="groupsTreeWithTeam"
                :props="defaultProps"
                :filter-node-method="filterNode"
                :default-checked-keys="selectedGids"
                accordion
                show-checkbox
                ref="groupTree"
                @check="sendSelectedUsers"
            >
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ node.label }}</span>
                    <span class="node-controls" v-if="!data.noneditable && data.groupname">
                        <v-btn
                            fab
                            elevation="0"
                            dark
                            x-small
                            class="primary"
                            @click.stop="editGroup(data)"
                        >
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </span>
                </span>
            </el-tree>
        </v-card-text>
    </v-card>
</template>

<script>
import { treeFilterNodeMethod } from '@/services/shared';
import groupsService from '@/services/groups';
import AddGroup from '@/components/calendar/calendarParams/AddGroup.vue';

export default {
    name: 'GroupsCard',

    components: {
        AddGroup,
    },

    props: {
        groupsTree: {
            type: Array,
            required: true,
        },
        teams: {
            type: Array,
            required: true,
        },
        perimeterPeople: {
            type: Array,
            required: true,
        },
        selectedGids: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            isAddGroupDisplayed: false,
            groupToEdit: undefined,

            defaultProps: {
                children: 'children',
                label: 'label',
                disabled: 'disabled',
            },
            enteredSearch: '',

            timeout: null,
            debounceValue: 500,
        };
    },

    watch: {
        enteredSearch(newVal) {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.$refs.groupTree.filter(newVal);
            }, this.debounceValue);
        },
    },

    computed: {
        groupsTreeWithTeam() {
            return [groupsService.fromTeamsToTreeNode(this.teams), ...this.groupsTree];
        },
    },

    methods: {
        // ACTIONS
        addNewGroup() {
            this.groupToEdit = undefined;
            this.isAddGroupDisplayed = true;
        },
        editGroup(group) {
            this.groupToEdit = group;
            this.isAddGroupDisplayed = true;
        },

        // TREE ACTIONS
        filterNode(search, node) {
            return treeFilterNodeMethod(search, node, this.defaultProps.label);
        },
        sendSelectedUsers() {
            const selectedLeafs = this.$refs.groupTree
                .getCheckedNodes()
                .filter((node) => !node.children);
            this.$emit('selected-users-change', selectedLeafs);
        },
    },
};
</script>
