import { formatToIsoString } from '@/services/shared';
import { OK, NO } from '@/constants/shared';
import { ISO_DATE_TIME_FORMAT } from '@/constants/dateFormat';

function buildAddUnavPayload(unavPeriod, recipientsGids, reason, constraint, comment) {
    let addUnavPayload;
    if (unavPeriod.periodicity) {
        addUnavPayload = {
            is_monday: unavPeriod.periodicity.periodicityDays.includes(0) ? OK : NO,
            is_tuesday: unavPeriod.periodicity.periodicityDays.includes(1) ? OK : NO,
            is_wednesday: unavPeriod.periodicity.periodicityDays.includes(2) ? OK : NO,
            is_thursday: unavPeriod.periodicity.periodicityDays.includes(3) ? OK : NO,
            is_friday: unavPeriod.periodicity.periodicityDays.includes(4) ? OK : NO,
            is_saturday: unavPeriod.periodicity.periodicityDays.includes(5) ? OK : NO,
            is_sunday: unavPeriod.periodicity.periodicityDays.includes(6) ? OK : NO,
            is_any_day: !unavPeriod.periodicity.isWorkingDay ? OK : NO,
            is_working_day: unavPeriod.periodicity.isWorkingDay ? OK : NO,
            number_weeks: unavPeriod.periodicity.numberWeeks,
            number_days: unavPeriod.periodicity.numberDays,
            periodicity_type: unavPeriod.periodicity.type,

            commentaire_declarant: comment,
            gid_collaborateur: recipientsGids,
            indisponibilite: reason.indisponibilite,
            periodic_end_hour: unavPeriod.endTime,
            periodic_range_end_date: unavPeriod.endDate,
            periodic_range_start_date: unavPeriod.startDate,
            periodic_start_hour: unavPeriod.startTime,
        };
    } else {
        addUnavPayload = {
            property_number: constraint ? constraint.propertyNumber : null,
            placement_type: constraint ? constraint.placementType : null,
            works_label: constraint ? constraint.worksLabel : null,
            construction_site_code: constraint
                ? parseInt(constraint.constructionSiteCode, 10)
                : null,
            commentaire_declarant: comment,
            date_debut: formatToIsoString(
                `${unavPeriod.startDate}T${unavPeriod.startTime}:00`,
                ISO_DATE_TIME_FORMAT
            ),
            date_fin: formatToIsoString(
                `${unavPeriod.endDate}T${unavPeriod.endTime}:00`,
                ISO_DATE_TIME_FORMAT
            ),
            gid_collaborateur: recipientsGids,
            indisponibilite: reason.indisponibilite,
        };
    }

    return addUnavPayload;
}

export default {
    buildAddUnavPayload,
};
