<template>
    <v-card tile min-height="100%">
        <v-card-title>Mes droits de délégation</v-card-title>

        <div class="delegation-list overflow-y-auto" v-if="delegators && delegators.length > 0">
            <v-list-item v-for="(delegator, i) in delegators" :key="i" two-line>
                <v-list-item-content>
                    <v-list-item-title>
                        {{ delegator.nom }} {{ delegator.prenom }}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="delegator.date_debut && delegator.date_fin">
                        {{ delegator.date_debut | formatDate }} au
                        {{ delegator.date_fin | formatDate }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-else>Délégation permanente</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </div>
        <v-card-text v-else>Aucun</v-card-text>
    </v-card>
</template>

<script>
import moment from 'moment';
import { DATE_FORMAT } from '@/constants/dateFormat';

export default {
    name: 'MyDelegators',

    props: {
        delegators: {
            type: Array,
            required: true,
        },
    },

    filters: {
        formatDate(isoDate) {
            return moment(isoDate).format(DATE_FORMAT);
        },
    },
};
</script>

<style scoped lang="scss">
.delegation-list {
    max-height: 320px;
}

.v-card__title {
    word-break: normal;
    white-space: nowrap;
}
</style>
