<template>
    <v-card tile min-height="100%">
        <AddDelegation
            v-if="isAddDelegationDisplayed"
            v-model="isAddDelegationDisplayed"
            :candidates="candidates"
            :delegation="delegationToEdit"
            @added-delegation="onAddDelegation"
            @edited-delegation="onEditDelegation"
            @deleted-delegation="onDeleteDelegation"
        />

        <v-card-title>
            Mes délégués
            <v-spacer />
            <v-btn
                icon
                color="accent"
                :disabled="isMaxDelegationsReached"
                @click="addNewDelegation()"
            >
                <v-icon>mdi-account-plus</v-icon>
            </v-btn>
        </v-card-title>

        <Spinner v-if="isSpinnerDiplayed" />

        <template v-else>
            <div class="delegation-list overflow-y-auto" v-if="delegates && delegates.length > 0">
                <v-list-item
                    v-for="delegate in delegates"
                    :key="delegate.gid_collaborateur"
                    two-line
                >
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ delegate.nom }} {{ delegate.prenom }}
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="delegate.date_debut && delegate.date_fin"
                            :class="{ 'is-expired': delegate.isExpired }"
                        >
                            {{ delegate.date_debut | formatDate }} au
                            {{ delegate.date_fin | formatDate }}
                            <span v-if="delegate.isExpired">(expirée)</span>
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>Délégation permanente</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon color="accent" @click="editDelegation(delegate)">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </div>

            <v-card-text v-else>Aucun</v-card-text>
        </template>
    </v-card>
</template>

<script>
import { mapMutations, createNamespacedHelpers } from 'vuex';
import moment from 'moment';
import { DATE_FORMAT, ISO_DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { DELEGATIONS } from '@/constants/succesMessages';
import delegationsApi from '@/api/delegations';
import AddDelegation from '@/components/delegation/AddDelegation.vue';
import Spinner from '@/components/Spinner.vue';

const perimeterNameSpace = createNamespacedHelpers('perimeter');
const perimeterStore = {
    mapGetters: perimeterNameSpace.mapGetters,
    mapActions: perimeterNameSpace.mapActions,
};

const fileName = 'MyDelegations.vue';

export default {
    name: 'MyDelegations',

    components: {
        AddDelegation,
        Spinner,
    },

    data() {
        return {
            isSpinnerDiplayed: false,
            isAddDelegationDisplayed: false,

            delegationToEdit: undefined,
            delegations: [],
        };
    },

    computed: {
        ...perimeterStore.mapGetters(['perimeterPeople', 'perimeterObject']),

        candidates() {
            return this.perimeterPeople.filter(
                (person) =>
                    !this.delegations.some(
                        (delegation) => delegation.gid_collaborateur === person.gid_collaborateur
                    )
            );
        },
        delegates() {
            return this.delegations
                .map((delegation) => ({
                    ...delegation,
                    ...this.perimeterObject[delegation.gid_collaborateur],
                    isExpired: this.isExpired(delegation.date_fin),
                }))
                .sort((a, b) => `${a.nom} ${a.prenom}`.localeCompare(`${b.nom} ${b.prenom}`));
        },
        isMaxDelegationsReached() {
            return this.delegations.length >= 5;
        },
    },

    async created() {
        this.showNavSpinner();
        await this.loadPerimeter();
        await this.loadDelegations();
        this.hideNavSpinner();
    },

    filters: {
        formatDate(date) {
            return date ? moment(date).format(DATE_FORMAT) : '';
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast']),
        ...perimeterStore.mapActions(['loadPerimeter']),

        // ACTIONS
        async loadDelegations() {
            try {
                this.delegations = await delegationsApi.getDelegations();
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },
        addNewDelegation() {
            this.delegationToEdit = undefined;
            this.isAddDelegationDisplayed = true;
        },
        editDelegation(delegate) {
            this.delegationToEdit = delegate;
            this.isAddDelegationDisplayed = true;
        },

        // EVENTS
        async onAddDelegation(newDelegation) {
            try {
                this.showNavSpinner();
                const addedDelegation = await delegationsApi.createDelegation(newDelegation);
                this.delegations.push(addedDelegation);
                this.showToast({
                    title: DELEGATIONS.CREATE_DELEGATIONS.TITLE,
                    message: DELEGATIONS.CREATE_DELEGATIONS.MESSAGE,
                    dismiss: false,
                });
                this.hideNavSpinner();
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideNavSpinner();
            }
        },
        async onEditDelegation(newDelegation) {
            try {
                this.showNavSpinner();
                this.delegations = this.delegations.filter(
                    (delegation) => delegation.gid_collaborateur !== newDelegation.gid_collaborateur
                );
                const updatedDelegation = await delegationsApi.updateDelegation(newDelegation);
                this.delegations.push(updatedDelegation);
                this.showToast({
                    title: DELEGATIONS.UPDATE_DELEGATIONS.TITLE,
                    message: DELEGATIONS.UPDATE_DELEGATIONS.MESSAGE,
                    dismiss: false,
                });
                this.hideNavSpinner();
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideNavSpinner();
            }
        },
        async onDeleteDelegation(delegationGid) {
            try {
                this.showNavSpinner();
                await delegationsApi.deleteDelegations(delegationGid);
                this.delegations = this.delegations.filter(
                    (delegation) => delegation.gid_collaborateur !== delegationGid
                );
                this.showToast({
                    title: DELEGATIONS.DELETE_DELEGATIONS.TITLE,
                    message: DELEGATIONS.DELETE_DELEGATIONS.MESSAGE,
                    dismiss: false,
                });
                this.hideNavSpinner();
            } catch (error) {
                this.showErrorToast({ error, fileName });
                this.hideNavSpinner();
            }
        },

        // HELPERS
        isExpired(date) {
            return date && moment(date, ISO_DATE_TIME_FORMAT).isBefore(moment());
        },
        showNavSpinner() {
            this.isSpinnerDiplayed = true;
        },
        hideNavSpinner() {
            this.isSpinnerDiplayed = false;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.delegation-list {
    max-height: 320px;
}

.v-card__title {
    word-break: normal;
    white-space: nowrap;
}

.is-expired {
    color: var(--v-error-lighten3) !important;
}
</style>
