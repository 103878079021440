<template>
    <div>
        <span class="black-text">
            <v-icon color="primary">mdi-alert-circle-outline</v-icon>
            {{ selectedUnav.attributes.tooltip }}
        </span>
        <br />

        <div v-if="isConstraint">
            <span v-if="placement === LOCKED_PLACEMENT_VALUE" class="black-text">
                <v-icon color="primary">mdi-lock</v-icon>
                Placement fixe
            </span>
            <span v-else-if="placement === FREE_PLACEMENT_VALUE" class="black-text">
                <v-icon color="primary">mdi-lock-open</v-icon>
                Placement libre
            </span>
            <br v-if="placement" />

            <span v-if="comment" class="black-text">
                <v-icon color="primary">mdi-comment-text</v-icon>
                {{ comment }}
            </span>
        </div>
    </div>
</template>

<script>
import unavailabilitiesService from '@/services/unavailabilities';
import { LOCKED_PLACEMENT_VALUE, FREE_PLACEMENT_VALUE } from '@/constants/unavailabilities';

export default {
    name: 'UnavTooltipSection',

    props: {
        selectedUnav: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            LOCKED_PLACEMENT_VALUE,
            FREE_PLACEMENT_VALUE,
        };
    },

    computed: {
        isConstraint() {
            return unavailabilitiesService.isConstraint(this.selectedUnav);
        },
        placement() {
            const constraint = unavailabilitiesService.getConstraint(this.selectedUnav);
            return constraint && constraint.placementType;
        },
        comment() {
            return this.selectedUnav.commentaire_declarant;
        },
    },
};
</script>

<style scoped lang="scss">
@import '@/styles/colors';

.black-text {
    color: $black;
}
</style>
