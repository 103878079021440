import moment from 'moment';
import { PERIOD_POSITIONS, PERIOD_OPTIONS, PERIOD_LABELS } from '@/constants/calendar';
import { ISO_DATE_FORMAT } from '@/constants/dateFormat';

export default {
    data() {
        return {
            todayDate: moment().format(ISO_DATE_FORMAT),
            isoFormat: ISO_DATE_FORMAT,
            periodOptions: PERIOD_OPTIONS,

            selectedDate: moment().format(ISO_DATE_FORMAT),
            selectedPeriod: PERIOD_OPTIONS[PERIOD_POSITIONS.TWO_WEEK_POSITION],
        };
    },

    computed: {
        startDate() {
            return this.getStartDate(this.selectedDate, this.selectedPeriod);
        },
        endDate() {
            return this.getEndDate(this.selectedDate, this.selectedPeriod);
        },
    },

    created() {
        this.updatePeriod();
    },

    methods: {
        onSelectedDateUpdate(newDate) {
            this.selectedDate = newDate;
            this.updatePeriod();
        },
        onSelectedPeriodUpdate(newPeriod) {
            this.selectedPeriod = newPeriod;
            this.updatePeriod();
        },
        updatePeriod() {
            this.$emit('update-period', {
                date: this.selectedDate,
                startDate: this.startDate,
                endDate: this.endDate,
                periodType: this.selectedPeriod.text,
            });
        },

        toActualDay() {
            this.onSelectedDateUpdate(this.todayDate);
        },
        goNext() {
            const newDate = moment(this.selectedDate, ISO_DATE_FORMAT);
            let selectedDate;
            switch (this.selectedPeriod.text) {
                case PERIOD_LABELS.DAY_LABEL:
                    selectedDate = newDate.add(1, 'days');
                    break;
                case PERIOD_LABELS.WEEK_LABEL:
                    selectedDate = newDate.add(1, 'weeks');
                    break;
                case PERIOD_LABELS.TWO_WEEK_LABEL:
                    selectedDate = newDate.add(2, 'weeks');
                    break;
                case PERIOD_LABELS.MONTH_LABEL:
                    selectedDate = newDate.add(4, 'weeks');
                    break;
                case PERIOD_LABELS.YEAR_LABEL:
                    selectedDate = newDate.add(53, 'weeks');
                    break;
                default:
                    selectedDate = newDate.add(2, 'weeks');
                    break;
            }
            this.onSelectedDateUpdate(this.formatToIsoDate(selectedDate));
        },
        goBack() {
            const newDate = moment(this.selectedDate, ISO_DATE_FORMAT);
            let selectedDate;
            switch (this.selectedPeriod.text) {
                case PERIOD_LABELS.DAY_LABEL:
                    selectedDate = newDate.subtract(1, 'days');
                    break;
                case PERIOD_LABELS.WEEK_LABEL:
                    selectedDate = newDate.subtract(1, 'weeks');
                    break;
                case PERIOD_LABELS.TWO_WEEK_LABEL:
                    selectedDate = newDate.subtract(2, 'weeks');
                    break;
                case PERIOD_LABELS.MONTH_LABEL:
                    selectedDate = newDate.subtract(4, 'weeks');
                    break;
                case PERIOD_LABELS.YEAR_LABEL:
                    selectedDate = newDate.subtract(53, 'weeks');
                    break;
                default:
                    selectedDate = newDate.subtract(2, 'weeks');
                    break;
            }
            this.onSelectedDateUpdate(this.formatToIsoDate(selectedDate));
        },

        getStartDate(selectedDate, selectedPeriod) {
            if (selectedPeriod.text === PERIOD_LABELS.DAY_LABEL) {
                return selectedDate;
            }
            return moment(this.selectedDate, ISO_DATE_FORMAT)
                .startOf('isoWeek')
                .format(ISO_DATE_FORMAT);
        },
        getEndDate(selectedDate, selectedPeriod) {
            if (selectedPeriod.text === PERIOD_LABELS.DAY_LABEL) {
                return selectedDate;
            }

            const endWeekDate = moment(selectedDate, ISO_DATE_FORMAT).endOf('isoWeek');
            let endDate;
            switch (selectedPeriod.text) {
                case PERIOD_LABELS.WEEK_LABEL:
                    endDate = endWeekDate;
                    break;
                case PERIOD_LABELS.TWO_WEEK_LABEL:
                    endDate = endWeekDate.add(1, 'weeks');
                    break;
                case PERIOD_LABELS.MONTH_LABEL:
                    endDate = endWeekDate.add(3, 'weeks');
                    break;
                case PERIOD_LABELS.YEAR_LABEL:
                    endDate = endWeekDate.add(52, 'weeks');
                    break;
                default:
                    endDate = endWeekDate.add(1, 'weeks');
                    break;
            }
            return this.formatToIsoDate(endDate);
        },
        formatToIsoDate(momentDate) {
            return momentDate.format(ISO_DATE_FORMAT);
        },
    },
};
