import moment from 'moment';

import {
    DATE_FORMAT,
    DATE_TIME_FORMAT,
    ISO_DATE_FORMAT,
    ISO_DATE_TIME_FORMAT,
} from '@/constants/dateFormat';
import { STATUT_INDISPO } from '@/constants/unavailabilities';
import { ZONEA, ZONEB, ZONEC } from '@/constants/holidays';
import {
    AVAILABILITY,
    DAY_HOURS_NUMBER,
    HOUR_MINUTES_NUMBER,
    MAX_PERCENT,
} from '@/constants/calendar';
import { PERIODICITY_TYPE } from '@/constants/addUnavailability';

import { groupBy, removeDuplicatesByProp, removeDuplicates } from '@/services/shared';
import unavailabilitiesService from '@/services/unavailabilities';

// DATE UTILS
function getStartDate(elStartDate, calendarStartDate) {
    return moment(elStartDate).isBefore(moment(calendarStartDate), 'day')
        ? moment(calendarStartDate).startOf('day')
        : moment(elStartDate);
}
function getEndDate(elEndDate, calendarEndDate) {
    return moment(elEndDate).isAfter(moment(calendarEndDate), 'day')
        ? moment(calendarEndDate).endOf('day')
        : moment(elEndDate);
}
function getPercentageFromMinutes(minutes) {
    return (minutes * MAX_PERCENT) / (HOUR_MINUTES_NUMBER * DAY_HOURS_NUMBER);
}
function getPositionInMinutes(startDate) {
    const startTime = startDate.format('HH:mm');
    return moment.duration(startTime).asMinutes();
}
function getDurationInMinutes(startDate, endDate) {
    return moment(endDate).diff(moment(startDate), 'minutes');
}
function queryDateBetweenDates({
    startDate,
    endDate,
    timeUnit = 'days',
    interval = 1,
    isWorkingDay = false,
    isoWeekDays = null,
}) {
    const now = moment(startDate, ISO_DATE_TIME_FORMAT);
    const startDateMoment = moment(startDate, ISO_DATE_TIME_FORMAT);
    const endDateMoment = moment(endDate, ISO_DATE_TIME_FORMAT);

    const dates = [];
    while (now.isSameOrBefore(endDateMoment)) {
        if (timeUnit === 'weeks') {
            const weekStartMoment = now.clone().startOf('isoWeek');
            const weekEndMoment = now.clone().endOf('isoWeek');
            const startLimit = weekStartMoment.isSameOrAfter(startDateMoment)
                ? weekStartMoment
                : now;
            const endLimit = weekEndMoment.isSameOrBefore(endDateMoment)
                ? weekEndMoment
                : endDateMoment;

            dates.push(getDatesBetweenTwoMoments(startLimit, endLimit, 1));
            now.startOf('isoWeek');
        } else if (timeUnit === 'days') {
            dates.push(getDatesBetweenTwoMoments(now, endDateMoment, interval));
        }

        now.add(interval, timeUnit);
    }

    const filteredDates = filterDates(dates.flat(), isWorkingDay, isoWeekDays);
    return removeDuplicates(filteredDates);
}

function filterDates(weekDates, isWorkingDay, isoWeekDays) {
    const saturdayIsoWeekDay = 6;
    const sundayIsoWeekDay = 7;

    return weekDates.filter((date) => {
        const nowIsoWeekDay = moment(date, ISO_DATE_FORMAT).isoWeekday();

        return (
            (!isoWeekDays && !isWorkingDay) ||
            (isoWeekDays && isoWeekDays.includes(nowIsoWeekDay)) ||
            (isWorkingDay &&
                nowIsoWeekDay !== saturdayIsoWeekDay &&
                nowIsoWeekDay !== sundayIsoWeekDay)
        );
    });
}

function getDatesBetweenTwoMoments(startMoment, endMoment, interval) {
    const now = startMoment.clone();
    const dates = [];
    while (now.isSameOrBefore(endMoment)) {
        dates.push(now.format(ISO_DATE_FORMAT));
        now.add(interval, 'days');
    }

    return dates;
}

// UNAVAILABILITIES ATTRIBUTES UTILS
function addTemporalClass(unavStartDate, unavEndDate, startDate, endDate, classes) {
    if (unavStartDate.isBefore(startDate)) {
        classes.push('from_past');
    }
    if (unavEndDate.isAfter(endDate)) {
        classes.push('to_future');
    }
}
function addColorClass(userUnav, classes) {
    let unavStatus;
    switch (userUnav.statut) {
        case STATUT_INDISPO.AVALIDER:
            unavStatus = 'waiting';
            break;
        case STATUT_INDISPO.ATTENTEANNULATION:
            unavStatus = 'cancel';
            break;
        default:
            unavStatus = '';
    }
    const unavailabilityFamilyCode = userUnav?.family_code;

    let colorClass = 'unavailabilities_reason';
    if (unavStatus) {
        colorClass += `_${unavStatus}`;
    }
    if (unavailabilityFamilyCode) {
        colorClass += `_${unavailabilityFamilyCode}`;
    }
    classes.push(colorClass);
}
function addOverlappingClass(
    userUnav,
    userUnavs,
    startDate,
    endDate,
    calendarStartDate,
    calendarEndDate,
    classes
) {
    const isOverlapping = userUnavs
        .filter((unav) => unav !== userUnav)
        .some((unav) => {
            const unavStartDate = getStartDate(unav.date_debut, calendarStartDate);
            const unavEndDate = getEndDate(unav.date_fin, calendarEndDate);
            return startDate.isBefore(unavEndDate) && endDate.isAfter(unavStartDate);
        });
    if (isOverlapping) {
        classes.push('is-overlapping');
    }
}
function addAttributesToUserUnavs(
    userUnavs,
    calendarStartDate,
    calendarEndDate,
    unavailabilitiesReasons
) {
    const userUnavsCopie = userUnavs.slice();
    userUnavs.forEach((userUnav) => {
        const title = userUnav.indisponibilite || '';

        const unavStartDate = moment(userUnav.date_debut);
        const unavEndDate = moment(userUnav.date_fin);
        const startDate = getStartDate(userUnav.date_debut, calendarStartDate);
        const endDate = getEndDate(userUnav.date_fin, calendarEndDate);

        const unavailabilityReason = title ? unavailabilitiesReasons[title] : undefined;
        const lib = unavailabilityReason ? unavailabilityReason.lib_absence : '';

        const tooltip = `${lib || 'Indisponibilité'} du ${unavStartDate.format(
            DATE_TIME_FORMAT
        )} au ${unavEndDate.format(DATE_TIME_FORMAT)}`;

        const classes = [];
        addTemporalClass(unavStartDate, unavEndDate, startDate, endDate, classes);
        addColorClass(userUnav, classes);
        addOverlappingClass(
            userUnav,
            userUnavsCopie,
            startDate,
            endDate,
            calendarStartDate,
            calendarEndDate,
            classes
        );

        userUnav.attributes = { classes, lib, title, tooltip };
    });
}
function getUserUnavs(
    user,
    unavailabilities,
    calendarStartDate,
    calendarEndDate,
    unavailabilitiesReasons
) {
    const userUnavs = unavailabilities.filter(
        (unav) => unav.gid_collaborateur === user.gid_collaborateur
    );
    if (!userUnavs || !userUnavs.length) {
        return [];
    }

    addAttributesToUserUnavs(
        userUnavs,
        calendarStartDate,
        calendarEndDate,
        unavailabilitiesReasons
    );
    return userUnavs;
}
export function getUnavs(
    users,
    unavailabilities,
    calendarStartDate,
    calendarEndDate,
    unavailabilitiesReasons
) {
    if (!(unavailabilities && unavailabilities.length)) {
        return [];
    }
    return users.map((user) =>
        getUserUnavs(
            user,
            unavailabilities,
            calendarStartDate,
            calendarEndDate,
            unavailabilitiesReasons
        )
    );
}

// EVENT UTILS
function getEvent(elStartDate, elEndDate, element, calendarStartDate, calendarEndDate) {
    const startDate = getStartDate(elStartDate, calendarStartDate);
    const endDate = getEndDate(elEndDate, calendarEndDate);

    const positionInMinutes = getPositionInMinutes(startDate);
    const position = getPercentageFromMinutes(positionInMinutes);

    const durationInMinutes = getDurationInMinutes(startDate, endDate);
    const duration = getPercentageFromMinutes(durationInMinutes);

    return {
        day: startDate.format(ISO_DATE_FORMAT),
        value: { position, duration, element },
    };
}
function groupEventsByDate(userEvents) {
    const mergedUserEvents = {};
    userEvents.forEach((event) => {
        const sameDayEvent = mergedUserEvents[event.day];
        if (sameDayEvent && sameDayEvent.length) {
            sameDayEvent.push(event.value);
        } else {
            mergedUserEvents[event.day] = [event.value];
        }
    });
    return mergedUserEvents;
}

// GET EVENTS BY TYPE FOR SINGLE USER
function getUserUnavEvents(unavs, calendarStartDate, calendarEndDate) {
    const userUnavs = unavs.filter((unav) => !unavailabilitiesService.isConstraint(unav));
    if (!userUnavs || !userUnavs.length) {
        return undefined;
    }

    const userUnavEvents = userUnavs.map((userUnav) =>
        getEvent(
            userUnav.date_debut,
            userUnav.date_fin,
            userUnav,
            calendarStartDate,
            calendarEndDate
        )
    );
    return groupEventsByDate(userUnavEvents);
}
function getUserConstraintUnavEvents(unavs, calendarStartDate, calendarEndDate) {
    const userConstraintUnavs = unavs.filter((unav) => unavailabilitiesService.isConstraint(unav));
    if (!userConstraintUnavs || !userConstraintUnavs.length) {
        return undefined;
    }

    const userConstraintUnavEvents = userConstraintUnavs.map((userConstraintUnav) =>
        getEvent(
            userConstraintUnav.date_debut,
            userConstraintUnav.date_fin,
            userConstraintUnav,
            calendarStartDate,
            calendarEndDate
        )
    );
    return groupEventsByDate(userConstraintUnavEvents);
}
function getUserHolidaysEvents(user, holidays, calendarStartDate, calendarEndDate) {
    const userHolidaysObject = holidays.find((holiday) => holiday.gid === user.gid_collaborateur);
    if (
        !userHolidaysObject ||
        !userHolidaysObject.holidays ||
        !userHolidaysObject.holidays.length
    ) {
        return undefined;
    }

    const userHolidaysEvents = userHolidaysObject.holidays.map((userHoliday) =>
        getEvent(
            moment(userHoliday.holiday, DATE_FORMAT).startOf('day'),
            moment(userHoliday.holiday, DATE_FORMAT).endOf('day'),
            userHoliday,
            calendarStartDate,
            calendarEndDate
        )
    );
    return groupEventsByDate(userHolidaysEvents);
}
function getUserWorkRythmEvents(user, workRythms, calendarStartDate, calendarEndDate) {
    const userWorkRythmsObject = workRythms.find(
        (workRythm) => workRythm.gid === user.gid_collaborateur
    );
    if (
        !userWorkRythmsObject ||
        !userWorkRythmsObject.work_rate ||
        !userWorkRythmsObject.work_rate.length
    ) {
        return undefined;
    }

    const userWorkRythmEvents = userWorkRythmsObject.work_rate.map((userWorkRythm) =>
        getEvent(
            userWorkRythm.start_time,
            userWorkRythm.end_time,
            userWorkRythm,
            calendarStartDate,
            calendarEndDate
        )
    );
    return groupEventsByDate(userWorkRythmEvents);
}
function getUserDutyCallEvents(user, dutyCalls, calendarStartDate, calendarEndDate) {
    const userDutyCallsObject = dutyCalls.find(
        (dutyCall) => dutyCall.gid === user.gid_collaborateur
    );
    if (
        !userDutyCallsObject ||
        !userDutyCallsObject.standby_time ||
        !userDutyCallsObject.standby_time.length
    ) {
        return undefined;
    }

    const userDutyCallEvents = userDutyCallsObject.standby_time.map((userDutyCall) =>
        getEvent(
            userDutyCall.start_date,
            userDutyCall.end_date,
            userDutyCall,
            calendarStartDate,
            calendarEndDate
        )
    );
    return groupEventsByDate(userDutyCallEvents);
}

// SCHOOL HOLIDAYS
function addAttributesToHolidays(holidays, displayedZones) {
    holidays.forEach((holiday) => {
        const title = holiday.description;
        const tooltip = `${holiday.description}, ${holiday.zones}`;
        const classes = [];
        switch (holiday.zones) {
            case ZONEA:
                classes.push('zone-a');
                break;
            case ZONEB:
                classes.push('zone-b');
                break;
            case ZONEC:
                classes.push('zone-c');
                break;
            default:
                classes.push('zone-a');
        }

        if (displayedZones.length === 3) {
            classes.push('is-treelapping');
        } else if (displayedZones.length === 2) {
            classes.push('is-overlapping');
        }
        holiday.attributes = { classes, title, tooltip };
    });
}
export function getSchoolHolidaysEvents(
    schoolHolidays,
    calendarStartDate,
    calendarEndDate,
    displayedZones
) {
    const periodHolidays = schoolHolidays.filter((holiday) => {
        const momentHolidayStartDate = moment(holiday.start_date);
        const momentHolidayEndDate = moment(holiday.end_date);

        const momentCalendarStartDate = moment(calendarStartDate);
        const momentCalendarEndDate = moment(calendarEndDate);

        return (
            ((momentHolidayStartDate.isSameOrBefore(momentCalendarStartDate) &&
                momentHolidayEndDate.isAfter(momentCalendarStartDate)) ||
                (momentHolidayStartDate.isAfter(momentCalendarStartDate) &&
                    momentHolidayStartDate.isBefore(momentCalendarEndDate))) &&
            [ZONEA, ZONEB, ZONEC].includes(holiday.zones)
        );
    });

    const groupedByDateHolidays = groupBy(periodHolidays, 'start_date');
    const holidays = Object.values(groupedByDateHolidays)
        .map((dateHolidays) => removeDuplicatesByProp(dateHolidays, 'zones'))
        .flat();
    if (!holidays || !holidays.length) {
        return {};
    }

    addAttributesToHolidays(holidays, displayedZones);

    const schoolHolidaysEvents = holidays.map((holiday) =>
        getEvent(holiday.start_date, holiday.end_date, holiday, calendarStartDate, calendarEndDate)
    );
    return groupEventsByDate(schoolHolidaysEvents);
}

// GET EVENTS BY TYPE
export function getUnavEvents(users, unavs, calendarStartDate, calendarEndDate) {
    if (!(unavs && unavs.length)) {
        return {};
    }
    return unavs.reduce((result, userUnavs, index) => {
        const userUnavEvents = getUserUnavEvents(userUnavs, calendarStartDate, calendarEndDate);
        if (userUnavEvents) {
            result[users[index].gid_collaborateur] = userUnavEvents;
        }
        return result;
    }, {});
}
export function getConstraintUnavEvents(users, unavs, calendarStartDate, calendarEndDate) {
    if (!(unavs && unavs.length)) {
        return {};
    }
    return unavs.reduce((result, userUnav, index) => {
        const userConstraintUnavEvents = getUserConstraintUnavEvents(
            userUnav,
            calendarStartDate,
            calendarEndDate
        );
        if (userConstraintUnavEvents) {
            result[users[index].gid_collaborateur] = userConstraintUnavEvents;
        }
        return result;
    }, {});
}
export function getHolidayEvents(users, holidays, calendarStartDate, calendarEndDate) {
    if (!(holidays && holidays.length)) {
        return {};
    }
    return users.reduce((result, user) => {
        const userHolidaysEvents = getUserHolidaysEvents(
            user,
            holidays,
            calendarStartDate,
            calendarEndDate
        );
        if (userHolidaysEvents) {
            result[user.gid_collaborateur] = userHolidaysEvents;
        }
        return result;
    }, {});
}
export function getWorkRythmEvents(users, workRythms, calendarStartDate, calendarEndDate) {
    if (!(workRythms && workRythms.length)) {
        return {};
    }
    return users.reduce((result, user) => {
        const userWorkRythmEvents = getUserWorkRythmEvents(
            user,
            workRythms,
            calendarStartDate,
            calendarEndDate
        );
        if (userWorkRythmEvents) {
            result[user.gid_collaborateur] = userWorkRythmEvents;
        }
        return result;
    }, {});
}
export function getDutyCallEvents(users, dutyCalls, calendarStartDate, calendarEndDate) {
    if (!(dutyCalls && dutyCalls.length)) {
        return {};
    }
    return users.reduce((result, user) => {
        const userDutyCallEvents = getUserDutyCallEvents(
            user,
            dutyCalls,
            calendarStartDate,
            calendarEndDate
        );
        if (userDutyCallEvents) {
            result[user.gid_collaborateur] = userDutyCallEvents;
        }
        return result;
    }, {});
}
export function getHighlightsEvents(unavPeriod, calendarStartDate, calendarEndDate) {
    const startDate = `${unavPeriod.startDate}T${unavPeriod.startTime}:00`;
    const endDate = `${unavPeriod.endDate}T${unavPeriod.endTime}:00`;

    let highlightEvents;

    if (!unavPeriod.periodicity) {
        const event = getEvent(startDate, endDate, undefined, calendarStartDate, calendarEndDate);
        highlightEvents = [event];
    } else {
        let hightLightDates;

        if (unavPeriod.periodicity.type === PERIODICITY_TYPE.WEEKLY) {
            const { numberWeeks } = unavPeriod.periodicity;
            const isoWeekDays = unavPeriod.periodicity.periodicityDays.map((day) => day + 1);

            hightLightDates = queryDateBetweenDates({
                startDate,
                endDate,
                timeUnit: 'weeks',
                interval: numberWeeks,
                isoWeekDays,
            });
        } else if (unavPeriod.periodicity.type === PERIODICITY_TYPE.DAILY) {
            const { isWorkingDay } = unavPeriod.periodicity;
            const numberDays = isWorkingDay ? 1 : unavPeriod.periodicity.numberDays;

            hightLightDates = queryDateBetweenDates({
                startDate,
                endDate,
                timeUnit: 'days',
                interval: numberDays,
                isWorkingDay,
            });
        }

        highlightEvents = hightLightDates.map((hightLightDate) =>
            getEvent(
                `${hightLightDate}T${unavPeriod.startTime}:00`,
                `${hightLightDate}T${unavPeriod.endTime}:00`,
                undefined,
                calendarStartDate,
                calendarEndDate
            )
        );
    }
    return groupEventsByDate(highlightEvents);
}

// AVAILABILITIES
function isUserAvailable(nowDate, userDayWorkRythmEvents, userUnavs) {
    if (userUnavs && userUnavs.length) {
        const totalUnavs = userUnavs.filter((unav) => unav.indisponibilite !== 'DEPANN');

        if (totalUnavs && totalUnavs.length) {
            const isForSureUnavailable = totalUnavs.some(
                (totalUnav) => totalUnav.statut !== STATUT_INDISPO.AVALIDER
            );
            return isForSureUnavailable
                ? AVAILABILITY.NOT_AVAILABLE
                : AVAILABILITY.MAY_BE_AVAILABLE;
        }
    }

    if (userDayWorkRythmEvents && userDayWorkRythmEvents.length) {
        const workRythmEvents = userDayWorkRythmEvents.filter(
            (workRythmEvent) =>
                moment(workRythmEvent.element.start_time).isSameOrBefore(nowDate) &&
                moment(workRythmEvent.element.end_time).isAfter(nowDate)
        );
        return workRythmEvents && workRythmEvents.length
            ? AVAILABILITY.IS_AVAILABLE
            : AVAILABILITY.NOT_AVAILABLE;
    }

    return AVAILABILITY.IS_AVAILABLE;
}
export function getAvailabilities(nowDate, users, workRythmEvents, unavs) {
    const isoDate = nowDate.format(ISO_DATE_FORMAT);
    return users.reduce((result, user, index) => {
        const userDayWorkRythmEvents = workRythmEvents[user.gid_collaborateur]
            ? workRythmEvents[user.gid_collaborateur][isoDate]
            : undefined;
        const userUnavs =
            unavs && unavs[index]
                ? unavs[index].filter(
                      (unav) =>
                          moment(unav.date_debut).isSameOrBefore(nowDate) &&
                          moment(unav.date_fin).isAfter(nowDate)
                  )
                : undefined;

        result[user.gid_collaborateur] = isUserAvailable(
            nowDate,
            userDayWorkRythmEvents,
            userUnavs
        );
        return result;
    }, {});
}

export default {
    getAvailabilities,
    getUnavs,
    getUnavEvents,
    getConstraintUnavEvents,
    getHolidayEvents,
    getWorkRythmEvents,
    getDutyCallEvents,
};
