export default {
    toast: {
        value: false,
        color: 'success',
        title: '',
        message: '',
        dismiss: true,
    },

    screenSize: 0,

    fatalErrors: [],

    isSpinnerDiplayed: false,

    onDemandUnavLoad: false,

    isCacheAllowed: true,
};
