import genLogger from '@cofely/genesis-logger';
import employeeApi from '@/api/employee';
import EdispoError from '@/services/errors/EdispoError.model';
import errorMessages from '@/constants/errorMessages';
import routesStateConstant from '@/constants/routes';
import {
    ROLES,
    ROLES_LIBS,
    OK,
    ADMIN,
    EXTERNAL,
    ORDO,
    DISPATCHEUR,
    ASS_EXP,
    N1,
    N2,
    RH,
    DELEGATOR,
    TECHNICIAN,
} from '@/constants/shared';

async function getEmployeeByGid(gid) {
    const employees = await employeeApi.getEmployees([gid]);
    if (employees && employees.length) {
        return employees[0];
    }
    throw new EdispoError({
        title: errorMessages.GET_SPECIFIC_EMPLOYEE.TITLE,
        message: errorMessages.GET_SPECIFIC_EMPLOYEE.MESSAGE,
    });
}

async function setLoggerUser(oktaAuth) {
    const oktaUser = await oktaAuth.getUser();
    genLogger.setUser({
        id: oktaUser.preferred_username.split('@')[0],
        name: oktaUser.name,
        email: oktaUser.email,
    });
}

function getRoles(employee) {
    const employeeRoles = ROLES.filter((role) => employee[role] === OK);
    if (employeeRoles.filter((role) => role !== ADMIN).length === 0) {
        employeeRoles.push(TECHNICIAN);
    }
    return employeeRoles;
}
function getRolesLib(roles) {
    return roles.map((role) => ROLES_LIBS[role]);
}

function isAdmin(employee) {
    return employee && employee[ADMIN] === OK;
}
function isExternal(employee) {
    return employee && employee[EXTERNAL] === OK;
}
function isTechnician(employee) {
    const roles = getRoles(employee);
    return roles.includes(TECHNICIAN);
}
function isOrdo(employee) {
    return employee && employee[ORDO] === OK;
}
function isDispatcheur(employee) {
    return employee && employee[DISPATCHEUR] === OK;
}
function isAssExp(employee) {
    return employee && employee[ASS_EXP] === OK;
}
function isN1(employee) {
    return employee && employee[N1] === OK;
}
function isN2(employee) {
    return employee && employee[N2] === OK;
}
function isRH(employee) {
    return employee && employee[RH] === OK;
}
function isDelegator(employee) {
    return employee && employee[DELEGATOR] === OK;
}

function getUserAuthorizedPages(roles) {
    const routes = [routesStateConstant.CALENDAR];
    if (!roles.includes(EXTERNAL)) {
        routes.push(routesStateConstant.MY_REQUESTS);
    }
    if (roles.includes(N1) || roles.includes(N2)) {
        routes.push(routesStateConstant.MY_VALIDATIONS);
    }
    if (roles.includes(N2) || roles.includes(RH)) {
        routes.push(routesStateConstant.RESCUE_VALIDATIONS);
    }
    if (roles.includes(RH)) {
        routes.push(routesStateConstant.EXTRACTION);
    }
    if (
        roles.includes(N1) ||
        roles.includes(N2) ||
        roles.includes(RH) ||
        roles.includes(DISPATCHEUR) ||
        roles.includes(ORDO) ||
        roles.includes(ASS_EXP)
    ) {
        routes.push(routesStateConstant.LAST_CHANGES);
    }
    return routes;
}

export default {
    getEmployeeByGid,

    setLoggerUser,

    getRolesLib,
    getRoles,

    isExternal,
    isTechnician,
    isAdmin,
    isOrdo,
    isDispatcheur,
    isAssExp,
    isN1,
    isN2,
    isRH,
    isDelegator,

    getUserAuthorizedPages,
};
