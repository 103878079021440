<template>
    <v-dialog
        @input="onValueChange"
        :value="isDisplayed"
        scrollable
        min-width="300"
        max-width="1000"
    >
        <v-card tile>
            <v-card-title>
                <span>
                    <v-icon large dark left>mdi-card-account-details</v-icon>
                    Mon profil : {{ employee.nom }} {{ employee.prenom }}
                </span>

                <v-spacer />

                <v-btn icon dark @click="onValueChange(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-row>
                                <v-col cols="12">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <ProfileSummarySection
                                                :employee="employee"
                                                :roles="roles"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row v-if="employee.solde_rtt !== null">
                                <v-col cols="12">
                                    <v-card tile height="100%">
                                        <v-card-text>
                                            <CounterBalanceSection
                                                :employee="employee"
                                                :colsNumber="2"
                                            />
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-card tile height="100%">
                                <v-card-text>
                                    <HierachySection :employee="employee" />
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import CounterBalanceSection from '@/components/sections/employee/CounterBalanceSection.vue';
import HierachySection from '@/components/sections/employee/HierachySection.vue';
import ProfileSummarySection from '@/components/sections/employee/ProfileSummarySection.vue';

export default {
    name: 'MyProfile',

    components: {
        CounterBalanceSection,
        HierachySection,
        ProfileSummarySection,
    },

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        employee: {
            type: Object,
            required: true,
        },
        roles: {
            type: Array,
            required: false,
            default: () => [],
        },
    },

    data() {
        return {
            isDisplayed: this.value,
        };
    },

    watch: {
        value(newVal) {
            this.isDisplayed = newVal;
        },
    },

    methods: {
        onValueChange(val) {
            this.$emit('input', val);
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-card__text {
    color: $black !important;
}
</style>
