function formatReasons(arrayReasons) {
    return arrayReasons.reduce((result, reason) => {
        result[reason.indisponibilite] = reason;
        return result;
    }, {});
}

export default {
    formatReasons,
};
