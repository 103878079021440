<template>
    <v-dialog @input="updateValue" :value="value" persistent max-width="600">
        <v-card tile>
            <v-card-title>
                <v-row no-gutters align="center" justify="center">
                    <v-icon x-large color="warning">mdi-alert</v-icon>
                </v-row>
            </v-card-title>

            <v-card-text>
                <span v-if="conflictedElement === CONFLICT_ELEMENTS.RECIPIENT_CONFLICT">
                    Vous ne pouvez pas déclarer dans le passé pour le ou les bénéficiaires
                    sélectioné(s). En continuant, vous devrez sélectionner des nouveaux
                    bénéficiaires.
                </span>

                <span v-if="conflictedElement === CONFLICT_ELEMENTS.SPECIFIC_HOURS_CONFLICT">
                    Le motif choisi ne peut être déclaré à l'heure. En continuant, vous devrez
                    sélectionner un nouveau motif.
                </span>

                <span v-if="conflictedElement === CONFLICT_ELEMENTS.VALIDATION_CONFLICT">
                    Le motif choisi n'est pas éligible à la périodicité. En continuant, vous devrez
                    sélectionner un nouveau motif.
                </span>

                <span v-if="conflictedElement === CONFLICT_ELEMENTS.UNAVAILABLE_REASON_CONFLICT">
                    Le ou les bénéficiaires sélectioné(s) n'ont pas droit au motif choisi. En
                    continuant, vous devrez sélectionner un nouveau motif.
                </span>

                <span v-if="conflictedElement === CONFLICT_ELEMENTS.CONSTRAINT_CONFLICT">
                    Les motifs de type contrainte ne sont pas éligibles à la périodicité. En
                    continuant, vous devrez sélectionner un nouveau motif.
                </span>
            </v-card-text>

            <v-card-actions>
                <v-spacer />

                <v-btn tile large outlined @click="updateValue(false)">Annuler</v-btn>

                <v-btn tile large outlined @click="confirmAction()">Continuer</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { CONFLICT_ELEMENTS } from '@/constants/addUnavailability';

export default {
    name: 'ConflictConfirmationModal',

    props: {
        value: {
            type: Boolean,
            required: true,
        },
        conflictedElement: {
            type: String,
            required: false,
            default: '',
        },
    },

    data() {
        return {
            CONFLICT_ELEMENTS,
        };
    },

    methods: {
        confirmAction() {
            this.$emit('confirm-conflict-action', this.conflictedElement);
            this.updateValue(false);
        },
        updateValue(val) {
            this.$emit('input', !!val);
        },
    },
};
</script>
