import moment from 'moment';
import { oldApiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import { ISO_DATE_TIME_FORMAT } from '@/constants/dateFormat';
import errorMessages from '@/constants/errorMessages';

const fileName = 'dutyCall.api.js';

async function getDutyCalls(rawEmployeesGids, start, end) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const startDate = moment(start).startOf('d').format(ISO_DATE_TIME_FORMAT);
        const endDate = moment(end).endOf('d').format(ISO_DATE_TIME_FORMAT);

        const response = await oldApiClient.post('standby_time/search', {
            employees: formattedUsersGids,
            period: [
                {
                    start_date: startDate,
                    end_date: endDate,
                },
            ],
        });
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.DUTY_CALLS, fileName));
    }
}

export default {
    getDutyCalls,
};
