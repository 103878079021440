<template>
    <v-menu
        offset-y
        nudge-right="10"
        min-width="auto"
        transition="scale-transition"
        ref="textDatePickerMenu"
        :close-on-content-click="false"
        :return-value.sync="selectedDate"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                readonly
                outlined
                :value="formattedDate"
                :rules="[isValid]"
                v-bind="$attrs"
                v-on="on"
                @input="saveDate(null)"
            ></v-text-field>
        </template>

        <v-date-picker
            no-title
            locale="fr-FR"
            first-day-of-week="1"
            :min="minVal"
            :max="maxVal"
            v-model="selectedDate"
            @input="saveDate(selectedDate)"
        ></v-date-picker>
    </v-menu>
</template>

<script>
import moment from 'moment';
import { DATE_FORMAT, ISO_DATE_FORMAT } from '@/constants/dateFormat';

export default {
    inheritAttrs: false,

    name: 'TextDatePicker',

    props: {
        value: {
            type: String,
            required: true,
        },
        max: {
            type: String,
            required: false,
            default: null,
        },
        min: {
            type: String,
            required: false,
            default: null,
        },
        mandatory: {
            required: false,
            type: Boolean,
            default: false,
        },
        returnFormat: {
            required: false,
            type: String,
            default: ISO_DATE_FORMAT,
        },
    },

    data() {
        return {
            selectedDate: '',
            minVal: '',
            maxVal: '',
        };
    },

    created() {
        this.selectedDate = this.formatToIso(this.value);
        this.minVal = this.formatToIso(this.min);
        this.maxVal = this.formatToIso(this.max);
    },

    watch: {
        value(newVal) {
            this.selectedDate = this.formatToIso(newVal);
        },
        min(newVal) {
            this.minVal = this.formatToIso(newVal);
        },
        max(newVal) {
            this.maxVal = this.formatToIso(newVal);
        },
    },

    computed: {
        returnedDate() {
            return this.formatToReturnFormat(this.selectedDate);
        },
        formattedDate() {
            return this.returnedDate
                ? moment(this.returnedDate, this.returnFormat).format(DATE_FORMAT)
                : null;
        },
    },

    methods: {
        formatToIso(date) {
            return date ? moment(date, this.returnFormat).format(ISO_DATE_FORMAT) : '';
        },
        formatToReturnFormat(date) {
            return date ? moment(date, ISO_DATE_FORMAT).format(this.returnFormat) : '';
        },
        saveDate(date) {
            this.selectedDate = date;
            this.$emit('input', this.returnedDate);
            this.$refs.textDatePickerMenu.save(date);
        },
        isValid(date) {
            return this.mandatory ? !!date || 'La date est obligatoire' : true;
        },
    },
};
</script>
