module.exports = {
    NODE_ENV: 'production',
    ISSUER: 'https://engie.okta-emea.com',
    CLIENT_ID: '0oab0ilgzcJzy7X6X0i7',
    API_BASE_URL: 'https://apis.svc.engie-solutions.fr/availabilities/api/v2',
    OLD_API_BASE_URL: 'https://apis.svc.engie-solutions.fr/availabilities/api/v1',
    ASSETS_BASE_URL: 'https://apis.svc.engie-solutions.fr/assets/api/v2',
    GETFEEDBACK_BUTTON_MOB: '8223faff5cc4',
    GETFEEDBACK_BUTTON_WEB: '4e6c8a2a1256',
};
