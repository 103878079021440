import Vue from 'vue';
import VueRouter from 'vue-router';
import { OktaAuth } from '@okta/okta-auth-js';
import OktaVue from '@okta/okta-vue';

import store from '@/store';
import routesStateConstant from '@/constants/routes';
import CalendarPage from '@/pages/CalendarPage.vue';
import getFeedbackService from '@/services/getFeedback';
import config from '../../env/env-config';

const envConfig = config.getEnvConfig();

Vue.use(VueRouter);

const routes = [
    {
        path: routesStateConstant.MY_REQUESTS.url,
        name: routesStateConstant.MY_REQUESTS.name,
        component: () => import('@/pages/MyRequestsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesStateConstant.MY_VALIDATIONS.url,
        name: routesStateConstant.MY_VALIDATIONS.name,
        component: () => import('@/pages/MyValidationsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesStateConstant.RESCUE_VALIDATIONS.url,
        name: routesStateConstant.RESCUE_VALIDATIONS.name,
        component: () => import('@/pages/RescueValidationsPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesStateConstant.EXTRACTION.url,
        name: routesStateConstant.EXTRACTION.name,
        component: () => import('@/pages/ExtractionPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: routesStateConstant.LAST_CHANGES.url,
        name: routesStateConstant.LAST_CHANGES.name,
        component: () => import('@/pages/LastChangesPage.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/implicit/callback',
        component: () => import('@/pages/ImplicitCallback.vue'),
    },
    {
        path: routesStateConstant.CALENDAR.url,
        name: routesStateConstant.CALENDAR.name,
        component: CalendarPage,
        meta: {
            requiresAuth: true,
        },
    },
    { path: '*', redirect: { name: routesStateConstant.CALENDAR.name } },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const oktaAuth = new OktaAuth({
    issuer: envConfig.oktaIssuer,
    clientId: envConfig.oktaClientId,
    redirectUri: `${window.location.origin}/implicit/callback`,
    pkce: false,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    responseMode: 'fragment',
    tokenManager: {
        storage: 'sessionStorage',
    },
});
Vue.use(OktaVue, { oktaAuth });

router.beforeEach(async (to, from, next) => {
    if (await Vue.prototype.$auth.isAuthenticated()) {
        await store.dispatch('employee/loadEmployee');
        await store.dispatch('checkPageAuthorization', to.name);
    }
    next();
});
router.afterEach(() => {
    getFeedbackService.notifyRouterNavigation();
});

export default router;
