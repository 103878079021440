function sortByEntityLvl(a, b) {
    if (a.lib_niv_entite && !b.lib_niv_entite) {
        return -1;
    }
    if (!a.lib_niv_entite && b.lib_niv_entite) {
        return 1;
    }
    return a.label.localeCompare(b.label);
}

function fromEntityToTreeNode(entity) {
    const { children, ...data } = entity;
    const perimeterTree = {
        ...data,
        label: data.prenom ? `${data.nom} ${data.prenom}` : `${data.id} ${data.nom}`,
    };

    if (children) {
        perimeterTree.children = children.map((childEntity) => fromEntityToTreeNode(childEntity));
        perimeterTree.children.sort((a, b) => sortByEntityLvl(a, b));
    } else {
        perimeterTree.gid_collaborateur = data.gid_collaborateur;
    }

    return perimeterTree;
}

function formatPerimeterPeople(people) {
    return people.reduce((result, person) => {
        result[person.gid_collaborateur] = person;
        return result;
    }, {});
}
function collectEntities(entity, array) {
    if (entity.children) {
        array.push({
            id: entity.id,
            nom: `${entity.id} ${entity.nom}`,
            niveau: entity.lib_niv_entite,
        });
        entity.children.forEach((item) => {
            collectEntities(item, array);
        });
    }
}
function collectPeople(entity, array) {
    if (!entity.children) {
        array.push({
            gid_collaborateur: entity.gid_collaborateur,
            code_entite: entity.code_entite,
            nom: entity.nom,
            prenom: entity.prenom,
        });
    } else {
        entity.children.forEach((item) => {
            collectPeople(item, array);
        });
    }
}

function getPerimeterTree(perimeter) {
    return perimeter.map((entity) => fromEntityToTreeNode(entity));
}
function getPerimeterEntities(perimeter) {
    const entities = [];
    perimeter.forEach((entity) => {
        collectEntities(entity, entities);
    });
    entities.sort((a, b) => a.nom.localeCompare(b.nom));
    return entities;
}
function getPerimeterPeople(perimeter) {
    const people = [];
    perimeter.forEach((entity) => {
        collectPeople(entity, people);
    });
    return people
        .sort((a, b) => a.nom.localeCompare(b.nom))
        .filter(
            (item, pos, array) =>
                !pos || item.gid_collaborateur !== array[pos - 1].gid_collaborateur
        );
}

export default {
    getPerimeterTree,
    getPerimeterEntities,
    getPerimeterPeople,
    formatPerimeterPeople,
};
