import employeeApi from '@/api/employee';
import { setImpersonatingGid } from '@/api/client';
import localCacheService from '@/services/localCache';
import employeeService from '@/services/employee';
import { CACHE_SUPERVISION_KEY } from '@/constants/localCache';

const fileName = 'employee.store';

const state = {
    employee: {},
    admin: {},
};

const getters = {
    isSupervising: (state) =>
        state.admin && state.admin.gid_collaborateur
            ? state.employee.gid_collaborateur !== state.admin.gid_collaborateur
            : false,
    isExternal: (state) => (state.employee ? employeeService.isExternal(state.employee) : true),
    isAdmin: (state) =>
        state.admin && state.admin.gid_collaborateur
            ? true
            : employeeService.isAdmin(state.employee),
    isOrdo: (state) => employeeService.isOrdo(state.employee),
    isDispatcheur: (state) => employeeService.isDispatcheur(state.employee),
    isAssExp: (state) => employeeService.isAssExp(state.employee),
    isN1: (state) => employeeService.isN1(state.employee),
    isN2: (state) => employeeService.isN2(state.employee),
    isRH: (state) => employeeService.isRH(state.employee),
    roles: (state) => employeeService.getRoles(state.employee),
};

const mutations = {
    setEmployee(state, employee) {
        state.employee = employee;
    },

    setAdmin(state, admin) {
        state.admin = admin;
    },
};

const actions = {
    async loadEmployee({ commit, state }) {
        if (!state.employee.gid_collaborateur) {
            try {
                const supervision = localCacheService.getCacheElement(CACHE_SUPERVISION_KEY);
                if (supervision && supervision.admin && supervision.employee) {
                    commit('setAdmin', supervision.admin);
                    commit('setEmployee', supervision.employee);
                    setImpersonatingGid(supervision.employee.gid_collaborateur);
                } else {
                    const employee = await employeeApi.getEmployee();
                    commit('setEmployee', employee);
                }
            } catch (error) {
                commit('addFatalError', { error, fileName }, { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
