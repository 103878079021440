<template>
    <v-dialog
        persistent
        scrollable
        max-width="1080px"
        :value="isDisplayed"
        @input="setAddUnavailabilityDisplay"
    >
        <v-card v-if="isResulManagementDisplayed">
            <v-card-title>
                <v-row no-gutters align="center" justify="center">
                    <span>
                        En soumettant cette série d'indisponibilités, les occurrences suivantes
                        n'ont pas été créées.
                    </span>
                </v-row>
            </v-card-title>

            <v-card-text>
                <ConflictTable
                    :invalidUnavailabilities="failedUnavs"
                    :recipients="recipients"
                    :reason="reason"
                />
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters align="center" justify="end">
                    <v-btn tile depressed @click="closeModal()" class="primary">OK</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>

        <v-card max-height="700" v-else>
            <v-card-title>
                <span class="d-flex align-center">
                    <v-icon large class="mr-3">
                        {{ isEditing ? 'mdi-pencil' : 'mdi-plus' }}
                    </v-icon>
                    {{ isEditing ? "Modification d'indisponibilité" : 'Nouvelle indisponibilité' }}
                </span>

                <v-spacer />

                <v-btn icon @click="setAddUnavailabilityDisplay(false)">
                    <v-icon large>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <Spinner v-if="isSpinnerDisplayed" />

            <template v-if="steps && steps.length">
                <v-card-text>
                    <v-stepper
                        tile
                        flat
                        :value="currentStepNbr"
                        @change="onStepChange"
                        min-height="350"
                    >
                        <v-stepper-header>
                            <template v-for="(step, index) in steps">
                                <v-stepper-step
                                    :key="`${index}-step`"
                                    :complete="currentStepNbr > index + 1"
                                    :editable="currentStepNbr > index + 1"
                                    :step="index + 1"
                                >
                                    {{ step.lib }}
                                </v-stepper-step>
                                <v-divider
                                    v-if="index !== steps.length - 1"
                                    :key="`${index}-div`"
                                />
                            </template>
                        </v-stepper-header>

                        <v-stepper-items>
                            <template v-for="(step, index) in steps">
                                <v-stepper-content :key="`${index}-content`" :step="index + 1">
                                    <DateStep
                                        v-if="step.id === dateStep.id"
                                        :hasDelegates="hasDelegates"
                                        :isN1N2Rh="isN1N2Rh"
                                        @update-period="onCalendarPeriodUpdate"
                                    />

                                    <RecipientsStep
                                        v-if="step.id === recipientStep.id"
                                        :isInThePast="isInThePast"
                                        :eligibleRecipients="eligibleRecipients"
                                    />

                                    <ReasonStep
                                        v-if="step.id === reasonStep.id"
                                        :isSpecificHours="isSpecificHours"
                                        :isPeriodic="isPeriodic"
                                        :hasRightToSelectConstraints="hasRightToSelectConstraints"
                                        :commonReasons="commonReasons"
                                        :roles="roles"
                                    />

                                    <template v-if="step.id === summaryStep.id">
                                        <SummaryStep
                                            v-if="unavPeriod && recipientsGids.length && reason"
                                            :employee="employee"
                                            :unavPrevisions="unavPrevisions"
                                            @unav-modification="onUnavModification()"
                                        />
                                        <FatalStep v-else />
                                    </template>
                                </v-stepper-content>
                            </template>
                        </v-stepper-items>
                    </v-stepper>

                    <CalendarView
                        v-if="calendarPeriod && calendarFilters"
                        class="my-2"
                        readOnly
                        :filters="calendarFilters"
                        :period="calendarPeriod"
                        :highlightPeriod="unavPeriod"
                        :displayedUsers="recipients"
                        :onDemandUnavLoad.sync="onDemandUnavLoad"
                    />
                </v-card-text>

                <v-card-actions>
                    <v-btn
                        class="primary-subtle"
                        tile
                        large
                        depressed
                        :ripple="false"
                        :disabled="currentStepNbr <= 1"
                        @click="previousStep()"
                    >
                        Précédent
                    </v-btn>

                    <v-spacer />

                    <v-btn
                        class="secondary"
                        tile
                        large
                        depressed
                        :ripple="false"
                        @click="setAddUnavailabilityDisplay(false)"
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        class="ml-3 primary"
                        tile
                        large
                        depressed
                        :ripple="false"
                        :loading="isLoading"
                        :disabled="!steps[currentPosition].isValid() || hasConflict"
                        @click="nextStep()"
                    >
                        {{ nextLabel }}
                    </v-btn>
                </v-card-actions>
            </template>
        </v-card>

        <ConflictConfirmationModal
            v-if="isConflictModalDisplayed"
            v-model="isConflictModalDisplayed"
            :conflictedElement="conflicts[0]"
            @confirm-conflict-action="onConflictActionConfirm"
        />
    </v-dialog>
</template>

<script>
import { mapGetters, createNamespacedHelpers, mapMutations } from 'vuex';
import moment from 'moment';

import unavailabilityReasonsApi from '@/api/unavailabilityReasons';
import employeeApi from '@/api/employee';
import addUnavailabilityApi from '@/api/addUnavailability';

import unavailabilitiesService from '@/services/unavailabilities';
import addUnavailabilityService from '@/services/addUnavailability';
import EdispoError from '@/services/errors/EdispoError.model';

import { CONFLICT_ELEMENTS, UNAV_PERIOD_TYPE, REASON_TYPE } from '@/constants/addUnavailability';
import { ISO_DATE_TIME_FORMAT } from '@/constants/dateFormat';
import { OK, N1, N2, NO, RH, CAN_DECLARE_CONSTRAINT } from '@/constants/shared';
import { ADD_UNAVAILABILITY } from '@/constants/succesMessages';
import errorMessages from '@/constants/errorMessages';
import { STATUT_INDISPO } from '@/constants/unavailabilities';

import CalendarView from '@/components/calendar/CalendarView.vue';
import DateStep from '@/components/addUnavailability/dateStep/DateStep.vue';
import ReasonStep from '@/components/addUnavailability/reasonStep/ReasonStep.vue';
import RecipientsStep from '@/components/addUnavailability/RecipientsStep.vue';
import SummaryStep from '@/components/addUnavailability/lastStep/SummaryStep.vue';
import FatalStep from '@/components/addUnavailability/lastStep/FatalStep.vue';
import ConflictConfirmationModal from '@/components/addUnavailability/conflicts/ConflictConfirmationModal.vue';
import ConflictTable from '@/components/addUnavailability/conflicts/ConflictTable.vue';
import Spinner from '@/components/Spinner.vue';

const employeeNameSpace = createNamespacedHelpers('employee');
const employeeStore = {
    mapState: employeeNameSpace.mapState,
    mapGetters: employeeNameSpace.mapGetters,
    mapActions: employeeNameSpace.mapActions,
};

const addUnavNameSpace = createNamespacedHelpers('addUnavailability');
const addUnavStore = {
    mapState: addUnavNameSpace.mapState,
    mapMutations: addUnavNameSpace.mapMutations,
};

const fileName = 'AddUnavailability.vue';

export default {
    name: 'AddUnavailability',

    components: {
        CalendarView,
        DateStep,
        ReasonStep,
        RecipientsStep,
        SummaryStep,
        ConflictConfirmationModal,
        ConflictTable,
        FatalStep,
        Spinner,
    },

    data() {
        return {
            isSpinnerDisplayed: false,
            isLoading: false,

            conflictedElement: '',
            isConflictModalDisplayed: false,
            isResulManagementDisplayed: false,

            onDemandUnavLoad: false,

            commonReasons: [],
            eligibleRecipients: [],
            unavPrevisions: undefined,
            unavResults: undefined,

            calendarPeriod: undefined,
            calendarFilters: {
                isWorkRythmDisplayed: false,
                isDutyCallDisplayed: false,
                isConstraintsDisplayed: true,
                displayedZones: [],
            },

            currentStepNbr: 1,
            steps: [],

            dateStep: {
                id: 0,
                lib: 'Date',
                isValid: () => !!this.unavPeriod,
            },
            recipientStep: {
                id: 1,
                lib: 'Bénéficiaires',
                isValid: () => this.recipients && this.recipients.length > 0,
            },
            reasonStep: {
                id: 2,
                lib: 'Motif',
                isValid: () =>
                    this.unavReason.reason && this.unavReason.constraint
                        ? this.unavReason.constraint.isValid
                        : !!this.unavReason.reason,
            },
            summaryStep: {
                id: 3,
                lib: 'Récapitulatif',
                isValid: () =>
                    this.unavPrevisions &&
                    this.unavPrevisions.invalid_unavailabilities &&
                    (this.unavPrevisions.invalid_unavailabilities.length === 0 ||
                        this.isPeriodic) &&
                    !this.unavPrevisions.cancellation_failure_reason,
            },
        };
    },

    computed: {
        // STORE RELATED
        ...mapGetters(['isScreenXS', 'isScreenSM', 'isScreenMD', 'isScreenLG', 'isScreenXL']),
        ...employeeStore.mapState(['employee']),
        ...employeeStore.mapGetters(['roles']),
        ...addUnavStore.mapState([
            'isDisplayed',
            'isEditing',
            'unavailability',
            'unavPeriod',
            'recipients',
            'unavReason',
            'comment',
        ]),
        ...addUnavStore.mapState({
            reason: (state) => state.unavReason.reason,
            constraint: (state) => state.unavReason.constraint,
        }),

        // EMPLOYEE RELATED
        isN1N2Rh() {
            return this.roles.some((role) => role === N1 || role === N2 || role === RH);
        },
        hasDelegates() {
            return this.eligibleRecipients && this.eligibleRecipients.length > 0;
        },
        hasRightToSelectConstraints() {
            return this.employee && this.employee[CAN_DECLARE_CONSTRAINT] === OK;
        },

        // MODAL UTILS
        currentPosition() {
            return this.currentStepNbr - 1;
        },
        nextLabel() {
            if (this.steps[this.currentPosition].id === this.summaryStep.id) {
                return this.isEditing ? 'Mettre à jour' : 'Soumettre';
            }
            return 'Suivant';
        },
        conflicts() {
            const conflicts = [];
            if (this.hasRecipientConflict()) {
                conflicts.push(CONFLICT_ELEMENTS.RECIPIENT_CONFLICT);
            }
            if (this.hasSpecificHoursConflict()) {
                conflicts.push(CONFLICT_ELEMENTS.SPECIFIC_HOURS_CONFLICT);
            }
            if (this.hasValidationConflict()) {
                conflicts.push(CONFLICT_ELEMENTS.VALIDATION_CONFLICT);
            }
            if (this.hasUnavailableReasonConflict()) {
                conflicts.push(CONFLICT_ELEMENTS.UNAVAILABLE_REASON_CONFLICT);
            }
            if (this.hasConstraintConflict()) {
                conflicts.push(CONFLICT_ELEMENTS.CONSTRAINT_CONFLICT);
            }
            return conflicts;
        },
        hasConflict() {
            return this.conflictsLength > 0;
        },
        conflictsLength() {
            return this.conflicts ? this.conflicts.length : 0;
        },

        // DATE RELATED
        isPeriodic() {
            return this.unavPeriod && !!this.unavPeriod.periodicity;
        },
        isSpecificHours() {
            return this.unavPeriod && this.unavPeriod.type === UNAV_PERIOD_TYPE.SPECIFIC_HOURS;
        },
        isInThePast() {
            return moment(
                `${this.unavPeriod.endDate}T${this.unavPeriod.endTime}:00`,
                ISO_DATE_TIME_FORMAT
            ).isSameOrBefore(moment());
        },

        // RECIPIENTS RELATED
        isCurrentUserSelected() {
            return this.recipientsGids.includes(this.employee.gid_collaborateur);
        },
        recipientsGids() {
            return this.recipients && this.recipients.length
                ? this.recipients.map((recipient) => recipient.gid_collaborateur)
                : [];
        },

        // RESULTS RELATED
        failedUnavs() {
            return this.unavResults && this.unavResults.failed_unavailabilities
                ? this.unavResults.failed_unavailabilities
                : [];
        },
    },

    async created() {
        this.showSpinner();
        await this.loadEmployee();
        await this.loadEligibleRecipients();

        if (this.isEditing) {
            await this.loadUnavProperties();
        }

        this.steps.push(this.dateStep);
        if (this.hasDelegates) {
            this.steps.push(this.recipientStep);
        } else if (!this.isEditing) {
            this.setRecipients([this.employee]);
        }
        this.steps.push(this.reasonStep);
        this.steps.push(this.summaryStep);

        this.hideSpinner();
    },

    watch: {
        conflictsLength(newVal, oldVal) {
            if (!!newVal && newVal !== oldVal) {
                this.showConflictModal();
            } else {
                this.$nextTick(() => {
                    this.hideConflictModal();
                });
            }
        },
    },

    methods: {
        ...mapMutations(['showErrorToast', 'showToast', 'setOnDemandUnavLoad']),
        ...employeeStore.mapActions(['loadEmployee']),
        ...addUnavStore.mapMutations([
            'setAddUnavailabilityDisplay',
            'setUnavPeriod',
            'setRecipients',
            'initializeUnavReason',
            'initializeConstraint',
            'setUnavReason',
            'setComment',
        ]),

        // EVENTS
        onUnavModification() {
            this.onStepChange(this.steps.length);
            this.loadCalendarUnavs();
        },
        async onStepChange(newStepNbr) {
            const comingPosition = newStepNbr - 1;
            const comingStep = this.steps[comingPosition];
            switch (comingStep.id) {
                case this.dateStep.id:
                    break;
                case this.recipientStep.id:
                    this.updateEligibleRecipients();
                    break;
                case this.reasonStep.id:
                    await this.loadCommonReasons();
                    break;
                case this.summaryStep.id:
                    await this.loadUnavPrevisions();
                    break;
                default:
                    break;
            }
            this.currentStepNbr = newStepNbr;
        },
        onConflictActionConfirm(conflictedElement) {
            if (conflictedElement === CONFLICT_ELEMENTS.RECIPIENT_CONFLICT) {
                this.setRecipients([]);
            } else {
                this.initializeUnavReason();
            }
        },
        onCalendarPeriodUpdate(newPeriod) {
            this.calendarPeriod = newPeriod;
        },

        // CLUTCH
        async loadUnavProperties() {
            const unavPeriod = unavailabilitiesService.getUnavPeriod(this.unavailability);
            this.setUnavPeriod(unavPeriod);

            if (this.hasDelegates) {
                this.updateEligibleRecipients();
                const recipients = this.eligibleRecipients.filter(
                    (eligibleRecipient) =>
                        eligibleRecipient.gid_collaborateur ===
                        this.unavailability.gid_collaborateur
                );
                this.setRecipients(recipients);
            } else {
                this.setRecipients([this.employee]);
            }

            await this.loadCommonReasons();
            const reason = this.commonReasons.find(
                (commonReason) =>
                    commonReason.indisponibilite === this.unavailability.indisponibilite
            );
            const constraint = unavailabilitiesService.getConstraint(this.unavailability);
            const reasonType = constraint ? REASON_TYPE.CONSTRAINT : REASON_TYPE.HR;
            this.setUnavReason({
                type: reasonType,
                reason,
                constraint,
            });

            const comment = this.unavailability.commentaire_declarant;
            this.setComment(comment);

            this.unavPrevisions = { invalid_unavailabilities: [] };
        },
        async nextStep() {
            if (this.currentStepNbr >= this.steps.length) {
                this.submitUnav();
            } else {
                this.onStepChange(this.currentStepNbr + 1);
            }
        },
        previousStep() {
            if (this.currentStepNbr <= 1) {
                this.setAddUnavailabilityDisplay(false);
            } else {
                this.onStepChange(this.currentStepNbr - 1);
            }
        },
        updateEligibleRecipients() {
            const newEmployee = {
                ...this.employee,
                disabled: this.isInThePast,
            };
            if (this.eligibleRecipients[0].gid_collaborateur !== this.employee.gid_collaborateur) {
                this.eligibleRecipients.unshift(newEmployee);
            } else {
                this.eligibleRecipients.splice(0, 1, newEmployee);
            }
        },
        closeModal() {
            this.hideResultManagementModal();
            this.setOnDemandUnavLoad(true);
            this.setAddUnavailabilityDisplay(false);
        },

        // API RELATED
        async submitUnav() {
            this.unavResults = undefined;
            try {
                this.showLoadingNextBtn();
                const addUnavPayload = this.getAddUnavPayload();

                if (this.isEditing) {
                    this.unavResults = await addUnavailabilityApi.replaceUnavailability(
                        this.unavailability.id,
                        addUnavPayload
                    );
                } else {
                    this.unavResults = await addUnavailabilityApi.createUnavailability(
                        addUnavPayload
                    );
                }
                this.hideLoadingNextBtn();
                this.notifyResults();
            } catch (error) {
                this.hideLoadingNextBtn();
                this.showErrorToast({ error, fileName });
                this.closeModal();
            }
        },
        async loadUnavPrevisions() {
            this.unavPrevisions = undefined;
            if (
                this.unavPeriod &&
                this.recipientsGids &&
                this.recipientsGids.length &&
                this.reason
            ) {
                try {
                    this.showSpinner();
                    const addUnavPayload = this.getAddUnavPayload();

                    if (this.isEditing) {
                        this.unavPrevisions =
                            await addUnavailabilityApi.unavailabilityReplacementCheck(
                                this.unavailability.id,
                                addUnavPayload
                            );
                    } else {
                        this.unavPrevisions =
                            await addUnavailabilityApi.unavailabilityCreationCheck(addUnavPayload);
                    }

                    this.hideSpinner();
                } catch (error) {
                    this.hideSpinner();
                    this.showErrorToast({ error, fileName });
                }
            }
        },
        async loadCommonReasons() {
            if (this.recipientsGids && this.recipientsGids.length) {
                try {
                    this.showSpinner();
                    this.commonReasons =
                        await unavailabilityReasonsApi.getCommonUnavailabilityReasons(
                            this.recipientsGids
                        );
                    this.hideSpinner();
                } catch (error) {
                    this.hideSpinner();
                    this.showErrorToast({ error, fileName });
                }
            }
        },
        async loadEligibleRecipients() {
            try {
                this.eligibleRecipients = await employeeApi.getEligibleRecipients();
            } catch (error) {
                this.showErrorToast({ error, fileName });
            }
        },

        // VALIDATIONS
        hasRecipientConflict() {
            return this.isInThePast && (this.isCurrentUserSelected || !this.isN1N2Rh);
        },
        hasUnavailableReasonConflict() {
            return this.reason &&
                this.reason.indisponibilite &&
                this.commonReasons &&
                this.commonReasons.length
                ? !this.commonReasons.some(
                      (reason) => reason.indisponibilite === this.reason.indisponibilite
                  )
                : false;
        },
        hasSpecificHoursConflict() {
            return this.reason && this.reason.indisponibilite
                ? this.isSpecificHours &&
                      (this.reason.fl_abs_jour !== NO || this.reason.fl_decompte !== null)
                : false;
        },
        hasValidationConflict() {
            return this.reason && this.reason.indisponibilite
                ? this.isPeriodic && this.reason.validation_needed !== NO
                : false;
        },
        hasConstraintConflict() {
            return this.reason && this.reason.indisponibilite
                ? this.isPeriodic && this.reason.is_constraint !== NO
                : false;
        },

        // NOTIFICATION HELPERS
        notifyResults() {
            const isCorrect =
                this.unavResults.created_unavailabilities &&
                this.unavResults.created_unavailabilities.length &&
                this.unavResults.failed_unavailabilities &&
                this.unavResults.failed_unavailabilities.length === 0 &&
                !this.unavResults.cancellation_failure_reason;

            if (isCorrect) {
                this.notifySuccess();
                this.closeModal();
            } else if (this.isPeriodic) {
                this.showResultManagementModal();
            } else {
                throw new EdispoError({
                    title: this.isEditing
                        ? errorMessages.EDIT_UNAVAILABILITY.TITLE
                        : errorMessages.CREATE_UNAVAILABILITY.TITLE,
                    message: this.isEditing
                        ? errorMessages.EDIT_UNAVAILABILITY.MESSAGE
                        : errorMessages.CREATE_UNAVAILABILITY.MESSAGE,
                    fileName,
                    stack: `Erreur de ${
                        this.isEditing ? 'modification' : 'création'
                    } d'indisponibilités: ${this.unavResults.failed_unavailabilities}`,
                });
            }
        },
        notifySuccess() {
            const isSingleAdd = this.unavResults.created_unavailabilities.length === 1;
            const { statut } = this.unavResults.created_unavailabilities[0];

            let message;
            if (this.isEditing) {
                message = isSingleAdd
                    ? ADD_UNAVAILABILITY.SINGLE_REPLACE
                    : ADD_UNAVAILABILITY.MULTIPLE_REPLACE;
            } else if (statut === STATUT_INDISPO.VALIDEE) {
                message = isSingleAdd
                    ? ADD_UNAVAILABILITY.SINGLE_VALIDATION_RAPIDE
                    : ADD_UNAVAILABILITY.MULTIPLE_VALIDATION_RAPIDE;
            } else if (statut === STATUT_INDISPO.AVALIDER) {
                message = isSingleAdd
                    ? ADD_UNAVAILABILITY.SINGLE_SUBMISSION
                    : ADD_UNAVAILABILITY.MULTIPLE_SUBMISSION;
            } else {
                message = isSingleAdd
                    ? ADD_UNAVAILABILITY.SINGLE_CREATE
                    : ADD_UNAVAILABILITY.MULTIPLE_CREATE;
            }

            this.showToast({
                title: message.TITLE,
                message: message.MESSAGE,
                dismiss: false,
            });
        },

        // HELPERS
        getAddUnavPayload() {
            return addUnavailabilityService.buildAddUnavPayload(
                this.unavPeriod,
                this.recipientsGids,
                this.reason,
                this.constraint,
                this.comment
            );
        },
        showResultManagementModal() {
            this.isResulManagementDisplayed = true;
        },
        hideResultManagementModal() {
            this.isResulManagementDisplayed = false;
        },
        loadCalendarUnavs() {
            this.onDemandUnavLoad = true;
        },
        showLoadingNextBtn() {
            this.isLoading = true;
        },
        hideLoadingNextBtn() {
            this.isLoading = false;
        },
        showConflictModal() {
            this.isConflictModalDisplayed = true;
        },
        hideConflictModal() {
            this.isConflictModalDisplayed = false;
        },
        showSpinner() {
            this.isSpinnerDisplayed = true;
        },
        hideSpinner() {
            this.isSpinnerDisplayed = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';

::v-deep .v-tab {
    background-color: $very-light-gray;
}
::v-deep .active-tab {
    color: $white;
    background-color: var(--v-accent-base);
}

::v-deep .el-input__inner {
    width: 100px;
}

::v-deep .v-stepper__header {
    box-shadow: none;
}
::v-deep .v-stepper__content {
    padding: 0;
}

::v-deep .v-card__text {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
}

::v-deep .v-dialog > .v-card > .v-card__text {
    padding: 16px 32px 16px 32px;
}
::v-deep .v-dialog > .v-card > .v-card__actions {
    padding: 24px 32px 24px 32px;
}
</style>
