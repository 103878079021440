import { formatToIsoStringStartOfDay, formatToIsoStringEndOfDay } from '@/services/shared';
import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'unavailabilities.api.js';

async function getUnavailability(unavailabilityId) {
    try {
        const response = await apiClient.get(`unavailabilities/${unavailabilityId}`);
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.UNAVAILABILITY_BY_ID, fileName));
    }
}

async function searchUnavailabilities(rawEmployeesGids, start, end) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const startDate = formatToIsoStringStartOfDay(start);
        const endDate = formatToIsoStringEndOfDay(end);

        const response = await apiClient.post('unavailabilities/search', {
            employees: formattedUsersGids,
            period: [
                {
                    start_date: startDate,
                    end_date: endDate,
                },
            ],
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.UNAVAILABILITIES, fileName));
    }
}

async function updateUnavailability(unavailabilityId, statut, comment) {
    try {
        const response = await apiClient.put(`unavailabilities/${unavailabilityId}`, {
            comment,
            statut,
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.UPDATE_UNAVAILABILITY, fileName));
    }
}

async function getUnavailabilityHistoric(unavailabilityId) {
    try {
        const response = await apiClient.get(`unavailabilities_history/${unavailabilityId}`);
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.UNAVAILABILITY_HISTORIC, fileName));
    }
}

export default {
    getUnavailability,
    searchUnavailabilities,
    updateUnavailability,
    getUnavailabilityHistoric,
};
