<template>
    <div>
        <div
            v-for="(event, i) in events"
            :key="i"
            class="work-rythm-event work-rythm"
            :style="{
                left: `${event.position}%`,
                width: `${event.duration}%`,
            }"
        />
    </div>
</template>

<script>
export default {
    name: 'WorkRythmEvents',

    props: {
        events: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/styles/colors';
@import '@/styles/unavailabilitiesColors';

.work-rythm-event {
    height: 30px;
    position: absolute;
    border-radius: 0px;
    opacity: 0.6;
    border-left: solid 1px $gray;
    border-right: solid 1px $gray;
    z-index: 3;
}
</style>
