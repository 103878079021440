/* eslint-disable no-extend-native */
/* eslint-disable func-names */
String.prototype.toParamString = function (...params) {
    let parameterizedString = this;

    params.forEach((param) => {
        parameterizedString = parameterizedString.replace(/{.*?}/, param);
    });

    return parameterizedString;
};

export default {
    // MESSAGES
    GET_SPECIFIC_EMPLOYEE: {
        TITLE: 'Erreur récupération utilisateur',
        MESSAGE: "Impossible de récupérer les informations de l'utilisateur selectionné.",
    },
    EMPLOYEE: 'Impossible de récupérer vos informations utilisateur.',
    EMPLOYEE_GET_DELEGATIONS: 'Impossible de récupérer vos délégations.',
    EMPLOYEE_CREATE_DELEGATION: 'Une erreur est survenue lors de la création de la délégation.',
    EMPLOYEE_PUT_DELEGATIONS: 'Une erreur est survenue lors de la modification de la délégation.',
    EMPLOYEE_DELETE_DELEGATION: 'Une erreur est survenue lors de la suppression de la délégation.',
    EMPLOYEE_LIST: 'Impossible de récupérer les informations des utilisateurs demandés.',
    EMPLOYEE_ELIGIBLE_RECIPIENTS:
        'Impossible de récupérer la liste des personnes éligibles à la déclaration.',
    TEAMS: "Impossible de récupérer la liste des personnes de votre périmètre d'habilitation.",
    GET_GROUPS: 'Impossible de récupérer vos groupes.',
    POST_GROUPS: 'Une erreur est survenue lors de la création du groupe',
    PUT_GROUPS: 'Une erreur est survenue lors de la modification du groupe',
    DELETE_GROUPS: 'Une erreur est survenue lors de la suppression du groupe',
    UNAVAILABILITIES: 'Impossible de récupérer les indisponibilités.',
    CREATE_UNAVAILABILITY: {
        TITLE: 'Problème système',
        MESSAGE: "Une erreur est survenue lors de la création de l'indisponibilité",
    },
    EDIT_UNAVAILABILITY: {
        TITLE: 'Problème système',
        MESSAGE: "Une erreur est survenue lors de la modification de l'indisponibilité",
    },
    CHECK_CREATE_UNAVAILABILITY:
        "Une erreur est survenue lors de la vérification de la création de l'indisponibilité",
    CHECK_EDIT_UNAVAILABILITY:
        "Une erreur est survenue lors de la vérification de la modification de l'indisponibilité",
    UNAVAILABILITY_BY_ID: "Impossible de récupérer l'indisponibilité demandée.",
    UNAVAILABILITY_HISTORIC: "Impossible de récupérer l'historique de l'indisponibilité.",
    UPDATE_UNAVAILABILITY: "Une erreur est survenue lors de la mise à jour de l'indisponibilité.",
    CHECK_UNAVAILABILITY:
        'Il est possible que cette indisponibilité ait déjà été prise en compte. Veuillez rafraîchir vos données.',
    EXTRACTION_UNAVAILABILITIES: 'Impossible de récupérer la liste des indisponibilités',
    LAST_CHANGES_UNAVAILABILITIES: 'Impossible de récupérer la liste des derniers changements',
    UNAVAILABILITIES_TO_VALIDATE: 'Impossible de récupérer la liste indisponibilités à valider',
    TO_VALIDATE_HISTORY: "Impossible de récupérer l'historique des validations.",
    TO_VALIDATE_COUNT: "Impossible de récupérer le nombre d'indisponibilités à valider.",
    MY_REQUESTS: 'Impossible de récupérer la liste de mes demandes',
    MY_REQUESTS_COUNT: 'Impossible de récupérer le nombre de mes demandes',
    MY_REQUESTS_EVENT: "Une erreur est survenue lors de la notification d'accès à mes demandes",
    UNAVAILABILITY_REASONS: 'Impossible de récupérer la liste des motifs.',
    COMMON_UNAVAILABILITY_REASONS: 'Impossible de récupérer la liste des motifs communs.',
    PERIMETER: "Impossible de récupérer votre périmètre d'habilitation.",
    HOLIDAYS: 'Impossible de récupérer les jours fériés.',
    SCHOOL_HOLIDAY: 'Impossible de récupérer les vacances scolaires.',
    DUTY_CALLS: 'Impossible de récupérer les astreintes.',
    WORK_RYTHMS: 'Impossible de récupérer les rythmes de travail.',
    FACILITIES: 'Impossible de récupérer la liste des installations',
    WORKS: 'Impossible de récupérer la liste des chantiers',

    // TECHNICAL
    OKTA: {
        TITLE: 'Oups, une erreur technique est survenue',
        MESSAGE: "Vous pouvez contacter helpy et signaler l'erreur : {error}.",
    },
    UNKNOWN: {
        TITLE: 'Erreur inconnue',
        MESSAGE: 'Une erreur inconnue est survenue.',
    },
    NETWORK: {
        TITLE: 'Problème réseau',
        MESSAGE: 'Vérifiez que vous êtes bien connecté.',
    },
    HTTP: {
        _401: {
            TITLE: 'Utilisateur non reconnu',
            MESSAGE: 'Veuillez réessayer ou vérifiez vos droits d’accès à eDispo.',
        },
        _403: 'Accès non autorisé',
        _404: 'Ressource introuvable',
        _409: 'Conflit',
        _500: 'Problème serveur',
    },
};
