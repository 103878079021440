import unavailabilityReasonsApi from '@/api/unavailabilityReasons';
import unavailabilityReasonsService from '@/services/unavailabilityReasons';

const fileName = 'unavailabilityReasons.store';

const state = {
    unavailabilityReasons: [],
};

const getters = {
    unavailabilityReasonsObject: (state) =>
        unavailabilityReasonsService.formatReasons(state.unavailabilityReasons),
};

const mutations = {
    setUnavailabilityReasons(state, unavailabilityReasons) {
        state.unavailabilityReasons = unavailabilityReasons;
    },
    clearReasons(state) {
        state.unavailabilityReasons = [];
    },
};

const actions = {
    async loadUnavailabilityReasons({ commit, state }) {
        if (!(state.unavailabilityReasons && state.unavailabilityReasons.length > 0)) {
            try {
                const unavailabilityReasons =
                    await unavailabilityReasonsApi.getUnavailabilityReasons();
                commit('setUnavailabilityReasons', unavailabilityReasons);
            } catch (error) {
                commit('showErrorToast', { error, fileName }, { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
