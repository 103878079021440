<template>
    <div class="weekend-event weekend" />
</template>

<script>
export default {
    name: 'WeekendEvents',
};
</script>

<style lang="scss" scoped>
@import '@/styles/unavailabilitiesColors';

.weekend-event {
    height: 30px;
    width: 99%;
    position: absolute;
    border-radius: 0px;
    z-index: 0;
}
</style>
