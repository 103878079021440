import groupsApi from '@/api/groups';
import groupsService from '@/services/groups';

const fileName = 'groups.store';

const state = {
    groups: [],
};

const getters = {
    groupsTree: (state) => groupsService.getGroupsTree(state.groups),
    groupsPeople: (state) => groupsService.getGroupsPeople(state.groups),
};

const mutations = {
    setGroups(state, newGroups) {
        const groups = newGroups.slice().sort((a, b) => a.groupname.localeCompare(b.groupname));
        state.groups = groups;
    },

    clearGroups(state) {
        state.groups = [];
    },
};

const actions = {
    async loadGroups({ commit, state }) {
        if (!(state.groups && state.groups.length > 0)) {
            try {
                const groups = await groupsApi.getGroups();
                commit('setGroups', groups);
            } catch (error) {
                commit('showErrorToast', { error, fileName }, { root: true });
            }
        }
    },
    addGroup({ commit, state }, addedGroup) {
        const { groups } = state;
        groups.push(addedGroup);
        commit('setGroups', groups);
    },
    removeGroup({ commit, state }, groupId) {
        const groups = state.groups.filter((group) => group.groupid !== groupId);
        commit('setGroups', groups);
    },
    editGroup({ commit, state }, editedGroup) {
        const groups = state.groups.filter((group) => group.groupid !== editedGroup.groupid);
        groups.push(editedGroup);
        commit('setGroups', groups);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
