<template>
    <v-snackbar
        top
        tile
        :value="toast.value"
        @input="setToastValue"
        :timeout="timeout"
        :color="toast.color"
    >
        <div class="v-snackbar__message">
            <h2>{{ toast.title }}</h2>
            <p>{{ toast.message }}</p>
        </div>
        <v-icon v-if="toast.dismiss" color="white" @click="setToastValue(false)">mdi-close</v-icon>
    </v-snackbar>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'Toast',

    computed: {
        ...mapState(['toast']),
        timeout() {
            if (this.toast.dismiss) {
                return -1;
            }
            return 3000;
        },
    },
    methods: {
        ...mapMutations(['setToastValue']),
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-snack__wrapper {
    width: 100%;
}
::v-deep .v-snack__content {
    width: 100%;
    .mdi-close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
.v-snackbar__message {
    display: block;
    margin: 0 auto;
    text-align: center;
    font-size: 0.9rem;
    font-family: sans-serif, Arial;
    h2 {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 0;
    }
}
</style>
