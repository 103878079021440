var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"value":_vm.isDisplayed,"max-width":"760","scrollable":"","persistent":""},on:{"input":_vm.showDialog}},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-title',[(_vm.isEditing)?_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-pencil")]),_vm._v(" Modifier un groupe ")],1):_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":"","large":""}},[_vm._v("mdi-plus")]),_vm._v(" Créer un groupe ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.showDialog(false)}}},[_c('v-icon',{attrs:{"dark":"","large":""}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-text-field',{staticClass:"group-text-field",attrs:{"tile":"","autofocus":"","rules":_vm.rules,"counter":20,"maxlength":20,"label":"Nom du groupe*"},model:{value:(_vm.groupName),callback:function ($$v) {_vm.groupName=$$v},expression:"groupName"}}),_c('el-transfer',{ref:"groupTransferRef",attrs:{"filterable":"","filter-placeholder":"Entrer un nom","filter-method":_vm.filterMethod,"titles":[
                            'Mon périmètre',
                            `${_vm.groupName ? _vm.groupName : 'Nom du groupe'}`,
                        ],"button-texts":['Retirer', 'Ajouter'],"format":{
                            noChecked: '${total}',
                            hasChecked: '${checked}/${total}',
                        },"props":{
                            key: 'gid_collaborateur',
                        },"data":_vm.candidates},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return _c('span',{},[_vm._v(" "+_vm._s(option.nom)+" "+_vm._s(option.prenom)+" ")])}}]),model:{value:(_vm.groupUsersGids),callback:function ($$v) {_vm.groupUsersGids=$$v},expression:"groupUsersGids"}})],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fluid":""}},[(_vm.isEditing)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"dark":"","outlined":"","tile":"","block":"","color":"error"},on:{"click":function($event){return _vm.deleteGroup()}}},[_vm._v(" Supprimer ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"tile":"","dark":"","block":"","outlined":"","color":"grey darken-1"},on:{"click":function($event){return _vm.showDialog(false)}}},[_vm._v(" Annuler ")])],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.isEditing)?_c('v-btn',{staticClass:"primary",attrs:{"tile":"","block":"","depressed":"","disabled":!_vm.isValid},on:{"click":function($event){return _vm.updateGroup()}}},[_vm._v(" Mettre à jour ")]):_c('v-btn',{staticClass:"primary",attrs:{"tile":"","block":"","depressed":"","disabled":!_vm.isValid},on:{"click":function($event){return _vm.createGroup()}}},[_vm._v(" Créer ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }