/* eslint-disable no-dupe-keys */
import { formatToIsoString } from '@/services/shared';
import { gouvClient, apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'holidays.api.js';

async function getHolidays(rawEmployeesGids, start, end) {
    try {
        const formattedUsersGids = rawEmployeesGids.map((gid) => ({ gid }));
        const startDate = formatToIsoString(start);
        const endDate = formatToIsoString(end);

        const response = await apiClient.post('public_holidays/search', {
            employees: formattedUsersGids,
            period: [
                {
                    start_date: startDate,
                    end_date: endDate,
                },
            ],
        });
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.HOLIDAYS, fileName));
    }
}

async function getSchoolHolidaysByYear(yearInterval) {
    try {
        const params = new URLSearchParams();
        params.append('dataset', 'fr-en-calendrier-scolaire');
        params.append('rows', '200');
        params.append('sort', 'start_date');
        params.append('refine.annee_scolaire', yearInterval);
        params.append('exclude.zones', 'Polyn%C3%A9sie');
        params.append('exclude.zones', 'R%C3%A9union');
        params.append('exclude.zones', 'Corse');
        params.append('exclude.zones', 'Saint+Pierre+et+Miquelon');
        params.append('exclude.zones', 'Mayotte');
        params.append('exclude.zones', 'Martinique');
        params.append('exclude.zones', 'Guyane');
        params.append('exclude.zones', 'Guadeloupe');

        const response = await gouvClient.get('search/', {
            params,
        });
        return response.data.records.map((data) => data.fields);
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.SCHOOL_HOLIDAY, fileName));
    }
}

export default {
    getHolidays,
    getSchoolHolidaysByYear,
};
