import { assetsClient, apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'assets.api.js';

async function getPropertyById(propertyId, navFullId) {
    const query = [];
    if (navFullId) {
        query.push(`"parent_entity_nav_full_id":{ "$like":"${navFullId}%25" }`);
    }
    if (propertyId) {
        query.push(`"id":{ "$eq":${propertyId} }`);
    }
    try {
        const response = await assetsClient.get(`properties?limit=10&q={${query.join(',')}}`);
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.FACILITIES, fileName));
    }
}

async function searchProperties(codeSearch, nameSearch, navFullId) {
    const query = [];
    if (navFullId) {
        query.push(`"parent_entity_nav_full_id":{ "$like":"${navFullId}%25" }`);
    }
    if (codeSearch && nameSearch) {
        query.push(
            `"$or": [{"code":{ "instr":"${codeSearch}" }}, {"name":{ "$instr":"${nameSearch}" }}]`
        );
    } else if (codeSearch) {
        query.push(`"code":{ "$instr":"${codeSearch}"}`);
    } else if (nameSearch) {
        query.push(`"name":{ "$instr":"${nameSearch}" }`);
    }

    try {
        const response = await assetsClient.get(`properties?limit=10&q={${query.join(',')}}`);
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.FACILITIES, fileName));
    }
}

async function searchConstructions(idSearch, nameSearch, constraintType, propertyId) {
    try {
        const response = await apiClient.post('works/search?limit=10', {
            id_search: idSearch,
            work_name_search: nameSearch,
            constraint_type: constraintType,
            property_id: propertyId,
        });
        return response.data.items;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.WORKS, fileName));
    }
}

export default {
    getPropertyById,
    searchProperties,
    searchConstructions,
};
