import { apiClient } from '@/api/client';
import { getApiError } from '@/services/errors/apiErrorHelper';
import errorMessages from '@/constants/errorMessages';

const fileName = 'perimeter.api.js';

async function getPerimeter() {
    try {
        const response = await apiClient.get('perimeter');
        return response.data;
    } catch (error) {
        return Promise.reject(getApiError(error, errorMessages.PERIMETER, fileName));
    }
}

export default {
    getPerimeter,
};
