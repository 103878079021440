import genLogger from '@cofely/genesis-logger';
import EDispoError from '@/services/errors/EdispoError.model';
import errorMessages from '@/constants/errorMessages';

export default {
    setToastValue(state, value) {
        state.toast.value = value;
    },
    showToast(state, { color = 'success', title = '', message = '', dismiss = true }) {
        state.toast = {
            value: true,
            color,
            title,
            message,
            dismiss,
        };
    },
    showErrorToast(state, { error, fileName = '', dismiss = true, color = 'error' }) {
        let title;
        let message;

        if (error instanceof EDispoError) {
            if (error.stack && error.fileName) {
                genLogger.error(error.fileName, error.stack);
            }
            title = error.title;
            message = error.message;
        } else {
            genLogger.error(fileName, `Interception d'une erreur non gérée: ${error}`);
            title = errorMessages.UNKNOWN.TITLE;
            message = errorMessages.UNKNOWN.MESSAGE;
        }
        state.toast = {
            value: true,
            color,
            title,
            message,
            dismiss,
        };
    },
    clearToast(state) {
        state.toast = {
            value: false,
            color: 'primary',
            title: '',
            message: '',
            dismiss: true,
        };
    },

    addFatalError(state, { error, fileName = '', solution = undefined }) {
        let fatalError;
        if (error instanceof EDispoError) {
            if (error.stack && error.fileName) {
                genLogger.error(error.fileName, error.stack);
            }
            fatalError = {
                id: state.fatalErrors.length,
                title: error.title,
                message: error.message,
                solution,
            };
        } else {
            genLogger.error(fileName, `Interception d'une erreur fatale non gérée: ${error}`);
            fatalError = {
                id: state.fatalErrors.length,
                title: errorMessages.UNKNOWN.TITLE,
                message: errorMessages.UNKNOWN.MESSAGE,
                solution,
            };
        }
        state.fatalErrors.push(fatalError);
    },
    removeFatalError(state, errorId) {
        state.fatalErrors.splice(errorId, 1);
    },
    clearErrors(state) {
        state.fatalErrors = [];
    },

    setScreenSize(state, size) {
        state.screenSize = size;
    },

    showSpinner(state) {
        state.isSpinnerDiplayed = true;
    },
    hideSpinner(state) {
        state.isSpinnerDiplayed = false;
    },

    setOnDemandUnavLoad(state, val) {
        state.onDemandUnavLoad = val;
    },

    setIsCacheAllowed(state, isCacheAllowed) {
        state.isCacheAllowed = isCacheAllowed;
    },
};
