/* eslint-disable no-underscore-dangle */
import Vue from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { ConcurrencyManager } from 'axios-concurrency';
import config from '../../env/env-config';

const envConfig = config.getEnvConfig();
const DEFAULT_RETRIES = 2;
const DEFAULT_MAX_CONCURRENT_REQUESTS = 50;
const DEFAULT_RETRY_DELAY_FUNCTION = (retryCount) => 1000 * retryCount;
const HOLIDAY_GOUV_API = 'https://data.education.gouv.fr/api/records/1.0';

let impersonatingGid = '';

const defaultRequestHandler = async (request) => {
    if (
        request.baseURL.includes(envConfig.apiBaseUrl) ||
        request.baseURL.includes(envConfig.assetsBaseUrl) ||
        request.baseURL.includes(envConfig.oldApiBaseUrl)
    ) {
        const idToken = await Vue.prototype.$auth.getIdToken();
        if (idToken) {
            request.headers.Authorization = idToken;
        }

        if (impersonatingGid) {
            if (request.params) {
                if (request.params instanceof URLSearchParams) {
                    request.params.append('impersonating_gid', impersonatingGid);
                } else {
                    request.params.impersonating_gid = impersonatingGid;
                }
            } else {
                request.params = { impersonating_gid: impersonatingGid };
            }
        }
    }
    return request;
};

class AxiosApiBuilder {
    constructor(baseURL) {
        this._axiosConfig = {
            baseURL,
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        this._interceptors = [
            {
                requestHandler: defaultRequestHandler,
            },
        ];
        this._axiosRetryConfig = {
            retries: DEFAULT_RETRIES,
            retryDelay: DEFAULT_RETRY_DELAY_FUNCTION,
        };
        this._maxConcurrentRequests = DEFAULT_MAX_CONCURRENT_REQUESTS;
    }

    build() {
        const newAxiosClient = axios.create(this._axiosConfig);
        axiosRetry(newAxiosClient, this._axiosRetryConfig);
        ConcurrencyManager(newAxiosClient, this._maxConcurrentRequests);

        this._interceptors.forEach(
            ({
                requestHandler,
                requestErrorHandler,
                responseSuccessHandler,
                responseErrorHandler,
            }) => {
                if (requestHandler || requestErrorHandler) {
                    newAxiosClient.interceptors.request.use(requestHandler, requestErrorHandler);
                }
                if (responseSuccessHandler || responseErrorHandler) {
                    newAxiosClient.interceptors.response.use(
                        responseSuccessHandler,
                        responseErrorHandler
                    );
                }
            }
        );
        return newAxiosClient;
    }
}

let apiClientInstance;
let gouvClientInstance;
let assetsClientInstance;
let oldApiClientInstance;

function getApiClient() {
    if (!apiClientInstance) {
        apiClientInstance = new AxiosApiBuilder(envConfig.apiBaseUrl).build();
    }
    return apiClientInstance;
}
function getGouvClient() {
    if (!gouvClientInstance) {
        gouvClientInstance = new AxiosApiBuilder(HOLIDAY_GOUV_API).build();
    }
    return gouvClientInstance;
}
function getAssetsClient() {
    if (!assetsClientInstance) {
        assetsClientInstance = new AxiosApiBuilder(envConfig.assetsBaseUrl).build();
    }
    return assetsClientInstance;
}
function getOldApiClient() {
    if (!oldApiClientInstance) {
        oldApiClientInstance = new AxiosApiBuilder(envConfig.oldApiBaseUrl).build();
    }
    return oldApiClientInstance;
}

export function setImpersonatingGid(gid) {
    impersonatingGid = gid;
}
export const apiClient = getApiClient();
export const gouvClient = getGouvClient();
export const assetsClient = getAssetsClient();
export const oldApiClient = getOldApiClient();

export default {
    setImpersonatingGid,
    apiClient,
    gouvClient,
    assetsClient,
    oldApiClient,
    AxiosApiBuilder,
};
